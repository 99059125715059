/* eslint-disable valid-jsdoc */
import numeral, { RoundingFunction } from 'numeral';

const longMonthFormatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
const shortMonthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
const longMonthNumericYearFormatter = new Intl.DateTimeFormat('en-US', {
	month: 'long',
	year: 'numeric',
});
const shortMonthNumericDayFormatter = new Intl.DateTimeFormat('en-us', {
	month: 'short',
	day: 'numeric',
});
const numericMonth2DigitDayFormatter = new Intl.DateTimeFormat('en-us', {
	month: 'numeric',
	day: '2-digit',
});
const yyyymmddFormatter = new Intl.DateTimeFormat('en-us', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
});

const prettyDateFormatter = new Intl.DateTimeFormat('en-us', {
	month: 'short',
	day: 'numeric',
	year: 'numeric',
	hour12: true,
	hour: 'numeric',
	minute: 'numeric',
	// timeZoneName: 'short'
});

const shortTime = new Intl.DateTimeFormat('en-us', {
	hour: 'numeric',
	hour12: true,
});

/**Numeral can do some wonky rounding for perentages depending on decimal places
 * (eg: .1250 => '12 %'). Using this to correct it to (.1250 => 13 %) */
const percentageRounding: RoundingFunction = (val: number) => Math.round(val * 10000) / 10000;

export default class ValueFormatter {
	//Money
	/**
	 * @param {number} value - number to format
	 * @returns {string} - decimal formatted strin
	 */
	static decimalMoney(value: number): string {
		return numeral(value).format('$ 0,0.0000', Math.round);
	}

	/**@param {number} value */
	static longMoney(value: number): string {
		return numeral(value).format('$ 0,0.00', Math.round);
	}

	/**@param {number} value */
	static shortMoney(value: number): string {
		return numeral(value).format('$ 0.00 a', Math.round).toUpperCase();
	}

	//Percent
	/**@param {number} value */
	static decimalPercent(value: number): string {
		return numeral(value).format('0,0.00000 %', Math.round);
	}

	/**@param {number} value */
	static percent(value: number): string {
		return numeral(value).format('0.0 %', percentageRounding);
	}

	/**@param {number} value */
	static longPercent(value: number): string {
		return numeral(value).format('0.00 %', percentageRounding);
	}

	/**@param {number} value */
	static shortPercent(value: number): string {
		return numeral(value).format('0,0 %', percentageRounding); // rounding can go awry with .1250
	}

	/**@param {number} value */
	static shortPercentOptionalDecimals(value: number): string {
		return numeral(value).format('0.[00] %', Math.round);
	}

	// Number
	/**@param {number} value */
	static longNumber(value: number): string {
		return numeral(value).format('0,0', Math.round);
	}

	/**@param {number} value */
	static shortNumber(value: number): string {
		return numeral(value).format('0.[00] a', Math.round).toUpperCase();
	}

	/**@param {number} value */
	static availabilityNumber(value: number): string {
		// Using Math.floor to truncate values after 3 decimal places
		return numeral(value).format('0.000', Math.floor);
	}

	/**@param {number} value */
	static latencyNumber(value: number): string {
		// Using Math.floor to truncate values after 3 decimal places
		return `${numeral(value).format('0.000', Math.floor)} ms`;
	}

	/**@param {number} value */
	static shortNumberNoDecimal(value: number): string {
		return numeral(value).format('0 a', Math.round).toUpperCase();
	}

	// Date
	/**@param {Date} date */
	static monthYearDate(date: Date | number): string {
		return longMonthNumericYearFormatter.format(date);
	}

	/**@param {Date} date */
	static longMonth(date: Date | number): string {
		return longMonthFormatter.format(date);
	}

	/**@param {Date} date */
	static shortMonth(date: Date | number): string {
		return shortMonthFormatter.format(date);
	}

	/** @param {Date} date */
	static monthDayDate(date: Date | number): string {
		return shortMonthNumericDayFormatter.format(date);
	}

	/** @param {Date} date */
	static yyyymmddDate(date: Date | number): string {
		const tempStr = yyyymmddFormatter.format(date).split('/');
		return `${tempStr[2]}${tempStr[0]}${tempStr[1]}`;
	}

	static prettyDate(date: Date | number): string {
		return prettyDateFormatter.format(date);
	}

	static shortDate(date: Date | number): string {
		return numericMonth2DigitDayFormatter.format(date);
	}

	static shortTime(date: Date | number): string {
		return shortTime.format(date);
	}
}
