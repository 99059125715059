import { styled } from '@fluentui/react/lib/Utilities';
import LinkButtonPanel from 'Components/Navigation/LinkButtonPanel.base';
import { linkButtonPanelStyles } from 'Components/Navigation/LinkButtonPanel.styles';
import {
	ILinkButtonPanelProps,
	ILinkButtonPanelStyleProps,
	ILinkButtonPanelStyles
} from 'Components/Navigation/LinkButtonPanel.types';

const StyledLinkButtonPanel =
	styled<ILinkButtonPanelProps,
		ILinkButtonPanelStyleProps,
		ILinkButtonPanelStyles>(
			LinkButtonPanel,
			linkButtonPanelStyles
		);

export default StyledLinkButtonPanel;
