/* eslint-disable react/react-in-jsx-scope */
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import {
	AccordionCommandBar,
	BarChart,
	ColorTestLayout,
	DetailsList,
	DetailsRow,
	Dropdown,
	GapsContainer,
	GapsLayout,
	GroupHeader,
	HealthContainer,
	HealthLayout,
	Icon,
	LineChart,
	ManagementContainer,
	ManagementLayout,
	MetricsContainer,
	MetricsLayout,
	PendingChangesContainer,
	PendingChangesLayout,
	PieChart,
	ResponsiveContainer,
	ScenarioDetailsContainer,
	ScenarioDetailsLayout,
	Sticky,
	TestLayout,
	TileCardStrip,
} from 'Components/LazyLoad/_Lazy';
import { ContainerRouteProps } from 'Components/Shell/Shell.types';
import {
	AggregateProvider,
	filterData,
	useAggregateDispatch,
} from 'Context/AggregateContext/AggregateContext';
import {
	Action,
	AggregateAction,
	IAggregateKeyProp,
} from 'Context/AggregateContext/AggregateContext.types';
import {
	CoreDataProvider,
	useCoreDataContext,
	useCoreDataDispatch,
} from 'Context/CoreDataContext/CoreDataContext';
import { CoreDataActions } from 'Context/CoreDataContext/CoreDataContext.types';
import {
	GlobalFilterProvider,
	useGlobalFilters,
	useGlobalFiltersDispatch,
	useMegaMonths,
} from 'Context/GlobalFiltersContext/GlobalFiltersContext';
import {
	LinkedItemsProvider,
	useLinkedItemsContext,
	useLinkedItemsDispatch,
} from 'Context/LinkedItemsContext/LinkedItemsContext';
import {
	LinkedItemUpdate,
	LinkedItemUpdatesArray,
} from 'Context/LinkedItemsContext/LinkedItemsContext.types';
import { CustomerKeysToLobMap } from 'DataModels/CoreDataModels';
import { ModifiedCoreApiData, ModifiedReliabilityModel } from 'DataModels/CoreDataModels.types';
import { LobEnum, TimespanEnum } from 'DataModels/Global.types';
import { DateScenarioCustomerToIcmItems, ModifiedIcmItem } from 'DataModels/LinkedItems.types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import isEmpty from 'Utils/IsEmpty';
import VF from 'Utils/ValueFormatting';
import DashboardLayout from 'Views/Dashboard/Dashboard.Layout';

function DashboardContainer(props: ContainerRouteProps): JSX.Element {
	//#region App insights
	const appInsights = useAppInsightsContext();
	const trackComponent = useTrackMetric(appInsights, 'Dashboard (entire site)');

	useEffect(() => trackComponent());
	//#endregion

	//#region Hooks and Contexts
	const globalFilters = useGlobalFilters();
	const { timeframe, endDate, startDate, owner, engineeringManager, shMetTarget, shNines } =
		globalFilters;
	const globalFiltersDispatch = useGlobalFiltersDispatch();
	const { megaMonths, megaMonthsIterator } = useMegaMonths();

	const location = useLocation();

	const {
		coreScenarioData,
		majorScenarioList,
		metaDataStatus,
		activeMinorScenarioList,
		scenarioGroupList,
		scenarioStatus,
		userMetadata,
		graduatedScenarios,
		ownersMetaData
	} = useCoreDataContext();
	const coreDispatch = useCoreDataDispatch();

	const aggregateDispatch = useAggregateDispatch();

	const {
		icmItems,
		addedIcmUpdates,
		removedIcmUpdate,
	} = useLinkedItemsContext();
	const linkedItemsDispatch = useLinkedItemsDispatch();

	/** We can generally render something with only the scenarios api data.
	 * This is only tracking that point and many views may need more loading tracking.
	 */
	const loading = useMemo(() => {
		return scenarioStatus !== 'success';
	}, [scenarioStatus]);
	//#endregion

	//#region preload components
	const [initPreload, setInitPreload] = useState(false);

	useEffect(() => {
		// Most pages need these.  Preload them

		// Fluent
		void DetailsList.preload();
		void DetailsRow.preload();
		void Dropdown.preload();
		void GroupHeader.preload();
		void Icon.preload();
		void Sticky.preload();

		// SH
		void AccordionCommandBar.preload();
		void TileCardStrip.preload();
	});

	useEffect(() => {
		//Early preload views on first load (rest handled in routes)
		if (!initPreload) {
			setInitPreload(true);
			switch (location.pathname) {
				case '/management': {
					void ManagementContainer.preload();
					void ManagementLayout.preload();
					break;
				}
				case '/pendingchanges': {
					void PendingChangesContainer.preload();
					void PendingChangesLayout.preload();
					break;
				}
				case '/': {
					void HealthContainer.preload();
					void HealthLayout.preload();
					void HealthContainer.preload();
					void HealthLayout.preload();
					void LineChart.preload();
					void ResponsiveContainer.preload();
					if (shNines) void BarChart.preload();
					if (shMetTarget) void PieChart.preload();
					break;
				}
				case '/gaps': {
					void GapsContainer.preload();
					void GapsLayout.preload();
					break;
				}
				case '/scenariodetails': {
					void ScenarioDetailsContainer.preload();
					void ScenarioDetailsLayout.preload();
					break;
				}
				case '/metrics': {
					void MetricsContainer.preload();
					void MetricsLayout.preload();
					break;
				}
				case '/test': {
					void TestLayout.preload();
					break;
				}
				case '/colortest': {
					void ColorTestLayout.preload();
					break;
				}
				default:
					break;
			}
		}
	}, [initPreload, location.pathname, shMetTarget, shNines]);
	//#endregion

	//#region Refs and Scrolls
	const tableRef = useRef<HTMLDivElement | null>(null);
	//TODO:  Consider Moving this to a provider and using the tableRefIntersectionObserver Directly (instead of tableRef)
	const [inViewTableRef, tableRefInView /*, tableRefIntersectionObserver*/] = useInView({
		threshold: 0.1, //10% threshold to overcome the footer
	});

	const [isScrollable, setIsScrollable] = useState(false);
	const [firstTimeMovedToTable, setFirstTimeMovedToTable] = useState(false);

	const setTableRef = useCallback(
		(node: HTMLDivElement) => {
			tableRef.current = node;
			inViewTableRef(node);
		},
		[inViewTableRef]
	);

	const viewsContentRef = useRef<HTMLDivElement>(null);

	//SCROLL TO TABLE AFTER CARD SELECT
	useEffect(() => {
		if (tableRef && tableRef.current) {
			if (isScrollable || firstTimeMovedToTable) {
				tableRef.current.scrollIntoView({ behavior: 'smooth' });
				setIsScrollable(false);
			}
			if (!firstTimeMovedToTable) {
				setFirstTimeMovedToTable(tableRef.current?.clientHeight < 20);
			}
		}
	}, [isScrollable, tableRef, firstTimeMovedToTable]);

	const onScrollToTable = useCallback(() => {
		setIsScrollable(true);
	}, []);
	//#endregion

	//#region Annotations

	//#region Icm
	const [mappedIcmItems, setMappedIcmItems] = useState<boolean>(false);

	const removeUnlinkedIcmItems = useCallback(
		({ item, scenarioMaps }: LinkedItemUpdate<ModifiedIcmItem>) => {
			const convertedDate: string = VF.yyyymmddDate(item.fullImpactStartDate);
			let day: ModifiedCoreApiData[] = [];
			let scenarioId: number | undefined;
			let scenario: ModifiedCoreApiData | undefined;
			let lobName: LobEnum;

			day = coreScenarioData[convertedDate];

			for (const map of scenarioMaps) {
				//Find scenario for that day
				if (scenarioId !== map.ScenarioId) {
					scenarioId = map.ScenarioId;
					scenario = day.find((scenario) => scenario.Id === map.ScenarioId);
				}
				lobName = CustomerKeysToLobMap[map.CustomerId];
				if (scenario && lobName) {
					scenario[lobName].LinkedIcmItems = (
						scenario[lobName].LinkedIcmItems as ModifiedIcmItem[]
					).filter((licm) => licm !== item);
				}
			}

			linkedItemsDispatch({ type: 'clear Icm updates', updateType: 'unlink' });
		},
		[coreScenarioData, linkedItemsDispatch]
	);

	useEffect(() => {
		if (removedIcmUpdate) removeUnlinkedIcmItems(removedIcmUpdate);
	}, [removeUnlinkedIcmItems, removedIcmUpdate]);

	const addLinkedIcmItems = useCallback(
		(updates: LinkedItemUpdatesArray<ModifiedIcmItem>) => {
			let convertedDate: string | undefined;
			let day: ModifiedCoreApiData[] = [];
			let scenarioId: number | undefined;
			let scenario: ModifiedCoreApiData | undefined;
			let lobName: LobEnum;

			for (const { item, scenarioMaps } of updates) {
				convertedDate = VF.yyyymmddDate(item.fullImpactStartDate);
				day = coreScenarioData[convertedDate];

				if (!day) {
					// create day if it doesn't exist
					const tempDay: ModifiedCoreApiData[] = [];
					coreScenarioData[convertedDate] = tempDay;
					day = tempDay;
				}

				for (const map of scenarioMaps) {
					//Find scenario for that day
					if (scenarioId !== map.ScenarioId) {
						scenarioId = map.ScenarioId;
						scenario = day.find((scenario) => scenario.Id === map.ScenarioId);
					}

					lobName = CustomerKeysToLobMap[map.CustomerId];
					if (scenario && lobName) {
						// Found an existing day/scenario
						if (!scenario[lobName].LinkedIcmItems)
							scenario[lobName].LinkedIcmItems = [];
						// Map Linked Scenario to Daily data
						scenario[lobName].LinkedIcmItems = (
							scenario[lobName].LinkedIcmItems as ModifiedIcmItem[]
						).concat(item);

						// Remove Linked Scenario from Suggestion Mapping to Scenario if existss
						if (scenario[lobName].SuggestedIcmItems)
							scenario[lobName].SuggestedIcmItems = (
								scenario[lobName].SuggestedIcmItems as ModifiedIcmItem[]
							).filter((sicm) => sicm !== item);
					} else if (lobName) {
						// Create a scenario for specific day

						// should always exist
						const scenarioMetaData = activeMinorScenarioList.find(
							(smd) => smd.Id === map.ScenarioId
						);

						if (scenarioMetaData) {
							const missingScenarioDate: Partial<ModifiedCoreApiData> = {
								...scenarioMetaData,
							};
							missingScenarioDate[lobName] = {
								Reliability: null,
								DailyCumulativeBudget: [],
								Next9DailyCumulativeBudget: [],
								ErrorBudgetRemaining: null,
								Next9ErrorBudgetRemaining: null,
								Requests: 0,
								Success: 0,
								Latency: null,
								HasData: false,
								GraduatedScenario: null,
								LinkedIcmItems: [item],
								SuggestedIcmItems: [],
								FullData: {},
							};
							day.push(missingScenarioDate as ModifiedCoreApiData);
						}
					}
				}
			}

			linkedItemsDispatch({ type: 'clear Icm updates', updateType: 'link' });
		},
		[linkedItemsDispatch, coreScenarioData, activeMinorScenarioList]
	);

	useEffect(() => {
		if (addedIcmUpdates) {
			addLinkedIcmItems(addedIcmUpdates);
		}
	}, [addLinkedIcmItems, addedIcmUpdates]);

	const mapDailyScenarioCustomerToIcmItems = useCallback(() => {
		//Only called once
		const temp = { ...coreScenarioData };
		const scenarioToIcmMap: DateScenarioCustomerToIcmItems = {};

		let dateKey;

		// For every icm
		for (const icm of icmItems) {
			dateKey = VF.yyyymmddDate(icm.fullImpactStartDate);

			for (const scenario of icm.LinkedScenarios) {
				if (!scenarioToIcmMap[dateKey]) scenarioToIcmMap[dateKey] = {};

				if (!scenarioToIcmMap[dateKey][scenario.ScenarioId])
					scenarioToIcmMap[dateKey][scenario.ScenarioId] = {};
				if (!scenarioToIcmMap[dateKey][scenario.ScenarioId][scenario.CustomerId])
					scenarioToIcmMap[dateKey][scenario.ScenarioId][scenario.CustomerId] = {
						LinkedIcmItems: [],
						SuggestedIcmItems: [],
					};
				scenarioToIcmMap[dateKey][scenario.ScenarioId][
					scenario.CustomerId
				].LinkedIcmItems.push(icm);
			}

			for (const scenario of icm.SuggestedScenarios) {
				if (!scenarioToIcmMap[dateKey]) scenarioToIcmMap[dateKey] = {};

				if (!scenarioToIcmMap[dateKey][scenario.ScenarioId])
					scenarioToIcmMap[dateKey][scenario.ScenarioId] = {};
				if (!scenarioToIcmMap[dateKey][scenario.ScenarioId][scenario.CustomerId])
					scenarioToIcmMap[dateKey][scenario.ScenarioId][scenario.CustomerId] = {
						LinkedIcmItems: [],
						SuggestedIcmItems: [],
					};
				scenarioToIcmMap[dateKey][scenario.ScenarioId][
					scenario.CustomerId
				].SuggestedIcmItems.push(icm);
			}
		}

		for (const date in scenarioToIcmMap) {
			for (const scenarioKey in scenarioToIcmMap[date]) {
				if (!temp[date]) {
					temp[date] = [];
				}

				const scenarioId = Number(scenarioKey);
				let scenario = temp[date].find((k) => k.Id === scenarioId);

				if (!scenario) {
					// No scenario found, create dummy scenario.
					// Can happen for scenarios that don't report every day

					// should always exist
					const scenarioMetaData = activeMinorScenarioList.find(
						(smd) => smd.Id === scenarioId
					);

					if (scenarioMetaData) {
						const missingScenarioDate: Partial<ModifiedCoreApiData> = {
							...scenarioMetaData,
						};
						scenario = missingScenarioDate as ModifiedCoreApiData;
						temp[date].push(scenario);
					}
				}

				if (scenario) {
					// should always exist due to ^
					for (const customerId in scenarioToIcmMap[date][scenarioKey]) {
						const lobKey = CustomerKeysToLobMap[customerId];
						const lobScenario = scenario[lobKey] as ModifiedReliabilityModel;
						if (scenario[lobKey]) {
							lobScenario.SuggestedIcmItems =
								scenarioToIcmMap[date][scenarioKey][customerId].SuggestedIcmItems;
							lobScenario.LinkedIcmItems =
								scenarioToIcmMap[date][scenarioKey][customerId].LinkedIcmItems;
						} else {
							scenario[lobKey] = {
								Reliability: null,
								DailyCumulativeBudget: [],
								Next9DailyCumulativeBudget: [],
								ErrorBudgetRemaining: null,
								Next9ErrorBudgetRemaining: null,
								Requests: 0,
								Success: 0,
								Latency: null,
								HasData: false,
								GraduatedScenario: null,
								LinkedIcmItems:
									scenarioToIcmMap[date][scenarioKey][customerId].LinkedIcmItems,
								SuggestedIcmItems:
									scenarioToIcmMap[date][scenarioKey][customerId]
										.SuggestedIcmItems,
								FullData: {},
							};
						}
					}
				}
			}
		}
	}, [activeMinorScenarioList, coreScenarioData, icmItems]);

	useEffect(() => {
		if (
			!isEmpty(coreScenarioData) &&
			activeMinorScenarioList.length &&
			icmItems.length &&
			!mappedIcmItems
		) {
			setMappedIcmItems(true);
			mapDailyScenarioCustomerToIcmItems();
		}
	}, [
		icmItems.length,
		coreScenarioData,
		mapDailyScenarioCustomerToIcmItems,
		mappedIcmItems,
		activeMinorScenarioList.length,
	]);

	//#endregion

	//#region Aggregates
	const getFullAggregateTuples = useCallback(() => {
		const aggKeyTuples: IAggregateKeyProp[] = [];
		const aggKeyTupleMap: Record<string, IAggregateKeyProp> = {};

		let prevAggr: IAggregateKeyProp | null = null;
		for (const month of megaMonthsIterator) {
			const monthAggrTuple: IAggregateKeyProp = {
				startDate: month.startDate,
				endDate: month.endDate,
				aggrKey: `${ month.startDate }-${ month.endDate }`,
				totalDays: month.totalDays,
				daysSeen: month.daysSeen,
			};
			if (prevAggr !== null) {
				prevAggr.nextAggrKey = monthAggrTuple.aggrKey;
				monthAggrTuple.previousAggrKey = prevAggr.aggrKey;
			}

			aggKeyTuples.push(monthAggrTuple);
			prevAggr = monthAggrTuple;

			aggKeyTupleMap[monthAggrTuple.aggrKey] = monthAggrTuple;
		}
		// If the timespan selected is not a full month, generate odd timespan tuple
		//    and attempt to generate it's previous two aggregates' tuple
		let oddTimespanTuple: IAggregateKeyProp = {
			startDate: startDate,
			endDate: endDate,
			aggrKey: `${ startDate }-${ endDate }`,
		};

		if (!megaMonths[timeframe] && !aggKeyTupleMap[oddTimespanTuple.aggrKey]) {
			let nextAggr = oddTimespanTuple;
			aggKeyTuples.push(oddTimespanTuple); // first tuple is guaranteed to be in valid range

			const tempStart = new Date(
				`${ startDate.slice(4, 6) }/${ startDate.slice(6, 8) }/${ startDate.slice(0, 4) }`
			);
			const tempEnd = new Date(
				`${ endDate.slice(4, 6) }/${ endDate.slice(6, 8) }/${ endDate.slice(0, 4) }`
			);

			// To calculate the no. of days
			const days = Math.ceil((tempEnd.getTime() - tempStart.getTime()) / (1000 * 3600 * 24));

			const possibleStart = megaMonthsIterator[0].startDate;
			for (let i = 0; i < 2; i++) {
				tempStart.setDate(tempStart.getDate() - days);
				tempEnd.setDate(tempEnd.getDate() - days);

				const startString = VF.yyyymmddDate(tempStart);
				const endString = VF.yyyymmddDate(tempEnd);

				const tempAggrKey = startString + '-' + endString;

				if (possibleStart <= startString) {
					oddTimespanTuple = {
						startDate: startString,
						endDate: endString,
						aggrKey: tempAggrKey,
					};
					nextAggr.previousAggrKey = oddTimespanTuple.aggrKey;
					oddTimespanTuple.nextAggrKey = nextAggr.aggrKey;

					aggKeyTuples.push(oddTimespanTuple);
					nextAggr = oddTimespanTuple;
				}
			}
		}

		return aggKeyTuples;
	}, [endDate, megaMonths, megaMonthsIterator, startDate, timeframe]);

	// GENERATE AGGREGATES
	useEffect(() => {
		//TODO: generate previous 2 aggregates
		if (loading) return;
		globalFilters.ownerManagers = ownersMetaData;
		const filtered = filterData(
			coreScenarioData,
			megaMonthsIterator[0].startDate,
			megaMonthsIterator[megaMonthsIterator.length - 1].endDate,
			globalFilters,
			'',
			''
		);

		const generateAggregates: Omit<AggregateAction, 'callback'> = {
			graduatedScenarios: graduatedScenarios,
			aggrKey: `${ startDate }-${ endDate }`,
			currentPage: '',
			filteredData: filtered,
			filters: globalFilters,
			// icmItems: [],
			majorScenariosList: majorScenarioList,
			minorScenariosList: activeMinorScenarioList,
			scenarioGroupList: scenarioGroupList,
			type: 'generate aggregates',
			dispatch: aggregateDispatch,
			aggrKeyProps: getFullAggregateTuples(),
		};

		aggregateDispatch(generateAggregates as Action);
	}, [
		aggregateDispatch,
		coreScenarioData,
		globalFilters,
		endDate,
		startDate,
		owner,
		engineeringManager,
		majorScenarioList,
		metaDataStatus,
		activeMinorScenarioList,
		scenarioGroupList,
		scenarioStatus,
		icmItems,
		loading,
		megaMonths,
		megaMonthsIterator,
		timeframe,
		graduatedScenarios,
		ownersMetaData,
		getFullAggregateTuples,
	]);
	//#endregion

	//#region Api Calls (dependent on global filters)
	const [initCoreData, setInitCoreData] = useState(false);
	const [initFullScenarios, setInitFullScenarios] = useState(false);

	// SET UP TRIGGER FOR API CALL ON CHANGE EXECUTIVE
	useEffect(() => setInitCoreData(false), [globalFilters.executiveFilter]);

	//API CALLS TRIGGERED BY EXECUTIVE CHANGE AND GOING TO MANAGEMENT PAGE FOR THE FIRST TIME
	useEffect(() => {
		const isManagement = location.pathname === '/management';

		if (!initCoreData) {
			// Daily Scenario Data
			coreDispatch({
				type: CoreDataActions.LoadCoreData,
				getFullScenarios: isManagement,
				apiParams: { executive: globalFilters.executiveFilter },
				dispatch: coreDispatch,
			});

			// Annotations Data
			linkedItemsDispatch({
				type: 'load annotations',
				dispatch: linkedItemsDispatch,
				executiveFilter: globalFilters.executiveFilter,
			});

			setInitCoreData(true);
			setMappedIcmItems(false);

			if (isManagement) setInitFullScenarios(true);
		} else if (isManagement && !initFullScenarios) {
			coreDispatch({
				type: CoreDataActions.LoadSelfServeScenarioMetadata,
				dispatch: coreDispatch,
				getFullScenarios: true,
			});
			coreDispatch({
				type: CoreDataActions.LoadSelfServeScenarioMetadata,
				dispatch: coreDispatch,
				getFullScenarios: true,
			});

			setInitFullScenarios(true);
		}
	}, [
		coreDispatch,
		linkedItemsDispatch,
		globalFilters.executiveFilter,
		initCoreData,
		initFullScenarios,
		location.pathname,
	]);
	//#endregion

	//#region Page Content props
	const timespanTitle = useMemo<string>(() => {
		let tempTitle = '';

		if (timeframe === TimespanEnum.Custom || timeframe === TimespanEnum.Last7Days) {
			const tempStart = new Date(
				`${ startDate.slice(4, 6) }/${ startDate.slice(6, 8) }/${ startDate.slice(0, 4) }`
			);
			const tempEnd = new Date(
				`${ endDate.slice(4, 6) }/${ endDate.slice(6, 8) }/${ endDate.slice(0, 4) }`
			);
			tempEnd.setDate(tempEnd.getDate() - 1);
			//COMPARING SAME DATE
			tempTitle =
				tempStart.getTime() === tempEnd.getTime()
					? VF.monthDayDate(tempStart)
					: startDate.slice(0, 4) !== endDate.slice(0, 4)
						? `${ VF.monthDayDate(tempStart) } ${ startDate.slice(0, 4) } - ${ VF.monthDayDate(
							tempEnd
						) } ${ endDate.slice(0, 4) }`
						: `${ VF.monthDayDate(tempStart) } - ${ VF.monthDayDate(tempEnd) }`;
		} else if (timeframe === TimespanEnum.Last6Months) {
			const tempStart = new Date(
				`${ startDate.slice(4, 6) }/${ startDate.slice(6, 8) }/${ startDate.slice(0, 4) }`
			);
			const tempEnd = new Date(
				`${ endDate.slice(4, 6) }/${ endDate.slice(6, 8) }/${ endDate.slice(0, 4) }`
			);
			tempEnd.setDate(tempEnd.getDate() - 1);
			tempTitle = `${ VF.shortMonth(tempStart) } ${ tempStart.getFullYear() } - ${ VF.shortMonth(
				tempEnd
			) } ${ tempEnd.getFullYear() }`;
		} else if (timeframe === TimespanEnum.Yesterday) {
			const tempStart = new Date(
				`${ startDate.slice(4, 6) }/${ startDate.slice(6, 8) }/${ startDate.slice(0, 4) }`
			);
			tempTitle = VF.monthDayDate(tempStart);
		} else {
			const curMonth = megaMonths[timeframe];
			tempTitle = `${ curMonth.shortMonth } ${ curMonth.year }`;
		}
		return tempTitle;
	}, [endDate, megaMonths, startDate, timeframe]);

	const [initUserMetaData, setInitUserMetaData] = useState(true);

	useEffect(() => {
		if (initUserMetaData && userMetadata.EngineeringManagerAlias) {
			setInitUserMetaData(false);
			globalFiltersDispatch({
				type: 'initialize engineering manager',
				engineeringManager: userMetadata.EngineeringManagerAlias,
			});
		}
	}, [globalFiltersDispatch, initUserMetaData, userMetadata.EngineeringManagerAlias]);

	const layoutProps = {
		loading,
		timespanTitle,
		tableRef,
		setTableRef,
		tableRefInView,
		viewsContentRef,
		onScrollToTable,
	};
	//#endregion

	// eslint-disable-next-line react/react-in-jsx-scope
	return <DashboardLayout { ...props } { ...layoutProps } />;
}

const withProviders = (props: ContainerRouteProps): JSX.Element => {
	return (
		<AggregateProvider>
			<LinkedItemsProvider>
				<CoreDataProvider>
					<GlobalFilterProvider>
						<DashboardContainer { ...props } />
					</GlobalFilterProvider>
				</CoreDataProvider>
			</LinkedItemsProvider>
		</AggregateProvider>
	);
};

export default withProviders;
