import { ContainerRouteProps } from 'Components/Shell/Shell.types';

/**
 * All Line of Business AND Overall.
 * Do not use this for iterating
 * @export
 * @enum {number}
 */
export enum LobEnum {
	Xbox = 'Xbox',
	Office = 'Office',
	AzureDirect = 'AzureDirect',
	AzureField = 'AzureField',
	M365 = 'M365',
	Partner = 'Partner',
	Microsoft = 'Microsoft',
	Marketplace = 'Marketplace',
	All = 'All',
	//WINDOWS = "Windows",
	//UNKNOWN = "Unknown",
	Overall = 'Overall',
}

/**
 * All Channel Groups.
 * Do not use this for iterating.
 * @export
 * @enum {number}
 */
export enum ChannelGroupEnum {
	Consumer = 'Consumer',
	Commercial = 'Commercial',
}

export type ChannelGroupMapping = {
	[k in ChannelGroupEnum]: LobEnum[];
};

export type CustKeyToLobMap = Record<number, LobEnum>;
export type LobToCustKeyMap = Record<LobEnum, CustomerId>;

/** eg: "20191201-20200501" */
export type AggregateKey = string;
export type Nines = 0 | 1 | 2 | 3 | 4;

/** 999999999 is dummy.  Do not use */
export type CustomerId = /*0 | 1 |*/ 2 | 3 | 4 | 5 | 6 | /*| 7*/ 8 | 9 | 10 | 11 | 999999999;

export enum TimespanEnum {
	Last6Months = 'Last 6 Months',
	Yesterday = 'Yesterday',
	Last7Days = 'Last 7 Days',
	Custom = 'Custom',
}

export enum ExecutiveEnum {
	Executive = 'Executive',
	NonExecutive = 'NonExecutive',
	All = 'All',
}

export type ScenarioHealthContainersProps = ContainerRouteProps & {
	loading: boolean;
	timespanTitle: string;
	tableRef: React.RefObject<HTMLDivElement>;
	setTableRef: (node: HTMLDivElement) => void;
	tableRefInView: boolean;
	viewsContentRef: React.RefObject<HTMLDivElement>;
	onScrollToTable: () => void;
};

export interface Duration {
	days: number;
	hours: number;
	minutes: number;
	fullMilliSeconds: number;
}
