/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IRefObject } from '@fluentui/react';
import { saveAs } from 'file-saver';
import { toBlob } from 'html-to-image';
import { TelemetryService } from 'Utils/TelemetryService';

export const takeSnapshot = (
	componentDivRef: IRefObject<HTMLElement | null>,
	imageName: string,
	backgroundColor: string,
	callback: (value: Blob | null, toClipboard: boolean) => void
): void => {
	const isFirefox = 'InstallTrigger' in window;

	// No Ref passed in
	if (typeof componentDivRef === 'function' || !componentDivRef.current) {
		TelemetryService.trackException('Screenshot', {
			error: 'No ref provided',
			image: imageName,
			stage: 'ref',
		});
		return callback(null, false);
	}

	toBlob(componentDivRef.current, { backgroundColor: backgroundColor, skipFonts: true })
		.then((blob) => {
			if (blob) {
				if (isFirefox || !navigator.clipboard.write) {
					saveAs(blob, `${imageName}.png`);
					return callback(blob, false);
				} else {
					//.write is still in the expimental stage and means different things for each browser.
					// it may not exist or may only support text
					// need multiple catches for the different scenarios
					try {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						navigator.clipboard
							.write([new ClipboardItem({ 'image/png': blob })])
							.then(() => callback(blob, true))
							.catch((err: Error) => {
								TelemetryService.trackException('Screenshot', {
									//TODO: may be overzealous tracking
									error: err,
									image: imageName,
									stage: 'clipboard write',
								});
								saveAs(blob, `${imageName}.png`);
								return callback(blob, false);
							});
					} catch (err) {
						TelemetryService.trackException('Screenshot', {
							//TODO: may be overzealous tracking
							error: err,
							image: imageName,
							stage: 'navigator clipboard',
						});
						saveAs(blob, `${imageName}.png`);
						return callback(blob, false);
					}
				}
			} else throw new Error('Blob returned null');
		})
		.catch((err: Error) => {
			TelemetryService.trackException('Screenshot', {
				error: err,
				image: imageName,
				stage: 'to blob',
			});
			return callback(null, false);
		});
};
