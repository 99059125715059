import {
	CommandBarButton,
	FontWeights,
	IButtonProps,
	IButtonStyles,
	ICommandBarItemProps,
	IconButton,
	IIconStyles,
	ILayerStyles,
	IMessageBarStyles,
	IRefObject,
	Layer,
	mergeStyleSets,
	MessageBar,
} from '@fluentui/react';
import { useSetTimeout } from '@fluentui/react-hooks';
import { NeutralColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import { IComponentScreenshotProps } from 'Components/ComponentScreenshot/ComponentScreenshot.types';
import { takeSnapshot } from 'Components/ComponentScreenshot/CopyToClipboard';
import { captureScreenshotStyles } from 'Components/CustomIcons/CustomIcons';
import { useThemeContext } from 'Context/ThemeContext/ThemeContext';
import React, { memo, RefObject, useEffect, useState } from 'react';
import { TooltipHostSHDefault } from 'Views/Layout.Shared';

const buttonStyle: IButtonStyles = {
	root: {
		height: '100%',
	},
};

function ComponentScreenshotBase({
	componentDivRef,
	screenshotTitle,
	messageBarStyles,
	layerStyles,
	buttonStyles,
	iconStyle,
	backgroundColor = 'white',
	disabled,
	beforeScreenshot,
	afterScreenshot,
}: IComponentScreenshotProps): JSX.Element {
	const theme = useThemeContext();

	const [message, setMessage] = useState('');
	const [processing, setProcessing] = useState(false);

	const { setTimeout, clearTimeout } = useSetTimeout();

	useEffect(() => {
		if (message && !processing) {
			// close message after 5 seconds
			const id = setTimeout(() => setMessage(''), 5000);
			return () => {
				clearTimeout(id);
			};
		}
	}, [clearTimeout, processing, message, setTimeout]);

	const screenshotName = screenshotTitle ? screenshotTitle : 'Screenshot';

	const defaultScreenshotIconStyle: IIconStyles = {
		root: [
			disabled && {
				selectors: {
					'.capture-screenshot-fill': {
						fill: theme?.semanticColors.disabledBodySubtext,
						'&:hover': {
							fill: theme?.semanticColors.disabledBodySubtext,
						},
					},
				},
			},
		],
	};

	const screenshotIconStyle: IIconStyles = mergeStyleSets({
		...defaultScreenshotIconStyle,
		...iconStyle,
	});

	const buttonProps: IButtonProps = {
		iconProps: {
			iconName: processing ? 'Play' : 'capture-screenshot',
			styles: captureScreenshotStyles({
				theme: theme,
				isImage: false,
				isPlaceholder: false,
				styles: screenshotIconStyle,
			}),
		},
		onClick: () => {
			if (componentDivRef) {
				if (beforeScreenshot) beforeScreenshot();
				setProcessing(true);
				setMessage('Processing screenshot...');
				takeSnapshot(
					componentDivRef as RefObject<HTMLElement | null>,
					screenshotName,
					backgroundColor,
					(value: Blob | null, toClipboard: boolean) => {
						if (!value) setMessage('Something went wrong, please try again.');
						else {
							setMessage(
								toClipboard
									? 'Image copied to clipboard!'
									: 'Screenshot is downloaded!'
							);
						}
						setProcessing(false);
						if (afterScreenshot) afterScreenshot();
					}
				);
			}
		},
		ariaLabel: `${screenshotName}`,
		disabled: !!processing || !!disabled,
	};

	return (
		<>
			<TooltipHostSHDefault content={`${screenshotName}`}>
				{buttonStyles ? (
					<CommandBarButton {...buttonProps} role="menuitem" styles={buttonStyles} />
				) : (
					<IconButton {...buttonProps} />
				)}
			</TooltipHostSHDefault>
			{message ? (
				<Layer styles={{ ...defaultLayerStyles, ...layerStyles }}>
					<MessageBar
						styles={{ ...defaultMessageBarStyles, ...messageBarStyles }}
						onDismiss={() => {
							setMessage('');
						}}
					>
						{message}
					</MessageBar>
				</Layer>
			) : null}
		</>
	);
}

export const ComponentScreenshot = memo(ComponentScreenshotBase);

export const commandBarButtonComponentScreenshot = (
	tableRef: IRefObject<HTMLDivElement>,
	screenshotTitle: string,
	beforeScreenshot?: () => void,
	afterScreenshot?: () => void,
	disabled?: boolean
): ICommandBarItemProps => {
	return {
		key: screenshotTitle,
		ariaLabel: screenshotTitle,
		onRender: (props: ICommandBarItemProps) => (
			<ComponentScreenshot
				componentDivRef={tableRef}
				buttonStyles={props.buttonStyles || buttonStyle}
				screenshotTitle={screenshotTitle}
				disabled={!!disabled}
				beforeScreenshot={beforeScreenshot}
				afterScreenshot={afterScreenshot}
			/>
		),
	};
};

const defaultMessageBarStyles: IMessageBarStyles = {
	root: {
		background: NeutralColors.black,
		color: NeutralColors.white,
		borderRadius: 4,
		padding: 12,
		marginBottom: 12,
	},
	text: { fontWeight: FontWeights.bold },
	icon: { color: NeutralColors.white },
	//Cancel Icon Style
	dismissal: {
		borderLeft: `1px ${NeutralColors.white} solid`,
		'.ms-Icon': { color: NeutralColors.white },
	},
};

const defaultLayerStyles: ILayerStyles = {
	root: { top: 'unset', maxWidth: 350, margin: 'auto' },
};
