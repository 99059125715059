import {
	AllDate,
	AllDates,
	GlobalFiltersAction,
	IGlobalFiltersDispatch,
	IGlobalFiltersState,
	IInProgressMonth,
	IMegaMonth,
	IMegaMonths,
	IMegaMonthsState,
	IUrlParams
} from 'Context/GlobalFiltersContext/GlobalFiltersContext.types';
import { PeopleMetadata } from 'DataModels/CoreDataModels.types';
import { TimespanEnum } from 'DataModels/Global.types';
import querystringify from 'querystringify';
import React, {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VF from 'Utils/ValueFormatting';

const defaultState: IGlobalFiltersState = {
	benchmark: 99.99,
	endDate: '',
	engineeringManager: null,
	executiveFilter: 'Executive',
	keyword: '',
	owner: null,
	startDate: '',
	timeframe: '',
	shTable: 'all',
	shCard: null,
	shNines: false,
	shBlendedIndex: false,
	shMetTarget: false,
	gapTable: 'all',
	serviceGroup: null,
	icmType: 'all',
	graphShape: 'oval',
	scenarioId: null,
	customerId: null,
	ownerManagers: {},
};

const GlobalFiltersContext = createContext<IGlobalFiltersState | undefined>(undefined);
const GlobalFiltersDispatch = createContext<IGlobalFiltersDispatch | undefined>(undefined);
const FullMegaMonthsContext = createContext<IMegaMonthsState | undefined>(undefined);

function GlobalFilterProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
	const navigate = useNavigate();
	const location = useLocation();
	const megaMonths = useMemo(getFilterCustomDates, []);
	const megaMonthsIterator = useMemo<IMegaMonth[]>(() => Object.values(megaMonths), [megaMonths]);
	const [inProgressMonth, setInProgressMonth] = useState<IInProgressMonth>(null);
	const { allDatesMap, allDatesIterator } = useMemo(
		() => getAllDates(megaMonthsIterator),
		[megaMonthsIterator]
	);
	const [megaMonthsState /*, setMegaMonthsState*/] = useState<IMegaMonthsState>({
		megaMonths,
		megaMonthsIterator,
		inProgressMonth,
		today: new Date(),
		allDatesMap,
		allDatesIterator,
	});
	const [urlParams /*, setUrlParams*/] = useState(getUrlParams());

	const globalFiltersReducer = useCallback(
		(prevState: IGlobalFiltersState, action: GlobalFiltersAction): IGlobalFiltersState => {
			switch (action.type) {
				case 'initialize engineering manager': {
					if (
						urlParams.engineeringmanager === undefined ||
						urlParams.engineeringmanager === ''
					) {
						return {
							...prevState,
							engineeringManager:
								typeof action.engineeringManager === 'string'
									? action.engineeringManager.toLowerCase()
									: null,
						};
					}
					return prevState;
				}
				case 'set engineering manager': {
					return {
						...prevState,
						engineeringManager:
							typeof action.engineeringManager === 'string'
								? action.engineeringManager.toLowerCase()
								: null,
						shCard: null,
					};
				}
				case 'set owner': {
					return {
						...prevState,
						owner: typeof action.owner === 'string' ? action.owner.toLowerCase() : null,
					};
				}
				case 'set executive': {
					return { ...prevState, executiveFilter: action.executiveFilter };
				}
				case 'set keyword': {
					//start timeout for updates
					updateKeywordWithTimeout(action.keyword);
					return prevState;
				}
				case '_internal finalize keyword': {
					return { ...prevState, keyword: action.keyword };
				}
				case 'set timeframe': {
					return {
						...prevState,
						timeframe: action.timeframe,
						startDate: action.startDate,
						endDate: action.endDate,
					};
				}
				case 'set shcard': {
					return { ...prevState, shCard: action.lowerCaseCardName };
				}
				case 'set shnines': {
					return { ...prevState, shNines: action.showNinesGraphs };
				}
				case 'set shblended': {
					return { ...prevState, shBlendedIndex: action.showBlendedIndex };
				}
				case 'set shmettarget': {
					return { ...prevState, shMetTarget: action.showMetTarget };
				}
				case 'set shtable': {
					return { ...prevState, shTable: action.tableName };
				}
				case 'set gaptable': {
					return { ...prevState, gapTable: action.tableName };
				}
				case 'set icmType': {
					return { ...prevState, icmType: action.icmType };
				}
				case 'set graphshape': {
					return { ...prevState, graphShape: action.graphShape };
				}
				case 'set servicegroup': {
					return {
						...prevState,
						serviceGroup:
							typeof action.serviceGroup === 'string'
								? action.serviceGroup.toLowerCase()
								: null,
					};
				}
				case 'reset visible filters': {
					let startMonth = megaMonthsIterator[megaMonthsIterator.length - 1];
					const currentDate = new Date().getDate();
					if (currentDate <= 25) startMonth = startMonth.previousMonth as IMegaMonth;
					return {
						...prevState,
						endDate: startMonth.endDate,
						startDate: startMonth.startDate,
						timeframe: startMonth.startDate,
						owner: null,
						keyword: prevState.keyword,
						executiveFilter: 'Executive',
						shNines: false,
					};
				}
				default:
					throw new Error(`Unhandled action: ${ (action as GlobalFiltersAction).type }`);
			}
		},
		[megaMonthsIterator, urlParams.engineeringmanager]
	);

	const keywordTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();

	const initGlobalFilters = useCallback(
		(initial: IGlobalFiltersState): IGlobalFiltersState => {
			const ret: IGlobalFiltersState = {
				...initial,
			};

			if (urlParams.engineeringmanager) ret.engineeringManager = urlParams.engineeringmanager;
			if (urlParams.owner) ret.owner = urlParams.owner;
			if (urlParams.benchmark) ret.benchmark = Number(urlParams.benchmark);
			if (urlParams.keyword) ret.keyword = urlParams.keyword;
			if (urlParams.executivefilter !== undefined) ret.executiveFilter = urlParams.executivefilter;
			if (urlParams.startdate) ret.startDate = urlParams.startdate;
			if (urlParams.enddate) ret.endDate = urlParams.enddate;
			if (urlParams.timeframe) ret.timeframe = urlParams.timeframe;
			if (urlParams.shnines !== undefined) ret.shNines = urlParams.shnines === true;
			if (urlParams.shblendedindex !== undefined)
				ret.shBlendedIndex = urlParams.shblendedindex === true;
			if (urlParams.shmettarget !== undefined)
				ret.shMetTarget = urlParams.shmettarget === true;
			if (
				urlParams.shtable === 'all' ||
				urlParams.shtable === 'missingtarget' ||
				urlParams.shtable === 'improvements' ||
				urlParams.shtable === 'isnext9'
			)
				ret.shTable = urlParams.shtable;
			if (
				urlParams.gaptable === 'all' ||
				urlParams.gaptable === 'mapped' ||
				urlParams.gaptable === 'unmapped'
			)
				ret.gapTable = urlParams.gaptable;
			if (
				urlParams.icmtype === 'all' ||
				urlParams.icmtype === 'linked' ||
				urlParams.icmtype === 'suggested'
			)
				ret.icmType = urlParams.icmtype;

			if (urlParams.graphshape === 'line' || urlParams.graphshape === 'oval')
				ret.graphShape = urlParams.graphshape;
			if (urlParams.shcard) ret.shCard = urlParams.shcard;
			if (urlParams.servicegroup) ret.serviceGroup = urlParams.servicegroup;
			if (
				urlParams.scenarioid &&
				urlParams.customerid &&
				!isNaN(urlParams.customerid) &&
				!isNaN(urlParams.scenarioid)
			) {
				ret.scenarioId = Number(urlParams.scenarioid);
				ret.customerId = Number(urlParams.customerid);
				ret.keyword = '';
				ret.engineeringManager = null;
			}

			return ret;
		},
		[urlParams]
	);

	const [globalFilters, globalFiltersDispatch] = useReducer(
		globalFiltersReducer,
		defaultState,
		initGlobalFilters
	);

	//UPDATE URL SEARCH PARAMS
	useEffect(() => {
		let search = '?';
		Object.entries<string | number | null | boolean | Record<string, PeopleMetadata>>(globalFilters).forEach((pair) => {
			// let isArray = Array.isArray(pair[1]);
			// let val = isArray && (pair[1] as Array<string>).length > 1 ? `[${ (pair[1] as Array<string>).join(",") }]` : pair[1];
			if (pair[0] === 'ownerManagers') {
				return;//skip ownerManagers
			}
			const val = pair[1] as any;
			const key = pair[0] as keyof IGlobalFiltersState;
			const urlParamsKey = key.toLowerCase() as keyof IUrlParams;

			// if (pair[0] === "benchmark") val = (val as number).toString();
			// change default value to a string to make comparisons easier
			let defaultValue = defaultState[key];
			defaultValue = Array.isArray(defaultValue)
				? defaultValue.length === 1
					? defaultValue[0]
					: `[${ defaultValue.join(',') }]`
				: defaultValue;
			defaultValue = defaultValue === '[]' ? null : defaultValue;

			// track what filters have been changed.
			const matchesDefault = defaultValue !== undefined && val === defaultValue;
			let hasBeenModified = !matchesDefault || urlParams[urlParamsKey] !== undefined;
			if (!matchesDefault && hasBeenModified) {
				hasBeenModified = true;
				urlParams[urlParamsKey] = val;
			}

			if (
				((val !== null &&
					val !== undefined &&
					val !== '' && //TODO: omit all hidden params (just timeFame for now)
					!matchesDefault) ||
					hasBeenModified) &&
				pair[0] !== 'timeframe'
			) {
				search += `${ pair[0] }=${ val }&`;
			}
		});

		search = search.substr(0, search.length - 1);

		if (/(?<oldParams>aggregate|monthyear)/gi.test(location.search)) {
			//Update history when changing url params due to old page -> new page navigation
			navigate({ pathname: location.pathname, search: search });
		} else if (location.search !== search) {
			//Update history when changing in CoreUX
			navigate({ pathname: location.pathname, search: search });
		}
	}, [globalFilters, location.pathname, location.search, navigate, urlParams]);

	//UPDATE IN PROGRESSMONTH
	useEffect(() => {
		const { startDate, endDate, timeframe } = globalFilters;
		const tempCurrentDate = new Date();
		const currentMonth = tempCurrentDate.getMonth() + 1;
		const currentDate = tempCurrentDate.getDate();
		let inProgressMonth: IInProgressMonth = currentMonth as IInProgressMonth;

		if (
			currentDate > 25 ||
			timeframe === TimespanEnum.Last6Months ||
			parseInt(startDate.slice(4, 6), 10) === currentMonth ||
			(parseInt(endDate.slice(4, 6), 10) === currentMonth && endDate.slice(6) !== '01')
		) {
			inProgressMonth = null;
		}
		setInProgressMonth(inProgressMonth);
		megaMonthsState.inProgressMonth = inProgressMonth;
	}, [globalFilters, megaMonthsState]);

	return (
		<FullMegaMonthsContext.Provider value={ megaMonthsState }>
			<GlobalFiltersContext.Provider value={ globalFilters }>
				<GlobalFiltersDispatch.Provider value={ globalFiltersDispatch }>
					{ children }
				</GlobalFiltersDispatch.Provider>
			</GlobalFiltersContext.Provider>
		</FullMegaMonthsContext.Provider>
	);

	function updateKeywordWithTimeout(value: string) {
		//TODO: consider replacing this with debounce
		// https://github.com/microsoft/fluentui/blob/master/packages/utilities/src/Async.ts#L308
		if (keywordTimeout.current) clearTimeout(keywordTimeout.current);
		keywordTimeout.current = setTimeout(() => {
			globalFiltersDispatch({ type: '_internal finalize keyword', keyword: value || '' });
		}, 1000);
	}

	/**
	 * Generates an object representing the past 6 months.
	 * @returns {Object} { monthKey: { monthProperties: ...} }
	 */
	function getFilterCustomDates(): IMegaMonths {
		const rightnow = new Date();
		const currentMonth = new Date(rightnow);
		currentMonth.setHours(0, 0, 0, 0);
		currentMonth.setDate(1);

		const followingMonth = new Date(currentMonth);
		followingMonth.setMonth(currentMonth.getMonth() + 1);

		const months: IMegaMonths = {};
		const prevMonth = null;
		let nextMonth = null;

		for (let i = 0; i < 6; i++) {
			//Format: 2019-04-23
			const monthKey = VF.yyyymmddDate(currentMonth);
			const endStr = VF.yyyymmddDate(followingMonth);

			// For totalDays.  Do not use differnceInDHM.  It will be short in march (daylight savings)
			const curMonthEnd = new Date(followingMonth);
			curMonthEnd.setDate(curMonthEnd.getDate() - 1);

			months[monthKey] = {
				// tooltip: tooltipString(currentMonth),
				// ago: ago(currentMonth),
				// log: log(currentMonth),
				// duration: duration(currentMonth, rightnow), //not super useful
				fullMonth: VF.longMonth(currentMonth),
				shortMonth: VF.shortMonth(currentMonth),
				year: currentMonth.getFullYear(),
				startDate: monthKey,
				date: new Date(currentMonth),
				endDate: endStr,
				previousMonth: prevMonth,
				nextMonth: nextMonth,
				aggrKey: `${ monthKey }-${ endStr }`,
				totalDays: curMonthEnd.getDate(),
				daysSeen: i === 0 ? rightnow.getDate() - 1 : curMonthEnd.getDate(),
			};

			// Add a day to always show projection on last day of the most recent month,
			//even if the realiability data for all days is already available
			if (i === 0 && months[monthKey].daysSeen === months[monthKey].totalDays - 1) {
				months[monthKey].totalDays += 1;
			}

			if (nextMonth) nextMonth.previousMonth = months[monthKey];
			nextMonth = months[monthKey];

			followingMonth.setMonth(followingMonth.getMonth() - 1);
			currentMonth.setMonth(currentMonth.getMonth() - 1);

			if (i === 1 && rightnow.getDate() >= 26) {
				defaultState.timeframe = (months[monthKey].nextMonth as IMegaMonth).startDate;
				defaultState.startDate = (months[monthKey].nextMonth as IMegaMonth).startDate;
				defaultState.endDate = (months[monthKey].nextMonth as IMegaMonth).endDate;
			} else if (i === 1 && rightnow.getDate() < 26) {
				defaultState.timeframe = months[monthKey].startDate;
				defaultState.startDate = months[monthKey].startDate;
				defaultState.endDate = months[monthKey].endDate;
			}
		}

		return months;
	}

	function getUrlParams() {
		const urlParams = querystringify.parse(
			location.search.toLowerCase()
		) as Partial<IUrlParams>;
		if (urlParams.executivefilter) {
			if (urlParams.executivefilter.toLowerCase() == 'executive') {
				urlParams.executivefilter = 'Executive';
			} else if (urlParams.executivefilter.toLowerCase() == 'nonexecutive') {
				urlParams.executivefilter = 'NonExecutive';
			} else if (urlParams.executivefilter.toLowerCase() == 'all') {
				urlParams.executivefilter = 'All';
			} else {
				urlParams.executivefilter = 'Executive';
			}
		}
		if (urlParams.engineeringmanager && urlParams.engineeringmanager === 'null')
			urlParams.engineeringmanager = null;
		if (urlParams.owner && urlParams.owner === 'null') urlParams.owner = null;
		if (urlParams.shnines) urlParams.shnines = urlParams.shnines !== 'false';
		if (urlParams.servicegroup)
			if (urlParams.servicegroup && urlParams.servicegroup === 'null')
				urlParams.servicegroup = null;
		if (urlParams.shblendedindex)
			urlParams.shblendedindex = urlParams.shblendedindex !== 'false';
		if (urlParams.shmettarget) urlParams.shmettarget = urlParams.shmettarget !== 'false';

		if (urlParams.shcard)
			//TODO: Temporary. Forcing old values into new standard to not break bookmarks
			urlParams.shcard =
				urlParams.shcard === 'health index' || urlParams.shcard === 'null'
					? null
					: urlParams.shcard;

		const start = urlParams['startdate'];
		const end = urlParams['enddate'];

		let invalidValues = true;

		if (start && end && start < end) {
			//Get range of possible dates
			const possibleStart = megaMonthsIterator[0].startDate;
			const possibleEnd = megaMonthsIterator[megaMonthsIterator.length - 1].endDate;

			//Check for valid dates and and set timeframe appropriately
			const dateReg = /(?<year>20\d{2}(?<month>0[1-9]|1[0-2])(?<day>0[1-9]|[12]\d|3[01]))/;
			if (
				dateReg.test(start) &&
				start >= possibleStart &&
				start < possibleEnd &&
				dateReg.test(end) &&
				end > possibleStart &&
				end <= possibleEnd
			) {
				let today: Date | string = new Date();
				today.setHours(0, 0, 0, 0);
				today = VF.yyyymmddDate(today);

				switch (true) {
					case megaMonths[start] && megaMonths[start].endDate === end:
						urlParams['timeframe'] = start; // Current month
						break;
					case start === possibleStart && end === possibleEnd:
						urlParams['timeframe'] = TimespanEnum.Last6Months;
						break;
					case end === today && parseInt(end, 10) - parseInt(start, 10) === 7:
						urlParams['timeframe'] = TimespanEnum.Last7Days;
						break;
					default:
						urlParams['timeframe'] = TimespanEnum.Custom;
						break;
				}
				invalidValues = false;
			}
		}

		if (invalidValues) {
			// At least one invalid date.  Ignore url params
			Reflect.deleteProperty(urlParams, 'startdate');
			Reflect.deleteProperty(urlParams, 'enddate');
		}
		return urlParams;
	}
}

function getAllDates(megaMonthsIterator: IMegaMonth[]) {
	const allDatesMap: AllDates = {};
	const allDatesIterator: AllDate[] = [];
	let dateKey = '';
	for (const megaMonth of megaMonthsIterator) {
		dateKey = megaMonth.startDate;
		const iteratorDate = new Date(megaMonth.date);

		for (let i = 0; i < megaMonth.totalDays; i++) {
			allDatesMap[dateKey] = {
				date: new Date(iteratorDate),
				index: allDatesIterator.length,
				aggrKey: dateKey,
			};
			allDatesIterator.push(allDatesMap[dateKey]);

			iteratorDate.setDate(iteratorDate.getDate() + 1);
			dateKey = VF.yyyymmddDate(iteratorDate);
		}
	}

	const finalDate = megaMonthsIterator[megaMonthsIterator.length - 1].date;
	finalDate.setMonth(finalDate.getMonth() + 1);
	dateKey = VF.yyyymmddDate(finalDate);

	allDatesMap[dateKey] = {
		date: new Date(finalDate),
		index: allDatesIterator.length,
		aggrKey: dateKey,
	};
	allDatesIterator.push(allDatesMap[dateKey]);

	return { allDatesMap, allDatesIterator };
}

function useGlobalFilters(): IGlobalFiltersState {
	const context = React.useContext(GlobalFiltersContext);
	if (context === undefined) {
		throw new Error('useGlobalFilters must be used within a GlobalFilterProvider ');
	}
	return context;
}

function useGlobalFiltersDispatch(): IGlobalFiltersDispatch {
	const context = React.useContext(GlobalFiltersDispatch);
	if (context === undefined) {
		throw new Error('useGlobalFiltersDispatch must be used within a GlobalFiltersProvider ');
	}

	return context;
}

function useMegaMonths(): IMegaMonthsState {
	const context = React.useContext(FullMegaMonthsContext);
	if (context === undefined) {
		throw new Error('useMegaMonths must be used within a GlobalFilterProvider ');
	}
	return context;
}

function useGlobalFiltersProvider() {
	return {
		globalFilters: useGlobalFilters(),
		globalFiltersDispatch: useGlobalFiltersDispatch(),
		megaMonths: useMegaMonths(),
	};
}

GlobalFilterProvider.useGlobalFiltersProvider = useGlobalFiltersProvider;

export { GlobalFilterProvider, useGlobalFilters, useGlobalFiltersDispatch, useMegaMonths };

