import { classNamesFunction, ISearchBoxStyleProps, IStyle, IToggleStyleProps } from '@fluentui/react';
import { IFilterBarStyleProps, IFilterBarStyles } from "Components/FilterBar/FilterBar.types";

const rightAlignChevronIcons: IStyle = {
	display: 'flex',
	flexGrow: 1,
	justifyContent: 'flex-end'
}

export const filterBarStyles = (props: IFilterBarStyleProps): IFilterBarStyles => {
	const { theme } = props;
	const { palette, effects } = theme;
	return {
		subComponentStyles: {
			overflowSet: {
				root: {
					background: palette.neutralLight,
					borderRadius: effects.roundedCorner4,
					padding: "4px 16px",
					boxShadow: effects.elevation8,
					flexGrow: 1,
					display: 'flex',
					minHeight: 29,
					// alignItems: 'stretch',
				},
				item: {
					// flexGrow: 1,
					margin: '0px 4px',
					selectors: {
						'&:nth-child(1)': {
							flexGrow: 5
						},
						'&:nth-last-child(-n+2)': {
							flexGrow: 0
						},
					}
				}
			},
			keywordSearch: ({ hasFocus }: ISearchBoxStyleProps) => {
				return {
					root: [
						{
							height: "100%",
							width: "100%",
							border: 0,
							outline: 0,
							background: "inherit",
							padding: 0,
						},
						hasFocus && [
							'is-active',
							{
								selectors: {
									':after': {
										borderBottom: 'none !important',
									}
								}
							},

						],
					],
					icon: {
						color: palette.neutralTertiary,

					},
					iconContainer: [hasFocus && { width: 32 }]
				}
			},
			menuButton: {
				root: {
					background: "inherit",
					width: "100%",
					verticalAlign: 'middle'
				},
				rootHovered: {
					background: "inherit",
				},
				flexContainer: {
					justifyContent: "space-between"
				},
				menuIcon: rightAlignChevronIcons,
				menuIconExpanded: rightAlignChevronIcons
			},
			ownerMenu: {
				// calloutMain: {
				// 	maxHeight: '300px !important'
				// },
			},
			toggles: (isInOverflow?: boolean) => ({ checked }: IToggleStyleProps) => {
				return {
					root: [
						{
							height: "100%",
							width: "100%",
							marginBottom: 0,
							justifyContent: 'flex-end',
							flexDirection: !isInOverflow ? 'row-reverse' : 'row'
						},
						isInOverflow && {
							justifyContent: 'flex-start',
							padding: "4px 8px"
						},
						!isInOverflow && {
							paddingRight: 8
						},
					],
					pill: [
						!checked && !isInOverflow && {
							backgroundColor: 'inherit',
						}
					],
					label: [
						!isInOverflow && {
							marginRight: 8,
							marginLeft: 0
						}
					]
				}
			}
		}
	}
}

export const getFilterBarClassNames =
	classNamesFunction<IFilterBarStyleProps, IFilterBarStyles>();
