import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
	ApplicationInsights,
	ITelemetryItem,
	SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { SometimesAuthHeaders } from 'API/Api.types';
import { History } from 'history';

type TelemetryServiceProps = {
	history: History;
};

type SometimesProperties = { requestHeaders?: SometimesAuthHeaders;[key: string]: unknown };
type RequiredPropertiesWithRequestHeaders = {
	properties: Required<SometimesProperties>;
	[key: string]: unknown;
};

export const reactAppInsights = new ReactPlugin();

export class TelemetryService {
	// static reactPlugin: ReactPlugin;
	static appInsights: ApplicationInsights;

	static initialize(reactPluginConfig: TelemetryServiceProps, username: string): void {
		const INSTRUMENTATION_KEY = process.env.REACT_APP_INSTRUMENTATION_KEY;
		// if (!this.reactPlugin) this.reactPlugin = new ReactPlugin();
		if (!this.appInsights) {
			this.appInsights = new ApplicationInsights({
				config: {
					disableFetchTracking: false,
					autoTrackPageVisitTime: true,
					enableAutoRouteTracking: true,
					enableRequestHeaderTracking: true,
					enableResponseHeaderTracking: true,
					enableCorsCorrelation: true,
					enableDebug: process.env.NODE_ENV.toLowerCase() === 'development',
					extensionConfig: {
						[reactAppInsights.identifier]: reactPluginConfig,
					},
					extensions: [reactAppInsights],
					instrumentationKey: INSTRUMENTATION_KEY,
					maxBatchInterval: 0,
				},
			});

			this.appInsights.loadAppInsights();
			this.appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
				if (
					(item.baseData?.properties as SometimesProperties)?.requestHeaders
						?.Authorization
				) {
					(
						item.baseData as RequiredPropertiesWithRequestHeaders
					).properties.requestHeaders.Authorization = '';
				}
			});
			// eslint-disable-next-line no-underscore-dangle
			this.appInsights.setAuthenticatedUserContext(username ?? '');
		}
	}

	/**
	 * Logs exception to Application Insights.
	 * @param {string} errorMessage Error message.
	 * @param {Object[]} properties Object of custom metrics.
	 * @returns {null} Nothing
	 */
	static trackException(errorMessage: string, properties: Record<string, unknown>): void {
		if (this.appInsights) {
			this.appInsights.trackException({
				error: new Error(errorMessage),
				properties,
				severityLevel: SeverityLevel.Error,
			});
			this.appInsights.flush();
		}
	}

	/**
	 * Logs event to Application Insights.
	 * @param {string} name Event name.
	 * @param {Object[]} properties Object of custom metrics.
	 * @returns {null} Nothing
	 */
	static trackEvent(name: string, properties: Record<string, unknown>): void {
		if (this.appInsights) {
			this.appInsights.trackEvent({
				name,
				properties,
			});
			this.appInsights.flush();
		}
	}

	/**
	 * Logs trace event to Application Insights.
	 * @param {string} message Trace message.
	 * @param {Object[]} properties Object of custom metrics.
	 * @returns {null} Nothing
	 */
	static trackTrace(message: string, properties: Record<string, unknown>): void {
		if (this.appInsights) {
			this.appInsights.trackTrace({
				message,
				properties,
				severityLevel: SeverityLevel.Information,
			});
			this.appInsights.flush();
		}
	}

	/**
	 * Logs PageView to Application Insights.
	 * @param {string} name Event name.
	 * @param {Object[]} properties Object of custom metrics.
	 * @returns {null} Nothing
	 */
	static trackPageView(name: string, properties: Record<string, unknown>): void {
		if (this.appInsights) {
			this.appInsights.trackPageView({
				name,
				properties,
			});
			this.appInsights.flush();
		}
	}

	static setAuthenticatedUserContext(userId: string): void {
		if (this.appInsights) {
			this.appInsights.setAuthenticatedUserContext(userId);
		}
	}
}
