import {
	Icon,
	IIconProps,
	IIconStyleProps,
	IIconStyles,
	mergeStyleSets,
	registerIcons,
} from '@fluentui/react';
import React from 'react';

export const registerCustomIcons = (): void =>
	registerIcons({
		icons: {
			'icm-svg': (
				// <svg version="1.1" x="0px" y="0px" viewBox="0 0 101 72" enableBackground="new 0 0 101 72" >
				// 	<rect x="34" y="23" fill="#0078D4" width="7" height="34" />
				// 	<polyline fill="#0078D4"
				// 		points="96,36.75 96,57 101,57 101,23 96,23 85,50.25 74,23 69,23 69,57 74,57 74,36.75 82.75,57 87.25,57 96,36.75 " />
				// 	<polyline fill="#0078D4"
				// 		points="96,36.75 96,57 101,57 101,23 96,23 85,50.25 74,23 69,23 69,57 74,57 74,36.75 82.75,57 87.25,57 96,36.75 " />
				// 	<path fill="#0078D4"
				// 		d="M58.5,52c-4.142,0-7.5-3.358-7.5-7.5s3.358-7.5,7.5-7.5c2.176,0,4.13,0.933,5.5,2.413v-6.569c-1.67-0.79-3.53-1.244-5.5-1.244c-7.125,0-12.9,5.776-12.9,12.9s5.775,12.9,12.9,12.9c1.97,0,3.83-0.455,5.5-1.244v-6.569C62.63,51.067,60.676,52,58.5,52z" />
				// 	<path fill="#0078D4"
				// 		d="M58.5,52c-4.142,0-7.5-3.358-7.5-7.5s3.358-7.5,7.5-7.5c2.176,0,4.13,0.933,5.5,2.413v-6.569c-1.67-0.79-3.53-1.244-5.5-1.244c-7.125,0-12.9,5.776-12.9,12.9s5.775,12.9,12.9,12.9c1.97,0,3.83-0.455,5.5-1.244v-6.569C62.63,51.067,60.676,52,58.5,52z" />
				// 	<g>
				// 		<path fill="#0078D4"
				// 			d="M64.025,60l2.925,4.746L66.804,65H8.32l-0.195-0.34l29.39-57.249l11.863,24.086c1.904-1.34,4.113-2.264,6.502-2.663L41.576,0h-8.123L0.154,64.856L4.279,72h66.566l4.076-7.059L71.896,60H64.025z" />
				// 	</g>
				// </svg>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					version="1.1"
					x="0px"
					y="0px"
					viewBox="0 0 101 72"
					enableBackground="new 0 0 101 72"
				>
					<rect x="34" y="23" className="icm-svg-fill" width="7" height="34" />
					<polyline
						className="icm-svg-fill"
						points="96,36.75 96,57 101,57 101,23 96,23 85,50.25 74,23 69,23 69,57 74,57 74,36.75 82.75,57 87.25,57 96,36.75 "
					/>
					<polyline
						className="icm-svg-fill"
						points="96,36.75 96,57 101,57 101,23 96,23 85,50.25 74,23 69,23 69,57 74,57 74,36.75 82.75,57 87.25,57 96,36.75 "
					/>
					<path
						className="icm-svg-fill"
						d="M58.5,52c-4.142,0-7.5-3.358-7.5-7.5s3.358-7.5,7.5-7.5c2.176,0,4.13,0.933,5.5,2.413v-6.569c-1.67-0.79-3.53-1.244-5.5-1.244c-7.125,0-12.9,5.776-12.9,12.9s5.775,12.9,12.9,12.9c1.97,0,3.83-0.455,5.5-1.244v-6.569C62.63,51.067,60.676,52,58.5,52z"
					/>
					<path
						className="icm-svg-fill"
						d="M58.5,52c-4.142,0-7.5-3.358-7.5-7.5s3.358-7.5,7.5-7.5c2.176,0,4.13,0.933,5.5,2.413v-6.569c-1.67-0.79-3.53-1.244-5.5-1.244c-7.125,0-12.9,5.776-12.9,12.9s5.775,12.9,12.9,12.9c1.97,0,3.83-0.455,5.5-1.244v-6.569C62.63,51.067,60.676,52,58.5,52z"
					/>
					<g>
						<path
							className="icm-svg-fill"
							d="M64.025,60l2.925,4.746L66.804,65H8.32l-0.195-0.34l29.39-57.249l11.863,24.086c1.904-1.34,4.113-2.264,6.502-2.663L41.576,0h-8.123L0.154,64.856L4.279,72h66.566l4.076-7.059L71.896,60H64.025z"
						/>
					</g>
				</svg>
			),
			'capture-screenshot': (
				<svg /* fill={ fillColor }*/ viewBox="0 0 2048 2048" width="16" height="16">
					<path
						className="capture-screenshot-fill"
						d="M1664 640v896H384V640h293l128-128h438l128 128h293zm-128 768V768h-219l-128-128H859L731 768H512v640h1024zm-512-640q53
			0 99 20t82 55 55 81 20 100q0 53-20 99t-55 82-81 55-100 20q-53 0-99-20t-80-55-55-81-20-100q0-52 20-99t54-81 81-55 99-21zm0 384q27
			0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50q0 27 10 50t27 40 41 28 50 10zM128
			128v256H0V0h384v128H128zM1664 0h384v384h-128V128h-256V0zM128 1664v256h256v128H0v-384h128zm1792 256v-256h128v384h-384v-128h256z"
					/>
				</svg>
			),
			ThickArrowUp: <>&#x1F845;</>,
			ThickArrowLeft: <>&#x1F844;</>,
			ThickArrowRight: <>&#x1F846;</>,
			ThickArrowDown: <>&#x1F847;</>,
		},
	});

type CustomIconProps = Exclude<IIconProps, 'iconName'> & { styles?: Partial<IIconStyles> };

export const icmIconStyles = ({ theme, styles }: IIconStyleProps): IIconStyles => {
	return mergeStyleSets({
		root: {
			fontSize: 22,
			height: 22,
			width: 22,

			// hacky thing for icm icon
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			selectors: {
				'.icm-svg-fill': {
					fill: theme?.semanticColors.inputIcon,
					// '&:hover': {
					// 	fill: theme?.semanticColors.inputIconHovered,
					// }
				},
			},
		},
		styles,
	});
};

export const IcmIcon = (props: CustomIconProps): JSX.Element => (
	<Icon {...props} iconName="icm-svg" styles={icmIconStyles} />
);

export const captureScreenshotStyles = ({ theme, styles }: IIconStyleProps): IIconStyles => {
	return mergeStyleSets(
		{
			root: {
				selectors: {
					'.capture-screenshot-fill': {
						fill: theme?.semanticColors.inputIcon,
						'&:hover': {
							fill: theme?.semanticColors.inputIconHovered,
						},
					},
				},
			},
		},
		styles
	);
};

export const ScreenshotIcon = (props: CustomIconProps): JSX.Element => (
	<Icon {...props} iconName="capture-screenshot" styles={captureScreenshotStyles} />
);

export const themePrimaryIconStyle = (additionalStyles?: Partial<IIconStyles>) => ({
	theme,
	styles,
}: IIconStyleProps): IIconStyles =>
	mergeStyleSets({ root: { color: theme?.palette.themePrimary } }, styles, additionalStyles);
