import { Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import LinkedItemApi from 'API/LinkedItemApi';
import {
	FailCallback,
	IIcmItemsCallback,
	ILinkedItemsDispatch,
	IWorkSummaryCallback,
	LinkedItemUpdate,
	LinkedItemUpdatesArray,
	LinkedItemsAction,
	LinkedItemsState,
} from 'Context/LinkedItemsContext/LinkedItemsContext.types';
import {
	IcmItem,
	IcmScenarioMap,
	ModifiedIcmItem,
	ModifiedWorkSummary,
	ScenarioMap,
	WorkSummary,
} from 'DataModels/LinkedItems.types';
import { differnceInDHM, getDuration } from 'Utils/Util';
import VF from 'Utils/ValueFormatting';
import React, { createContext, useCallback, useReducer, useState } from 'react';
import { HtmlSanitizer } from 'roosterjs-react';

const initialState: LinkedItemsState = {
	icmItems: [],
	workSummaries: [],
	linkedItemsStatus: 'empty',
	updateStatus: 'success',
	itemSearchStatus: 'empty',
	addedIcmUpdates: null,
	removedIcmUpdate: null,
};

const LinkedItemsContext = createContext<LinkedItemsState | undefined>(undefined);

const LinkedItemsDispatch = createContext<ILinkedItemsDispatch | undefined>(undefined);

let icmUpdateInProgress = false;

function LinkedItemsProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
	const [error, setError] = useState<string | null>(null);
	const linkedItemsReducer = useCallback(
		(prevState: LinkedItemsState, action: LinkedItemsAction): LinkedItemsState => {
			switch (action.type) {
				//All Items
				case 'load annotations': {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					loadAllLinkedItems(action.dispatch, action.executiveFilter);
					return { ...initialState, linkedItemsStatus: 'fetching' };
				}
				case 'api errors': {
					const apiError:string=action.error;
					setError(apiError);
					return { ...initialState, linkedItemsStatus: 'error' };
				}
				case 'fetched all annotations': {
					return {
						...prevState,
						linkedItemsStatus: 'success',
						icmItems: action.icmItems,
						workSummaries: action.workSummary,
					};
				}

				// Icm Items
				case 'search for Icm item': {
					//Checking to see if an item attached to any particular scenario is the responsibility of the caller
					//  and should happen before this action is dispatched

					//Checking to see if an item exists in the api data already is the responsibility of this context.
					//	Already existing items will be returned instead of a full api call
					getIcmItem(
						prevState.icmItems,
						action.icmItemId,
						action.searchKey,
						action.dispatch,
						action.dispatchCallback
					);
					return {
						...prevState,
						itemSearchStatus: 'fetching',
					};
				}
				case 'fetched Icm item': {
					if (action.dispatchCallback)
						action.dispatchCallback([{ item: action.icmItem, scenarioMaps: [] }]);
					return {
						...prevState,
						itemSearchStatus: 'success',
						icmItems: action.foundInExistingData
							? prevState.icmItems
							: prevState.icmItems.concat(action.icmItem),
					};
				}
				case 'link Icm items': {
					if (icmUpdateInProgress) return prevState;
					icmUpdateInProgress = true;
					const wrappedFailCallback = () => {
						icmUpdateInProgress = false;
						if (action.failCallback) action.failCallback();
					};
					linkIcmItems(
						action.linkItemUpdates,
						action.fromPage,
						action.dispatch,
						action.dispatchCallback,
						wrappedFailCallback
					);
					return {
						...prevState,
						updateStatus: 'fetching',
					};
				}
				case '_internal finalize link Icm items': {
					icmUpdateInProgress = false;
					if (action.dispatchCallback) action.dispatchCallback(action.linkItemUpdates);
					return {
						...prevState,
						updateStatus: 'success',
						icmItems: applyLinkUpdates<ModifiedIcmItem>(
							prevState.icmItems,
							action.linkItemUpdates
						),
						addedIcmUpdates: action.linkItemUpdates,
					};
				}
				case 'unlink Icm item': {
					unLinkIcmItems(
						action.unlinkItemUpdate,
						action.fromPage,
						action.dispatch,
						action.dispatchCallback
					);

					return {
						...prevState,
						updateStatus: 'fetching',
					};
				}
				case '_internal finalize unlink Icm item': {
					if (action.dispatchCallback) action.dispatchCallback([action.unlinkItemUpdate]);
					return {
						...prevState,
						updateStatus: 'success',
						icmItems: applyUnLinkUpdate<ModifiedIcmItem>(
							prevState.icmItems,
							action.unlinkItemUpdate
						),
						removedIcmUpdate: action.unlinkItemUpdate,
					};
				}
				case 'clear Icm updates': {
					if (action.updateType === 'link')
						return { ...prevState, addedIcmUpdates: null };
					return { ...prevState, removedIcmUpdate: null };
				}

				// Work Summary
				case 'update work summary': {
					sendUpdatedWorkSummary(
						action.updatedWorkSummary,
						action.dispatch,
						action.dispatchCallback
					);
					return prevState;
				}
				case '_internal finalize update work summary': {
					const tempWorkSummaries = prevState.workSummaries.filter(
						(item) =>
							!(
								item.ScenarioId === action.updatedWorkSummary.ScenarioId &&
								item.CustomerId === action.updatedWorkSummary.CustomerId &&
								item.convertedDate === action.updatedWorkSummary.convertedDate
							)
					);
					tempWorkSummaries.push(action.updatedWorkSummary);
					if (action.dispatchCallback) action.dispatchCallback(action.updatedWorkSummary);
					return { ...prevState, workSummaries: tempWorkSummaries };
				}

				default:
					throw new Error(`Unhandled action: ${ (action as LinkedItemsAction).type }`);
			}
		},
		[]
	);

	const [state, dispatch] = useReducer(linkedItemsReducer, initialState);

	return (

		<LinkedItemsContext.Provider value={ state }>
			{error &&
			<Dialog
			hidden={error === null}
			dialogContentProps={{
			  type: DialogType.normal,
			  title: 'API Data Retrieval Error : LinkedItemsContext',
			  subText: error,
			}}
			onDismiss={() => setError(null)}
		  >
			<DialogFooter>
			  <PrimaryButton onClick={() =>setError(null)} text="Close" />
			</DialogFooter>
		  </Dialog>}
			<LinkedItemsDispatch.Provider value={ dispatch }>{ children }</LinkedItemsDispatch.Provider>
		</LinkedItemsContext.Provider>
	);
}

function applyLinkUpdates<T extends ModifiedIcmItem>(
	items: T[],
	itemUpdates: LinkedItemUpdatesArray<T>
) {
	for (const { item, scenarioMaps } of itemUpdates) {
		const set = new Set<IcmScenarioMap>(scenarioMaps);

		// Remove from Suggested if exists
		if ((item as ModifiedIcmItem).SuggestedScenarios) {
			const casted = item as ModifiedIcmItem;
			casted.IsLinked = true;
			//No need to sort this here
			casted.SuggestedScenarios = casted.SuggestedScenarios.filter(
				(scenario) => !set.has(scenario)
			);
			casted.LinkedScenarios = [
				...casted.LinkedScenarios,
				...(scenarioMaps as IcmScenarioMap[]),
			].sort(sortScenarioMaps);
		}
	}
	return [...items];
}

function applyUnLinkUpdate<T extends ModifiedIcmItem>(
	items: T[],
	{ scenarioMaps, item }: LinkedItemUpdate<T>
) {
	const set = new Set<IcmScenarioMap>(scenarioMaps);

	if ((item as ModifiedIcmItem).SuggestedScenarios) {
		const casted = item as ModifiedIcmItem;
		casted.IsLinked = casted.LinkedScenarios.length > 0;
		//No need to sort this here
		item.LinkedScenarios = (item.LinkedScenarios).filter(
			(scenario) => !set.has(scenario)
		);
	}


	// Drop from mapped scenarios entirely (cannot determine if they should go back into suggested)

	return [...items];
}

function loadAllLinkedItems(dispatch: ILinkedItemsDispatch, executiveFilter: string) {
	//Query params for last 6 months
	const end = new Date();
	end.setHours(0);
	end.setMinutes(0);
	end.setSeconds(0);
	end.setMilliseconds(0);
	end.setDate(1);
	end.setMonth(end.getMonth() + 1);

	const start = new Date(end);
	start.setMonth(start.getMonth() - 6);

	void LinkedItemApi.getAnnotations(
		start.toISOString(),
		end.toISOString(),
		executiveFilter,
		(res) => {
			dispatch({
				type: 'fetched all annotations',
				icmItems: transformIcmItems(res.Icm),
				workSummary: transformWorkSummaries(res.WorkSummary),
			});
		},
		(err: string) => {
			const apiError: string = err;
			dispatch({
				type: 'api errors',
				error: apiError,
			})
		}
	);
}

function sortScenarioMaps<T extends ScenarioMap>(a: T, b: T) {
	return a.ScenarioId * 100 + a.CustomerId - (b.ScenarioId * 100 + b.CustomerId);
}

function transformIcmItems(icmItems: IcmItem[]): ModifiedIcmItem[] {
	const currentDate = new Date();

	for (const item of icmItems as ModifiedIcmItem[]) {
		const impactStartDate = new Date(item.ImpactStartDate ?? item.CreatedOn);

		item.fullImpactStartDate = impactStartDate;
		item.impactDuration = differnceInDHM(
			impactStartDate,
			item.MitigateDate ? new Date(item.MitigateDate) : currentDate
		);
		item.detectDuration = differnceInDHM(
			impactStartDate,
			item.DetectDate ? new Date(item.DetectDate) : currentDate
		);
		item.displayImpactStartDate = VF.prettyDate(impactStartDate);

		item.fullDetectDate = item.DetectDate ? new Date(item.DetectDate) : null;
		item.displayDetectDate = item.fullDetectDate ? VF.prettyDate(item.fullDetectDate) : '';

		item.fullMitigateDate = item.MitigateDate ? new Date(item.MitigateDate) : null;
		item.displayMitigateDate = item.fullMitigateDate
			? VF.prettyDate(item.fullMitigateDate)
			: '';

		item.fullCreateDate = item.CreatedOn ? new Date(item.CreatedOn) : null;
		item.displayCreateDate = item.fullCreateDate ? VF.prettyDate(item.fullCreateDate) : '';

		item.displayTTD = getDuration(item.fullDetectDate, item.fullImpactStartDate);
		item.displayTTM = getDuration(item.fullMitigateDate, item.fullImpactStartDate);

		if (!item.LinkedScenarios) item.LinkedScenarios = [];
		if (!item.SuggestedScenarios) item.SuggestedScenarios = [];

		item.LinkedScenarios = item.LinkedScenarios.sort(sortScenarioMaps);
		item.SuggestedScenarios = item.SuggestedScenarios.sort(sortScenarioMaps);

		if (!item.ServiceName) item.ServiceName = 'Unknown';
	}

	return icmItems as ModifiedIcmItem[];
}

//ICM ITEMS
function getIcmItem(
	icmItems: ModifiedIcmItem[],
	incidentId: number,
	searchKey: string,
	dispatch: ILinkedItemsDispatch,
	dispatchCallback?: IIcmItemsCallback
) {
	const alreadyExists = icmItems.find((i) => i.Id === incidentId);
	if (alreadyExists) {
		dispatch({
			type: 'fetched Icm item',
			itemSearchStatus: 'success',
			searchKey: searchKey,
			foundInExistingData: true,
			icmItem: alreadyExists,
			dispatchCallback: dispatchCallback,
		});
	} else {
		void LinkedItemApi.getIcmItem(
			incidentId,
			(fetchedItems: IcmItem) => {
				dispatch({
					type: 'fetched Icm item',
					itemSearchStatus: 'success',
					searchKey: searchKey,
					foundInExistingData: false,

					icmItem: transformIcmItems([fetchedItems])[0],
					dispatchCallback: dispatchCallback,
				});
			},
			() => {
				if (dispatchCallback) dispatchCallback([]);
			}
		);
	}
}

function linkIcmItems(
	linkItemUpdates: LinkedItemUpdatesArray<ModifiedIcmItem>,
	fromPage: string,
	dispatch: ILinkedItemsDispatch,
	dispatchCallback?: IIcmItemsCallback,
	failCallback?: FailCallback
) {
	//gather all updates for all scenarios
	let allUpdates: IcmScenarioMap[] = [];
	for (const updatedIcm of linkItemUpdates) {
		allUpdates = allUpdates.concat(updatedIcm.scenarioMaps);
	}

	void LinkedItemApi.linkIcmItems(
		allUpdates,
		fromPage,
		() => {
			dispatch({
				type: '_internal finalize link Icm items',
				linkItemUpdates,
				dispatchCallback,
			});
		},
		failCallback
	);
}

function unLinkIcmItems(
	unlinkItemUpdate: LinkedItemUpdate<ModifiedIcmItem>,
	fromPage: string,

	dispatch: ILinkedItemsDispatch,
	dispatchCallback?: IIcmItemsCallback
) {
	void LinkedItemApi.unLinkIcmItems(unlinkItemUpdate.scenarioMaps, fromPage, () => {
		dispatch({
			type: '_internal finalize unlink Icm item',
			unlinkItemUpdate,
			dispatchCallback,
		});
	});
}

// Work Summaries
function transformWorkSummaries(workSummaries: WorkSummary[]): ModifiedWorkSummary[] {
	for (const summary of workSummaries as ModifiedWorkSummary[]) {
		summary.convertedDate = summary.StartDate.slice(0, 10).replace(/-/g, '');
		summary.convertedStartDate = summary.StartDate.slice(0, 10).replace(/-/g, '');

		// Add a day to converted end date to be consistent with global filters end date
		// it is non-inclusive
		const tempEnd = new Date(summary.EndDate);
		tempEnd.setDate(tempEnd.getDate() + 1);
		summary.convertedEndDate = VF.yyyymmddDate(tempEnd);

		summary.SummaryText = getSanitizeSummaryText(summary.SummaryText);
	}
	return workSummaries as ModifiedWorkSummary[];
}

function getSanitizeSummaryText(summaryText: string): string {
	let tempText = '';
	if (summaryText) {
		try {
			// try to read encoded string
			tempText = decodeURIComponent(summaryText);
		} catch (error) {
			//String can't be decoded.  Assumed to be a regular plain text string.
		}
	}
	return HtmlSanitizer.sanitizeHtml(tempText);
}

function sendUpdatedWorkSummary(
	updatedWorkSummary: ModifiedWorkSummary,
	dispatch: ILinkedItemsDispatch,
	dispatchCallback?: IWorkSummaryCallback
) {
	void LinkedItemApi.setWorkSummary(updatedWorkSummary, () => {
		updatedWorkSummary.SummaryText = getSanitizeSummaryText(updatedWorkSummary.SummaryText);
		dispatch({
			type: '_internal finalize update work summary',
			updatedWorkSummary: updatedWorkSummary,
			dispatchCallback: dispatchCallback,
		});
	});
}

// Custom hooks

function useLinkedItemsContext(): LinkedItemsState {
	const context = React.useContext(LinkedItemsContext);
	if (context === undefined) {
		throw new Error('useDataProviderState must be used within a LinkedItemsProvider ');
	}
	return context;
}

function useLinkedItemsDispatch(): ILinkedItemsDispatch {
	const context = React.useContext(LinkedItemsDispatch);
	if (context === undefined) {
		throw new Error('useLinkedItemsDispatch must be used within a LinkedItemsProvider ');
	}

	return context;
}

function useLinkedItemsProvider() {
	return {
		linkedItems: useLinkedItemsContext(),
		linkedItemsDispatch: useLinkedItemsDispatch(),
	};
}

LinkedItemsProvider.useLinkedItemsProvider = useLinkedItemsProvider;

export { LinkedItemsProvider, useLinkedItemsContext, useLinkedItemsDispatch };

