import { classNamesFunction, FontSizes } from '@fluentui/react';
import { INavigationStyleProps, INavigationStyles } from 'Components/Navigation/Navigation.types';

export const navigationStyles = ({ theme }: INavigationStyleProps): INavigationStyles => {
	return {
		root: {
			backgroundColor: theme.palette.neutralQuaternaryAlt,
			color: theme.semanticColors.bodyText,
			borderRight: `1px ${theme.palette.neutralTertiaryAlt} solid`,
		},
		subComponentStyles: {
			homeButton: {
				root: {
					color: theme.semanticColors.bodyText,
					minHeight: '48px',
					backgroundColor: 'inherit',
					flexGrow: 1,
					padding: '8px', //keeps icon consistently placed horizontally
				},
				icon: {
					fontSize: FontSizes.xLargePlus,
					color: theme.semanticColors.bodyText,
				},
				textContainer: {
					justifyContent: 'start',
				},
			},
			toggleButton: ({ isExpanded }: { isExpanded: boolean }) => ({
				root: {
					minHeight: '48px',
					minWidth: !isExpanded ? '100%' : '48px',
					padding: '4px',
					fontSize: FontSizes.xLargePlus,
				},
				icon: {
					fontSize: FontSizes.large,
					color: theme.semanticColors.bodyText,
				},
			}),
			navigation: {
				link: {
					padding: '0px 6px',
					backgroundColor: 'inherit',
					color: 'white',
				},
				linkText: {
					color: theme.semanticColors.bodyText,
					marginLeft: 16,
				},
			},
			separator: {
				root: {
					height: 4,
					margin: '0px 4px',
					padding: '0px 4px',
					selectors: {
						':before': {
							backgroundColor: theme.palette.neutralTertiaryAlt,
						},
					},
				},
			},
		},
	};
};

export const getNavigationClassNames = classNamesFunction<
	INavigationStyleProps,
	INavigationStyles
>();
