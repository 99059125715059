/* eslint-disable @typescript-eslint/unbound-method */
import { FailureCallback, SometimesAuthHeaders, SuccessCallback } from 'API/Api.types';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
	IcmItem,
	IcmScenarioMap,
	ModifiedWorkSummary,
	WorkSummary,
} from 'DataModels/LinkedItems.types';
import { getAccount, msalScenarioHealthApiFetch } from 'Utils/MsalConfig';
import { TelemetryService } from 'Utils/TelemetryService';

type AllLinkedItemsRes = {
	Icm: IcmItem[];
	WorkSummary: WorkSummary[];
};
export default class LinkedItemApi {
	static getAnnotations(
		startDate: string,
		endDate: string,
		executiveFilter: string,
		success: SuccessCallback<AllLinkedItemsRes>,
		fail?: FailureCallback<string>
	): Promise<AllLinkedItemsRes> {
		return new Promise(() => {
			const start = new Date().getTime();
			const url = `v1.0/annotations?PeriodFrom=${ startDate }&PeriodTo=${ endDate }&executiveFilter=${ executiveFilter }`;
			const options = {
				timeout: 40000
			};
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: options });
			msalScenarioHealthApiFetch.get(url, { ...options })
				.then(({ data }: AxiosResponse<AllLinkedItemsRes>) => {
					const end = new Date().getTime();
					const duration = end - start; //ms
					TelemetryService.trackEvent('API v1.0/annotations End', { url: url, duration: duration });
					if (duration > 30000)
						// will be longer than the time reported in devtools
						TelemetryService.trackException('Get Annotations', {
							error: { message: 'timeout of 30000ms exceeded' },
						});

					const { Icm, WorkSummary } = data;
					if (!Icm.length || !WorkSummary.length) {
						TelemetryService.trackException('Missing Linked Item Data', {
							missing: Object.entries(data).reduce((str: string, [s, arr]) => {
								if (!arr.length) return `${ str }${ str.length ? ', ' : '' }${ s }`;
								return str;
							}, ''),
						});
					}

					success(data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get Annotations', { error: err });
					if (fail) fail('Error retrieving date from api annotations.  Error: '+ err.message);
				});
		});
	}

	///////ICM ITEMS
	static linkIcmItems(
		icmItems: IcmScenarioMap[],
		page: string,
		success: SuccessCallback<boolean>,
		fail?: FailureCallback<unknown>
	): Promise<boolean> {
		return new Promise(() => {
			const url = 'v1.0/annotations/addicmlinks';
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.post(url, LinkedItemApi.getIcmSaveParams(icmItems).data, {})
				.then((res: AxiosResponse<boolean>) => {
					TelemetryService.trackEvent(`API ${ url } End`, { items: icmItems, page: page, url: url, duration: Date.now() - apiStartTime });
					success(res.data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Link Icm Items', {
						error: err,
						items: icmItems,
						page: page,
					});
					if (fail) fail(err);
				});
		});
	}

	static unLinkIcmItems(
		icmItems: IcmScenarioMap[],
		page: string,
		success: SuccessCallback<boolean>,
		fail?: FailureCallback<unknown>
	): Promise<boolean> {
		return new Promise(() => {
			const url = 'v1.0/annotations/deleteicmlinks/';
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.post(url, LinkedItemApi.getIcmSaveParams(icmItems).data, {})
				.then((res: AxiosResponse<boolean>) => {
					TelemetryService.trackEvent(`API ${ url } End`, { items: icmItems, page: page, url: url, duration: Date.now() - apiStartTime });
					success(res.data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Unlink Icm Items', {
						error: err,
						items: icmItems,
						page: page,
					});
					if (fail) fail(err);
				});
		});
	}

	static getIcmItem(
		incidentId: number,
		success: SuccessCallback<IcmItem>,
		fail?: FailureCallback<unknown>
	): Promise<IcmItem> {
		return new Promise(() => {
			const url = `v1.0/annotations/icm/${ incidentId }`;
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.get(url, {})
				.then(({ data }: AxiosResponse<IcmItem>) => {
					TelemetryService.trackEvent(`API ${ url } End`, { incident: incidentId, url: url, duration: Date.now() - apiStartTime });
					success(data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					// console.error(err.message);
					TelemetryService.trackException('Get Icm Item', {
						error: err,
						incident: incidentId,
					});
					if (fail) fail(err);
				});
		});
	}

	static getIcmSaveParams(icmItems: IcmScenarioMap[]): AxiosRequestConfig {
		const account = getAccount();
		// update username
		for (const item of icmItems) {
			item.UserAlias = account.name ?? '';
		}

		const queryParams: AxiosRequestConfig = {
			method: 'post',
			data: icmItems,
		};
		return queryParams;
	}

	static setWorkSummary(
		updatedWorkSummary: ModifiedWorkSummary,
		success: SuccessCallback<boolean>,
		fail?: FailureCallback<unknown>
	): Promise<boolean> {
		return new Promise(() => {
			const account = getAccount();
			const reqParam: AxiosRequestConfig = {
				method: 'post',
				data: {
					...updatedWorkSummary,
					UserAlias: account.name,
				},
			};
			const url = 'v1.0/annotations/worksummary';
			const apiStartTime = Date.now();

			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.post(url, reqParam.data, {})
				.then(({ data }: AxiosResponse<boolean>) => {
					TelemetryService.trackEvent(`API ${ url } End`, { summary: updatedWorkSummary, url: url, duration: Date.now() - apiStartTime });
					success(data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Set Work Summary', {
						error: err,
						summary: updatedWorkSummary,
					});
					if (fail) fail(err);
				});
		});
	}
}
