import {
	CommandBarButton,
	IconButton,
	INavLink,
	IRenderFunction,
	IRenderGroupHeaderProps,
	Nav,
	Persona,
	Separator,
	Stack,
	Text,
} from '@fluentui/react';
import {
	HealthContainer,
	HealthLayout,
	LineChart,
	ResponsiveContainer,
} from 'Components/LazyLoad/_Lazy';
import { getNavigationClassNames } from 'Components/Navigation/Navigation.styles';
import { INavigationProps } from 'Components/Navigation/Navigation.types';
import { useThemeContext } from 'Context/ThemeContext/ThemeContext';
import { semiBoldFontWeightStyle } from 'CSS/SharedStyles';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Navigation({
	styles,
	className,
	open,
	onToggleOpen,
	defaultOpen = false,
	navLinkGroups,
}: INavigationProps): JSX.Element {
	/**
	 * Getting theme from content.
	 * So whenever the theme content is updated, we have the theme updated here as well.
	 */
	const theme = useThemeContext();
	const { subComponentStyles, root } = getNavigationClassNames(styles, {
		theme: theme,
		className,
	});
	const { homeButton, separator, navigation, toggleButton } = subComponentStyles;

	/**for uncontrolled state */
	const [collapse, setCollapse] = useState(defaultOpen);

	/**determine if we should be using controlled(props) or uncontrolled state*/
	const isExpanded = open ?? collapse;

	const navigate = useNavigate();
	const location = useLocation();

	// It's the button for the user to open/close the navigation.
	const navToggle = useCallback(() => {
		return (
			<IconButton
				styles={toggleButton({ isExpanded })}
				ariaLabel={(isExpanded ? 'Close' : 'Open') + ' the accordion of navigation'}
				iconProps={{ iconName: isExpanded ? 'DoubleChevronLeft' : 'DoubleChevronRight' }}
				onClick={() => {
					if (open === undefined) setCollapse(!collapse);
					if (onToggleOpen) onToggleOpen();
				}}
			/>
		);
	}, [collapse, isExpanded, onToggleOpen, open, toggleButton]);

	const groupHeaderTokens = useMemo(() => {
		if (!isExpanded) return { padding: 4 };
		return {
			padding: '4px 4px 4px 16px',
		};
	}, [isExpanded]);

	const renderGroupHeader = useCallback(
		(
			groupHeader?: IRenderGroupHeaderProps /*, defaultRender?: IRenderFunction<IRenderGroupHeaderProps>*/
		) => {
			if (!groupHeader) return null;
			return (
				<>
					<Stack
						grow
						horizontal
						horizontalAlign={isExpanded ? 'start' : 'center'}
						tokens={groupHeaderTokens}
					>
						{isExpanded ? (
							<Text styles={semiBoldFontWeightStyle}>{groupHeader.name}</Text>
						) : (
							// : groupHeader.groupData && groupHeader.groupData.iconProps && <Icon { ...groupHeader.groupData.iconProps } />
							groupHeader.groupData &&
							groupHeader.groupData.initials && (
								<Text styles={semiBoldFontWeightStyle}>
									{groupHeader.groupData.initials}
								</Text>
							)
						)}
					</Stack>
					<Separator styles={separator} />
				</>
			);
		},
		[groupHeaderTokens, isExpanded, separator]
	);

	return (
		<Stack className={root} verticalFill>
			<Stack grow>
				<Stack horizontal={isExpanded} verticalAlign="center">
					<CommandBarButton
						styles={homeButton({})}
						iconProps={{ iconName: 'Home' }}
						// Using undefined here when the nav is not expanded
						// so that it won't mess up the styles of the button.
						text={isExpanded ? 'Home' : undefined}
						ariaLabel="home"
						onClick={() => {
							void HealthContainer.preload();
							void HealthLayout.preload();
							void LineChart.preload();
							void ResponsiveContainer.preload();
							navigate('/' + location.search);
						}}
					/>
					{navToggle()}
				</Stack>
				<Separator styles={separator}></Separator>
				<Nav
					groups={navLinkGroups}
					onRenderLink={(item?: INavLink, defaultRender?: IRenderFunction<INavLink>) => {
						return (
							<>
								{item?.showPersona && (
									<Persona
										imageInitials={item?.initial}
										coinSize={32}
										hidePersonaDetails={!isExpanded}
										text={item?.name}
									/>
								)}
								{item?.iconProps &&
									isExpanded &&
									defaultRender &&
									defaultRender(item)}
							</>
						);
					}}
					onRenderGroupHeader={renderGroupHeader}
					styles={navigation}
				/>
			</Stack>
			<Separator styles={separator}></Separator>
			<Stack horizontalAlign={isExpanded ? 'end' : 'start'}>{navToggle()}</Stack>
		</Stack>
	);
}
