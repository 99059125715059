import { classNamesFunction } from '@fluentui/react';
import { IPageFooterStyleProps, IPageFooterStyles } from "Components/Page/Footer.types";

export const pageFooterStyles = (props: IPageFooterStyleProps): IPageFooterStyles => {
	const { theme, hideIcon } = props;
	return {
		root: {
			background: theme.palette.neutralLight,
			borderTop: `1px ${ theme.palette.neutralTertiaryAlt } solid`,
			textAlign: 'center',
			minHeight: 50
		},
		subComponentStyles: {
			subtleText: { //TODO: This fails accessibility check.  Leaving for now, because we can't match Prod design with current theme color set
				root: { color: theme.palette.neutralTertiary, }
			},
			link: {
				root: { color: theme.palette.neutralSecondary }
			},
			scrollButton: {
				root: {
					color: theme.palette.neutralPrimary,
					visibility: hideIcon ? 'hidden' : '',
				},
				rootHovered: {
					color: theme.palette.neutralPrimary,
					backgroundColor: theme.palette.neutralQuaternary
				}
			},
		}
	}
}

export const getPageFooterClassNames =
	classNamesFunction<IPageFooterStyleProps, IPageFooterStyles>();
