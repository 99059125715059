import { IconButton, IStackTokens, Link, Stack, StackItem, Text } from '@fluentui/react';
import { getPageFooterClassNames } from 'Components/Page/Footer.styles';
import { IPageFooterProps } from 'Components/Page/Footer.types';
import { useThemeContext } from 'Context/ThemeContext/ThemeContext';
import React, { useCallback, useEffect, useState } from 'react';

const wrapperStackTokens: IStackTokens = { padding: 8 };

export default function PageFooter({ scrollRef, styles }: IPageFooterProps): JSX.Element {
	const [showScroll, setShowScroll] = useState(false);
	const [currentElement, setCurrentElement] = useState<HTMLElement>();

	const theme = useThemeContext();
	const { subComponentStyles, root } = getPageFooterClassNames(styles, {
		hideIcon: !showScroll,
		theme: theme,
	});
	const { link, scrollButton, subtleText } = subComponentStyles;

	/** Check to see if we should show the scroll button */
	const checkScrollTop = useCallback(
		(evt) => {
			const target = evt.target;
			if (!showScroll && target.scrollTop > 200) {
				setShowScroll(true);
			} else if (showScroll && target.scrollTop <= 200) {
				setShowScroll(false);
			}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			setCurrentElement(evt.target);
		},
		[showScroll]
	);

	/**  Initiate scroll */
	const scrollTop = useCallback(() => {
		if (currentElement) {
			currentElement.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [currentElement]);

	/** Add event listener */
	useEffect(() => {
		if (scrollRef && scrollRef.current) {
			scrollRef.current
				.getElementsByClassName('ms-ScrollablePane--contentContainer')[0]
				.addEventListener('scroll', checkScrollTop);
		}
	}, [scrollRef, checkScrollTop]);

	return (
		<Stack
			horizontalAlign="center"
			verticalAlign="center"
			horizontal
			tokens={wrapperStackTokens}
			className={root}
		>
			<StackItem grow={3}>
				<Text styles={subtleText}>{`Contact: `}</Text>
				<Link styles={link} href="mailto:shsupport@microsoft.com">
					Scenario Health
				</Link>
				<Text styles={subtleText}> | Internal Use Only | © Microsoft 2024 | </Text>
				<Link
					styles={link}
					href="http://go.microsoft.com/fwlink/?LinkId=518021"
					target="_blank"
				>
					Privacy Notice
				</Link>
			</StackItem>
			<StackItem>
				<IconButton
					iconProps={{
						iconName: 'DoubleChevronUp',
					}}
					onClick={scrollTop}
					styles={scrollButton()}
					title="Scroll to top"
				/>
			</StackItem>
		</Stack>
	);
}
