import { styled } from '@fluentui/react/lib/Utilities';
import Navigation from 'Components/Navigation/Navigation.base';
import { navigationStyles } from 'Components/Navigation/Navigation.styles';
import {
	INavigationProps,
	INavigationStyleProps,
	INavigationStyles
} from 'Components/Navigation/Navigation.types';

const StyledNavigation =
	styled<INavigationProps,
		INavigationStyleProps,
		INavigationStyles>(
			Navigation,
			navigationStyles
		);

export default StyledNavigation;
