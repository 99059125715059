import { getResponsiveMode, getTheme, ITheme, ResponsiveMode } from '@fluentui/react';
import { useWindow } from '@fluentui/react-window-provider';
import React, { createContext, useEffect, useState } from 'react';

//This is an annoying example theme for testing
// const testingTheme = createTheme({
// 	palette: {
// 		themePrimary: '#21de47',
// 		themeLighterAlt: '#f5fef7',
// 		themeLighter: '#d8fadf',
// 		themeLight: '#b7f5c3',
// 		themeTertiary: '#73eb8b',
// 		themeSecondary: '#39e25b',
// 		themeDarkAlt: '#1ec840',
// 		themeDark: '#19a936',
// 		themeDarker: '#137c28',
// 		neutralLighterAlt: '#575757',
// 		neutralLighter: '#5d5d5d',
// 		neutralLight: '#696969',
// 		neutralQuaternaryAlt: '#6f6f6f',
// 		neutralQuaternary: '#757575',
// 		neutralTertiaryAlt: '#8c8c8c',
// 		neutralTertiary: '#3c2c1b',
// 		neutralSecondary: '#795735',
// 		neutralPrimaryAlt: '#b1804e',
// 		neutralPrimary: '#c99159',
// 		neutralDark: '#d6a97b',
// 		black: '#e1be9a',
// 		white: '#4f4f4f',
// 	}
// });

//const initialTheme = loadTheme(testingTheme);
const initialTheme = getTheme();

const ThemeProviderContext = createContext<ITheme | undefined>(undefined);

/** Breakpoints are : 479, 639, 1023, 1365, 1919, 99999999 */
const ResponsiveModeContext = createContext<ResponsiveMode | undefined>(undefined);

function ThemeProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
	const windowPointer = useWindow();

	const [theme /*, setTheme*/] = useState(initialTheme);
	const [responsiveMode, setResponsiveMode] = useState<ResponsiveMode>(
		getResponsiveMode(windowPointer)
	);

	useEffect(() => {
		if (windowPointer) {
			windowPointer.addEventListener('resize', () =>
				setResponsiveMode(getResponsiveMode(windowPointer))
			);
		}
	}, [windowPointer]);

	return (
		<ResponsiveModeContext.Provider value={responsiveMode}>
			<ThemeProviderContext.Provider value={theme}>{children}</ThemeProviderContext.Provider>
		</ResponsiveModeContext.Provider>
	);
}

function useThemeContext(): ITheme {
	const context = React.useContext(ThemeProviderContext);
	if (context === undefined) {
		throw new Error('useDataProviderState must be used within a ThemeContext ');
	}
	return context;
}

function useTheme() {
	return {
		theme: useThemeContext(),
	};
}
function useResponsiveModeContext(): ResponsiveMode {
	const context = React.useContext(ResponsiveModeContext);
	if (context === undefined) {
		throw new Error('useDataProviderState must be used within a ThemeContext ');
	}
	return context;
}

function useResponsiveMode() {
	return {
		responsiveMode: useResponsiveModeContext(),
	};
}

ThemeProvider.useTheme = useTheme;
ThemeProvider.useResponsiveMode = useResponsiveMode;

export { ThemeProvider, useThemeContext, useResponsiveModeContext };
