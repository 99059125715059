
import { classNamesFunction, FontWeights, ResponsiveMode } from '@fluentui/react';
import { ILinkButtonPanelStyleProps, ILinkButtonPanelStyles } from "Components/Navigation/LinkButtonPanel.types";

export const linkButtonPanelStyles = ({ theme, responsiveMode }: ILinkButtonPanelStyleProps): ILinkButtonPanelStyles => {
	return {
		subComponentStyles: {
			waffleButton: {
				root: {
					height: 48,
					background: theme.palette.themeDark,
					borderColor: theme.palette.themeDark,
					padding: '8px 11px',
					minWidth: 'unset'
				},
				icon: {
					fontWeight: FontWeights.semibold,
				},
			},
			panelButton: () => {
				return {
					root: {
						width: responsiveMode && responsiveMode >= ResponsiveMode.medium
							? 'calc(50% - 8px)'
							: 320,
						margin: 4
					},
					rootHovered: {
						boxShadow: theme.effects.elevation16
					}
				}
			},
			panel: {
				content: {
					borderTop: `thin solid ${ theme.palette.neutralQuaternary }`,
					padding: "0px 0px 20px 0px",
					margin: "0px 20px"
				}
			}
		}
	}
}

export const getLinkButtonPanelClassNames = classNamesFunction<ILinkButtonPanelStyleProps, ILinkButtonPanelStyles>();
