import { Duration } from 'DataModels/Global.types';
import { DateTime } from 'luxon';
import { RefCallback } from 'react';

export const getRefSettingDelegate = <P>(ref: React.MutableRefObject<P>): RefCallback<P> => {
	return (element: P) => (ref.current = element);
};

export const localeCompare = (string1: string, string2: string): number =>
	string1.localeCompare(string2);

//Calulate dates differnce in days hours minutes
export function differnceInDHM(fromDate: Date, toDate: Date): Duration {
	let diffInMilliSeconds = Math.abs(fromDate.getTime() - toDate.getTime());
	const tempMiliSeconds = diffInMilliSeconds;

	// calculate days
	const days = Math.floor(diffInMilliSeconds / 8.64e7);
	diffInMilliSeconds -= days * 8.64e7;

	// calculate hours
	const hours = Math.floor(diffInMilliSeconds / 3600000) % 24;
	diffInMilliSeconds -= hours * 3600000;

	// calculate minutes
	const minutes = Math.floor(diffInMilliSeconds / 60000) % 60;
	diffInMilliSeconds -= minutes * 60000;

	return {
		days,
		hours,
		minutes,
		fullMilliSeconds: tempMiliSeconds,
	};
}

export const maxValue = (arr: number[]): number | null => {
	if (!arr || !arr.length) return null;

	let currMax = arr[0];
	for (let i = 1; i < arr.length; i++) {
		if (arr[i] > currMax) currMax = arr[i];
	}

	return currMax;
};

export const minValue = (arr: number[]): number | null => {
	if (!arr || !arr.length) return null;

	let currMin = arr[0];
	for (let i = 1; i < arr.length; i++) {
		if (arr[i] < currMin) currMin = arr[i];
	}

	return currMin;
};

export const avgValue = (arr: number[]): number | null => {
	if (!arr || !arr.length) return null;

	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}

	return sum / arr.length;
};

export const sumValue = (arr: number[]): number | null => {
	if (!arr || !arr.length) return null;

	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}

	return sum;
};

/**
 * Given a complex complexId string (eg: SomeObject.ASubObject.AValue),  return that lowest level value (AValue);
 * @param {string} complexId a period separated sting 'someText.someOtherText.theThingYouWant'
 * @param {object} item ITableItem
 * @returns {P} Value of type P or undefined
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComplexIdValue<T extends Record<string | number | symbol, any>, P>(
	complexId: string,
	item: T
): P | undefined {
	if (!item) return undefined;
	let value = item as never; //using never because we don't care about the type here but the generic T is useful outside of this
	const bracketsRegex = /\[(?<anyText>.*?)\]/g;
	const bracketKeys = complexId.match(bracketsRegex);

	const keysArray = complexId.split('.');
	for (const key of keysArray) {
		let tempKey = key;
		if (bracketKeys && bracketKeys.length && bracketKeys.indexOf(key) > -1) {
			// TO RARELY BE USED.  Only works with keys on the first level of the item.
			// if they id includes a bracket, find the key inside that bracket from the root item again
			// eg: '[someOtherKey].theGoods'
			// { someOtherKey: 'this is the key', 'this is the key': { theGoods: theValueWeWant} }
			tempKey = tempKey.slice(1, -1);
			const tempSubKeys = tempKey.split('.');
			for (const tempSubKey of tempSubKeys) {
				if (item[tempSubKey] === undefined) return undefined;
				tempKey = item[tempSubKey] as string;
			}
		}
		if (value[tempKey] === undefined) return undefined;
		value = value[tempKey];
	}

	return value as P;
}

/**
 * Given a string representing date.  Return a Date with local timezone
 * @param {string} dateString expects YYYYMMDD
 * @returns {Date} date
 */
export function yyyymmddToDate(dateString: string): Date {
	return new Date(
		`${ dateString.slice(4, 6) }/${ dateString.slice(6, 8) }/${ dateString.slice(0, 4) }`
	);
}

/** Javascript does not handle float subtraction well
 *  use this to prevent 100 - 99.9 = 0.09999999999999432
 *  Optional _mult_ override.  Javascript is weird, man.
 */
export function floatSubtract(from: number, subtract: number, mult = 10000000000): number {
	return (from * mult - subtract * mult) / mult;
}

/** Javascript does not handle float subtraction well
 *  use this to prevent 100 - 99.9 = 0.09999999999999432
 *  Creating this addition function to be consistent
 *  Optional _mult_ override.  Javascript is weird, man.
 */
export function floatAdd(from: number, add: number, mult = 10000000000): number {
	return (from * mult + add * mult) / mult;
}

export function getDuration(date1: Date | null, date2: Date | null) {
	if (date1 == null || date2 == null) {
		return 'TBD';
	}
	//if icm is not mitigated => MitigateDate == '0001-01-01T00:00:00'
	if (date1.getFullYear() == 1 && date1.getMonth() == 0 && date1.getDay() == 1) {
		return 'TBD';
	}
	if (date2.getFullYear() == 1 && date2.getMonth() == 0 && date2.getDay() == 1) {
		return 'TBD';
	}
	// Calculate the time difference in milliseconds
	const timeDifference = Math.abs(date1.getTime() - date2.getTime());

	// Calculate the number of days, hours, and minutes
	const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

	let daystr = days > 0 ? `${ days }d ` : '';
	let hourstr = (days > 0 || hours > 0) ? `${ hours }h ` : '';
	let minutestr = `${ minutes }m`;

	return `${ daystr }${ hourstr }${ minutestr }`;
}

export function getDurationMS(durationInMilliseconds: number) {
	if (durationInMilliseconds < 0) {
		return '-';
	}
	// Calculate days, hours, minutes, and seconds
	const secondsTotal = durationInMilliseconds / 1000;
	const days = Math.floor(secondsTotal / (60 * 60 * 24));
	const hours = Math.floor((secondsTotal % (60 * 60 * 24)) / (60 * 60));
	const minutes = Math.floor((secondsTotal % (60 * 60)) / 60);
	const seconds = Math.round(secondsTotal % 60 * 1000) / 1000; // Round to 3 decimal places

	// Build the duration string
	let durationString = '';
	if (days > 0) {
		durationString += `${ days }d `;
	}
	if (hours > 0 || days > 0) {
		durationString += `${ hours }h `;
	}
	if (minutes > 0 || hours > 0 || days > 0) {
		durationString += `${ minutes }m `;
	}
	if (seconds > 0 || minutes > 0 || hours > 0 || days > 0) {
		durationString += `${ seconds }s `;
	}

	return durationString.trim();
}

export function checkStringNullOrEmpty(str: string) {
	return (!str || str.length === 0);
}

/**
 * Get the time of the midnight on given Date in pacific timezone.
 *  e.g Suppose current local timezone is India Time, and given 2024-02-28 15:00:00 India time (which is 2024-02-28 02:30 pacific time).
 *  => return 2024-02-28 8AM (utc), which is 2024-02-28 12AM (pacific time).
 */
export function getPacificMidnightDateTime(inputdate?: Date) {
	let date = new Date();
	if (inputdate != null) {
		date = inputdate;
	}
	// convert to pacific time zone and set hours to 0
	const luxonDateTime = DateTime.fromJSDate(date).setZone('America/Los_Angeles').startOf('day');
	const jsDate = luxonDateTime.toJSDate();
	return jsDate;
}

/**
 * Get current time and convert to pacific time zone and then set the time to 12am.
 */
export function getMidNightTodayInPacificTime() {
	const date = new Date();
	return getPacificMidnightDateTime(date);
}

export function isValidHttpUrl(str: string) {
	let url;
	try {
		url = new URL(str);
	} catch (_) {
		return false;
	}
	return (url.protocol === "https:") && !(/[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]/.test(str));
}

