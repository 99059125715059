import { styled } from '@fluentui/react/lib/Utilities';
import FilterBarBase from 'Components/FilterBar/FilterBar.base';
import { filterBarStyles } from 'Components/FilterBar/FilterBar.styles';
import {
	IFilterBarProps,
	IFilterBarStyleProps,
	IFilterBarStyles,
} from 'Components/FilterBar/FilterBar.types';

const StyledFilterBar = styled<IFilterBarProps, IFilterBarStyleProps, IFilterBarStyles>(
	FilterBarBase,
	filterBarStyles
);

export default StyledFilterBar;
