import { ScrollablePane } from '@fluentui/react';
import { getPageContentClassNames, pageContentStyles } from 'Components/Page/Content.styles';
import { PageContentProps } from 'Components/Page/Content.types';
import { useThemeContext } from 'Context/ThemeContext/ThemeContext';
import React from 'react';
import { getRefSettingDelegate } from 'Utils/Util';

export function PageContent({
	className,
	role,
	scrollRef,
	viewsContentRef,
	children,
}: React.PropsWithChildren<PageContentProps>): JSX.Element {
	const theme = useThemeContext();
	const { root, subComponentStyles } = getPageContentClassNames(pageContentStyles, {
		theme: theme,
		className,
	});
	const { scrollablePane } = subComponentStyles;

	return (
		<div
			role={role}
			className={root}
			ref={scrollRef ? getRefSettingDelegate(scrollRef) : null}
			data-is-scrollable="true"
		>
			<ScrollablePane
				styles={() =>
					scrollablePane({
						scrollablePaneOffsetHeight: scrollRef?.current?.offsetHeight,
						scrollablePaneContentOffsetHeight: viewsContentRef?.current?.offsetHeight,
						theme,
					})
				}
			>
				{children}
			</ScrollablePane>
		</div>
	);
}

export default PageContent;
