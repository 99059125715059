import { ComponentType } from 'react';
import lazy from 'react-lazy-with-preload';

type LazyLoadFunction<T> = () => Promise<DynamicImport<T>>;

type DynamicImport<T> = { default: ComponentType<T> };

function retry<T>(fn: LazyLoadFunction<T>, retriesLeft = 5, interval = 500) {
	return new Promise<DynamicImport<T>>((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error: any) => {
				setTimeout((): any => {
					if (retriesLeft > 0)
						retry<T>(fn, retriesLeft - 1, interval).then(resolve, reject);
					else reject(error);
				}, interval);
			});
	});
}

//////////////////////////////////
//
//		Lazy Loading Components
//
//////////////////////////////////

// Fluent UI
export const Dialog = lazy(() => retry(() => import('Components/LazyLoad/Dialog')));
export const DialogFooter = lazy(() => retry(() => import('Components/LazyLoad/DialogFooter')));
export const DetailsList = lazy(() => retry(() => import('Components/LazyLoad/DetailsList')));
export const DetailsRow = lazy(() => retry(() => import('Components/LazyLoad/DetailsRow')));
export const Dropdown = lazy(() => retry(() => import('Components/LazyLoad/Dropdown')));
export const GroupHeader = lazy(() => retry(() => import('Components/LazyLoad/GroupHeader')));
export const Icon = lazy(() => retry(() => import('Components/LazyLoad/Icon')));
export const IconButton = lazy(() => retry(() => import('Components/LazyLoad/IconButton')));
export const Pivot = lazy(() => retry(() => import('Components/LazyLoad/Pivot')));
export const PrimaryButton = lazy(() => retry(() => import('Components/LazyLoad/PrimaryButton')));
export const SearchBox = lazy(() => retry(() => import('Components/LazyLoad/SearchBox')));
export const Sticky = lazy(() => retry(() => import('Components/LazyLoad/Sticky')));
// Recharts

export const ResponsiveContainer = lazy(() =>
	retry(() => import('Components/LazyLoad/ResponsiveContainer'))
);
export const BarChart = lazy(() => retry(() => import('Components/LazyLoad/BarChart')));
export const LineChart = lazy(() => retry(() => import('Components/LazyLoad/LineChart')));
export const PieChart = lazy(() => retry(() => import('Components/LazyLoad/PieChart')));

// Scenario Health
export const AvailabilityDetail = lazy(() => import('Components/AvailabilityDetail/Detail'));
export const DateRangePicker = lazy(() =>
	retry(() => import('Components/DateRangePicker/DateRangePicker'))
);
export const TileCardStrip = lazy(() => retry(() => import('Components/TileCard/TileCardStrip')));
export const AccordionCommandBar = lazy(
	() => import('Components/AccordionCommandBar/AccordionCommandBar')
);

// Views
export const DashboardLayout = lazy(() => retry(() => import('Views/Dashboard/Dashboard.Layout')));
export const DashboardContainer = lazy(() =>
	retry(() => import('Views/Dashboard/Dashboard.Container'))
);

export const GapsLayout = lazy(() => retry(() => import('Views/Gaps/Gaps.Layout')));
export const GapsContainer = lazy(() => retry(() => import('Views/Gaps/Gaps.Container')));

export const ScenarioDetailsLayout = lazy(() =>
	retry(() => import('Views/ScenarioDetails/ScenarioDetails.Layout'))
);
export const ScenarioDetailsContainer = lazy(() =>
	retry(() => import('Views/ScenarioDetails/ScenarioDetails.Container'))
);

export const HealthLayout = lazy(() => retry(() => import('Views/Health/Health.Layout')));
export const HealthContainer = lazy(() => retry(() => import('Views/Health/Health.Container')));

export const ManagementLayout = lazy(() =>
	retry(() => import('Views/Management/Management.Layout'))
);
export const ManagementContainer = lazy(() =>
	retry(() => import('Views/Management/Management.Container'))
);
export const PendingChangesLayout = lazy(() =>
	retry(() => import('Views/Management/PendingChanges.Layout'))
);
export const PendingChangesContainer = lazy(
	() => import('Views/Management/PendingChanges.Container')
);

export const MetricsLayout = lazy(() => retry(() => import('Views/Metrics/Metrics.Layout')));
export const MetricsContainer = lazy(() => retry(() => import('Views/Metrics/Metrics.Container')));

export const TestLayout = lazy(() => retry(() => import('Views/Test/Test.Layout')));
export const ColorTestLayout = lazy(() => retry(() => import('Views/Test/ColorTest.Layout')));
