/* eslint-disable @typescript-eslint/unbound-method */
import { FailureCallback, SometimesAuthHeaders, SuccessCallback } from 'API/Api.types';
import { AvailabilityOptions } from 'API/ScenarioHealthApi.types';
import { AxiosError, AxiosResponse } from 'axios';
import {
	CoreApiDataMap,
	GraduatedScenario,
	ISelfServeScenarios,
	MetaData,
	PeopleMetadata,
	ScenarioGroupTypeData,
	UserMetadata
} from 'DataModels/CoreDataModels.types';
import { getAccount, msalScenarioHealthApiFetch } from 'Utils/MsalConfig';
import { TelemetryService } from 'Utils/TelemetryService';

export default class ScenarioHealthApi {
	static loadCoreAvailabilityData(
		param: AvailabilityOptions,
		success: SuccessCallback<CoreApiDataMap>,
		fail?: FailureCallback<string>
	): Promise<CoreApiDataMap> {
		// const startPerf = performance.now()
		return new Promise(() => {
			const start = new Date().getTime();
			const url = `v1.0/availability/daily?${ ScenarioHealthApi.getFileNameByFilter(param) }`;
			const options = {
				timeout: 60000
			};
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: options });
			msalScenarioHealthApiFetch.get(url, { ...options })
				.then((res: AxiosResponse<CoreApiDataMap>) => {
					const end = new Date().getTime();
					const duration = end - start; //ms
					if (duration > 30000) {
						// will be longer than the time reported in devtools
						TelemetryService.trackException('Get Availabilty Full', {
							error: { message: 'timeout of 30000ms exceeded' },
						});
					}
					TelemetryService.trackEvent('API v1.0/availability/daily End', { url: url, duration: duration });
					// const endPerf = performance.now()

					// console.debug('\tparam: ', param);
					// console.debug(`\t%cloadCoreAvailabilityData (ms): ${endPerf - startPerf}, %cend time(ms): ${endPerf}`, "color:#E91E63", "color:black")

					// Checking for empty response is handled in Core Data Context
					success(res.data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get Availabilty Full', { error: err });
					if (fail) fail('Error Retrieving Availability: ' + err.message);
				});
		});
	}

	static getFileNameByFilter(param: AvailabilityOptions): string {
		const {
			// startDate,
			// endDate,
			executive,
			groupBy,
			monthYear,
			serviceCategory,
			source,
		} = param;
		// let monthYear = (param && param.monthYear && param.monthYear.trim().toLowerCase()) || '';
		// const startDate = (param && param.startDate) || '';
		// const endDate = (param && param.endDate) || '';
		// const source = (param && param.source && param.source.trim().toLowerCase()) || 'all';
		// const queryShowBy = (param && param.groupBy && param.groupBy.trim().toLowerCase()) || 'scenario';
		// const executiveFilter = (param && param.executive)
		// const serviceCategory = (param && param.serviceCategory) || "front%20door"

		const queryParams =
			`DateType=${ monthYear ? monthYear?.toLowerCase().trim() : '' }` +
			`&Source=${ source ? source?.toLowerCase().trim() : '' }` +
			`&ShowBy=${ groupBy ? groupBy?.toLowerCase().trim() : 'scenario' }` +
			// + `${(monthYear === 'custom') ? `&End=${endDate}&Start=${startDate}` : ''}`
			`&executiveFilter=${ executive !== undefined ? executive : 'Executive' }` +
			`&serviceCategory=${ serviceCategory ? serviceCategory?.toLowerCase().replace(' ', '%20') : ''
			}`;

		return queryParams;
	}

	static loadScenariosListData(
		success: SuccessCallback<MetaData[]>,
		fail?: FailureCallback<string>,
		activeOnly = true,
		fromCache = true
	): Promise<MetaData[]> {
		return new Promise(() => {
			const url = `v1.0/scenarios?activeOnly=${ activeOnly }`;
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.get(url, {})
				.then((res: AxiosResponse<MetaData[]>) => {
					TelemetryService.trackEvent(`API v1.0/scenarios End`, { url: url, duration: Date.now() - apiStartTime });
					if (!res.data.length)
						TelemetryService.trackException('Empty Scenario Metadata', {});
					success(res.data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get Scenarios Metadata', { error: err });
					if (fail) fail('Error retrieving scenarios: ' + err.message);
				});
		});
	}

	static loadOwnersData(
		success: SuccessCallback<PeopleMetadata[]>,
		fail?: FailureCallback<string>
	): Promise<PeopleMetadata[]> {
		// const startPerf = performance.now()
		return new Promise(() => {
			const url = `v1.0/scenarioowners`;
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.get(url, {})
				.then((res: AxiosResponse<PeopleMetadata[]>) => {
					// const endPerf = performance.now()
					// console.debug(`%cloadOwnersData (ms): ${endPerf - startPerf}, %cend time(ms): ${endPerf}`, "color:#E91E63", "color:black")
					TelemetryService.trackEvent(`API ${ url } End`, { url: url, duration: Date.now() - apiStartTime });
					if (!res.data.length)
						TelemetryService.trackException('Empty Owners Metadata', {});
					success(res.data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get Owners Metadata', { error: err });
					if (fail) fail('Error retrieving Scenario Owners : ' + err.message);
				});
		});
	}

	static loadEngineeringManagersData(
		success: SuccessCallback<PeopleMetadata[]>,
		fail?: FailureCallback<string>
	): Promise<PeopleMetadata[]> {
		// const startPerf = performance.now()
		return new Promise(() => {
			const url = `v1.0/scenarioengineeringmanagers`;
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.get(url, {})
				.then((res: AxiosResponse<PeopleMetadata[]>) => {
					TelemetryService.trackEvent(`API ${ url } End`, { url: url, duration: Date.now() - apiStartTime });
					if (!res.data.length)
						TelemetryService.trackException('Empty Engineering Managers Metadata', {});
					success(res.data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get Engineering Managers Metadata', {
						error: err,
					});
					if (fail) fail('Error retrieving Engineering Managers: ' + err.message);
				});
		});
	}

	static getUserMetada(
		success: SuccessCallback<UserMetadata>,
		fail?: FailureCallback<string>
	): Promise<UserMetadata> {
		return new Promise(() => {
			const account = getAccount();
			const url = `v1.0/users/${ account.username.split('@')[0] }`;
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.get(url, {})
				.then(({ data }: AxiosResponse<UserMetadata>) => {
					TelemetryService.trackEvent(`API ${ url } End`, { url: url, duration: Date.now() - apiStartTime });
					if (data == null) {
						TelemetryService.trackException('Empty User Metadata', { alias: account.username.split('@')[0] });
					}

					success(data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get User Metadata', { error: err });
				});
		});
	}

	static getGraduatedScenarios(
		success: SuccessCallback<GraduatedScenario[]>,
		fail?: FailureCallback<string>,
		fromCache = true
	): Promise<GraduatedScenario[]> {
		return new Promise(() => {
			const url = `v1.0/gradudatedscenarios?fromCache=${ fromCache }`;
			const start = new Date().getTime();
			const options = {
				timeout: 40000
			};
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: options });
			msalScenarioHealthApiFetch.get(url, { ...options })
				.then(({ data }: AxiosResponse<GraduatedScenario[]>) => {
					const end = new Date().getTime();
					const duration = end - start; //ms
					if (duration > 30000)
						// will be longer than the time reported in devtools
						TelemetryService.trackException('Get Graduated Scenarios', {
							error: { message: 'timeout of 30000ms exceeded' },
						});
					TelemetryService.trackEvent(`API v1.0/gradudatedscenarios End`, { url: url, duration: duration });
					success(data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get Graduated Scenarios', { error: err });
					if (fail) fail('Error retrieving Graduated Scenarios: ' + err.message);
				});
		});
	}

	static loadScenarioGroupTypeData(
		success: SuccessCallback<ScenarioGroupTypeData[]>,
		fail?: FailureCallback<string>
	): Promise<ScenarioGroupTypeData[]> {
		return new Promise(() => {
			const url = `v1.0/GetAllScenarioGroups`;
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.get(url, {})
				.then(({ data }: AxiosResponse<ScenarioGroupTypeData[]>) => {
					TelemetryService.trackEvent(`API ${ url } End`, { url: url, duration: Date.now() - apiStartTime });
					if (data == null)
						TelemetryService.trackException('No scenario group type data', {});
					success(data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get Scenario Group', { error: err });
					if (fail) fail('Error retrieving scenario group: ' + err.message);
				});
		});
	}


	/****************All the API calls related to Self serve will be below ******************/

	/** getAllSelfServeScenarios method helps to get all the Scenarios for self serve */
	static getAllSelfServeScenarios(
		success: SuccessCallback<ISelfServeScenarios[]>,
		fail?: FailureCallback<string>,
		activeOnly = true,
		fromCache = true
	): Promise<ISelfServeScenarios[]> {
		return new Promise(() => {
			const url = `v1.0/GetAllSelfServeScenarios?fromCache=${ fromCache }&activeOnly=${ activeOnly }`;
			const apiStartTime = Date.now();
			TelemetryService.trackEvent(`API ${ url.split('?')[0] } Start`, { url: url, options: {} });
			msalScenarioHealthApiFetch.get(url, {})
				.then((res: AxiosResponse<ISelfServeScenarios[]>) => {
					TelemetryService.trackEvent(`API ${ url } End`, { url: url, duration: Date.now() - apiStartTime });
					if (!activeOnly && !res.data.length)
						TelemetryService.trackException('Empty Scenario Metadata', {});
					success(res.data);
				})
				.catch((err: AxiosError) => {
					if ((err && err.config && err.config.headers && err.config.headers as SometimesAuthHeaders)?.Authorization)
						(err.config.headers as SometimesAuthHeaders).Authorization = '';
					TelemetryService.trackException('Get Scenarios Metadata', { error: err });
					if (fail) fail('Error retrieving scenarios: ' + err.message);
				});
		});
	}
}
