import {
	CommandBarButton,
	IContextualMenuItem,
	IContextualMenuListProps,
	IOverflowSetItemProps,
	IRenderFunction,
	ISearchBoxStyles,
	IStackItemStyles,
	OverflowSet,
	ResizeGroup,
	SearchBox,
	Stack,
	StackItem,
	Text,
	Toggle
} from '@fluentui/react';
import { getFilterBarClassNames } from 'Components/FilterBar/FilterBar.styles';
import {
	IFilterBarProps,
	OverflowContextualItem,
	OverflowData,
	TimeframeMenuItemData
} from 'Components/FilterBar/FilterBar.types';
import { DateRangePicker } from 'Components/LazyLoad/_Lazy';
import { useCoreDataContext } from 'Context/CoreDataContext/CoreDataContext';
import {
	useGlobalFilters,
	useGlobalFiltersDispatch,
	useMegaMonths
} from 'Context/GlobalFiltersContext/GlobalFiltersContext';
import { useThemeContext } from 'Context/ThemeContext/ThemeContext';
import { semiBoldFontWeightStyle } from 'CSS/SharedStyles';
import { ExecutiveEnum, TimespanEnum } from 'DataModels/Global.types';
import React, { MouseEvent, Suspense, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import VF from 'Utils/ValueFormatting';
import { TooltipHostSHDefault } from 'Views/Layout.Shared';

const reset: IContextualMenuItem = {
	id: '',
	'data-cy': 'cy-resetOwnerFilter',
	text: 'Reset',
	iconProps: {
		iconName: 'ClearSelection',
	},
	key: '',
	ariaLabel: 'Reset',
	checked: false,
};

const searchBoxStyles: ISearchBoxStyles = {
	root: { margin: '8px' },
};

const headerDropDown: IStackItemStyles = { root: { borderBottom: '1px solid #ccc' } };
const bodyDropDown: IStackItemStyles = {
	root: ['custom-scrollbar', { height: '300px', overflowY: 'scroll' }],
};

const footerDropDown: IStackItemStyles = { root: { borderTop: '1px solid #ccc' } };

export function FilterBar({ styles, className }: IFilterBarProps): JSX.Element {
	const theme = useThemeContext();

	const location = useLocation();

	const globalFiltersDispatch = useGlobalFiltersDispatch();
	const {
		startDate,
		endDate,
		timeframe,
		owner,
		keyword,
		executiveFilter,
		shBlendedIndex,
		shMetTarget,
		engineeringManager,
	} = useGlobalFilters();

	const { megaMonths, megaMonthsIterator } = useMegaMonths();

	const { subComponentStyles } = getFilterBarClassNames(styles, {
		theme: theme,
		className,
	});
	const { overflowSet, keywordSearch, menuButton, ownerMenu, toggles } = subComponentStyles;

	//////////////////////////
	//#region 		Owners Dropdown
	//////////////////////////
	const { ownersMetaData, emMetaData } = useCoreDataContext();
	const [filteredOwnersList, setFilteredOwnersList] = useState<IContextualMenuItem[] | null>(
		null
	);

	const constructOwnerOption = useCallback(
		(ownerKey: string): IContextualMenuItem => {
			return {
				ariaLabel: ownerKey,
				key: ownerKey,
				text: ownersMetaData[ownerKey].Name,
				id: ownerKey, //used for testing, not selections
				'data-cy': 'cy-globalOwnerFilterItem',
				checked: owner === ownerKey,
			};
		},
		[owner, ownersMetaData]
	);


	const ownerDetails = useMemo(() => {
		const options: IContextualMenuItem[] = [];
		let ownerName;
		if (engineeringManager) {
			// This if check cannot be move to the above level for now,
			// since there are some teams does not have order.
			if (emMetaData[engineeringManager]?.Owners) {
				emMetaData[engineeringManager].Owners.forEach((ownerKey) => {
					if (ownersMetaData[ownerKey]) {
						if (owner === ownerKey) {
							ownerName = ownersMetaData[ownerKey].Name;
						}
						options.push(constructOwnerOption(ownerKey));
					}
				});
			}
		} else {
			for (const ownerKey in ownersMetaData) {
				if (ownerKey && ownersMetaData[ownerKey]) {
					if (owner === ownerKey) {
						ownerName = ownersMetaData[ownerKey].Name;
					}
					options.push(constructOwnerOption(ownerKey));
				}
			}
		}
		options.sort((a, b) => {
			return (a.text ?? '') < (b.text ?? '') ? -1 : 1;
		});

		return {
			owners: options,
			ownerName: ownerName,
		};
	}, [constructOwnerOption, emMetaData, engineeringManager, owner, ownersMetaData]);

	const onOwnerFilter = useCallback(
		(list: IContextualMenuItem[]) => setFilteredOwnersList(list),
		[]
	);

	const ownerDrodDownItem = useMemo((): OverflowContextualItem => {
		const { owners, ownerName } = ownerDetails;
		const tempList = filteredOwnersList || owners;
		const options: IContextualMenuItem[] = [...tempList, reset];

		return {
			isInOverflow: false,
			key: 'owner-list',
			ariaLabel: 'Owner',
			text: 'Owner',
			'data-cy': 'cy-ownerDD',
			secondaryText: ownerName,
			subMenuProps: {
				items: options,
				ariaLabel: 'Owner',
				calloutProps: {
					styles: ownerMenu,
				},
				onDismiss: () => setFilteredOwnersList(null),
				onItemClick: (
					ev?: MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
					item?: IContextualMenuItem
				) => {
					if (item) {
						globalFiltersDispatch({
							type: 'set owner',
							owner: !item.key ? null : item.key,
						});
						setFilteredOwnersList(null);
					}
				},
				onRenderMenuList: (
					props?: IContextualMenuListProps,
					defaultRender?: IRenderFunction<IContextualMenuListProps>
				) => {
					const { items } = props as IContextualMenuListProps;
					return (
						<Stack>
							<StackItem styles={ headerDropDown }>
								<SearchBox
									data-cy="cy-ownerSearchBox"
									placeholder="Owner Search"
									iconProps={ { iconName: 'Search' } }
									ariaLabel="Owner Search"
									onChange={ (
										event?: React.ChangeEvent<HTMLInputElement>,
										newValue?: string
									) => {
										if (newValue) {
											const tempList = owners.filter(
												(k: IContextualMenuItem) =>
													k.text &&
													k.text
														.toLowerCase()
														.indexOf(newValue.toLowerCase()) > -1
											);
											onOwnerFilter(tempList);
										}
									} }
									onClear={ () => onOwnerFilter(owners) }
									styles={ searchBoxStyles }
								/>
							</StackItem>
							<StackItem styles={ bodyDropDown }>
								{ defaultRender &&
									defaultRender({
										...props,
										items: items.slice(0, -1),
									} as IContextualMenuListProps) }
							</StackItem>
							<StackItem styles={ footerDropDown }>
								{ defaultRender &&
									defaultRender({
										...props,
										items: items.slice(items.length - 1, items.length),
									} as IContextualMenuListProps) }
							</StackItem>
						</Stack>
					);
				},
			},
		};
	}, [filteredOwnersList, ownerDetails, onOwnerFilter, ownerMenu, globalFiltersDispatch]);
	//#endregion

	//////////////////////////
	//#region 		Keyword Search
	//////////////////////////
	const keywordSearchItem = useMemo(
		(): OverflowContextualItem => ({
			isInOverflow: false,
			key: 'keywork-search',
			ariaLabel: 'Keyword search',
			onRender: (/*item: any*/) => (
				<SearchBox
					placeholder="Filter by keyword"
					iconProps={ { iconName: 'Filter' } }
					ariaLabel="Filter by keyword"
					defaultValue={ keyword ?? '' }
					onChange={ (event, value) =>
						globalFiltersDispatch({
							type: 'set keyword',
							keyword: value || '',
						})
					}
					styles={ keywordSearch }
					underlined={ true }
				/>
			),
		}),
		[keyword, keywordSearch, globalFiltersDispatch]
	);

	//#endregion

	const onExecutiveFilterChange = useCallback(
		(
			ev?: MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
			option?: IContextualMenuItem
		) => {
			if (option && option.data) {

				globalFiltersDispatch({
					type: 'set executive',
					executiveFilter: option.key,
				});
			}
		},
		[globalFiltersDispatch]
	);
	//////////////////////////
	//#region 		Boolean Toggles (Executive, Next 9, etc)
	//////////////////////////
	const executiveDropdownItem = useMemo(() => {
		const options: IContextualMenuItem[] = [
			{
				key: ExecutiveEnum.Executive,
				text: ExecutiveEnum.Executive,
				id: ExecutiveEnum.Executive,
				checked: executiveFilter === ExecutiveEnum.Executive,
				data: {
					executiveFilter: ExecutiveEnum.Executive,
				},
				onClick: onExecutiveFilterChange,
			},
			{
				key: ExecutiveEnum.NonExecutive,
				text: ExecutiveEnum.NonExecutive,
				id: ExecutiveEnum.NonExecutive,
				checked: executiveFilter === ExecutiveEnum.NonExecutive,
				data: {
					executiveFilter: ExecutiveEnum.NonExecutive,
				},
				onClick: onExecutiveFilterChange,
			},
			{
				key: ExecutiveEnum.All,
				text: ExecutiveEnum.All,
				id: ExecutiveEnum.All,
				checked: executiveFilter === ExecutiveEnum.All,
				data: {
					executiveFilter: ExecutiveEnum.All,
				},
				onClick: onExecutiveFilterChange,
			}
		];
		const overflowItem: OverflowContextualItem = {
			isInOverflow: false,
			key: 'executive-dropdown',
			ariaLabel: 'executive dropdown',
			text: 'Exec/Non-Exec',
			secondaryText: executiveFilter,
			subMenuProps: {
				items: options,
			},
		};
		return overflowItem;
	}, [executiveFilter,  onExecutiveFilterChange]);

	const blendedIndexToggleItem: OverflowContextualItem = useMemo((): OverflowContextualItem => {
		return {
			isInOverflow: true,
			isAlwaysInOverflow: true,
			key: 'blended index',
			onRender: (item: OverflowContextualItem) => {
				return (
					<Toggle
						label="Show Blended Index"
						inlineLabel
						ariaLabel="Show Blended Index"
						checked={ shBlendedIndex }
						role="menuitemcheckbox"
						onChange={ (event?: MouseEvent<HTMLElement>, checked?: boolean) => {
							globalFiltersDispatch({
								type: 'set shblended',
								showBlendedIndex: !!checked,
							});
						} }
						styles={ toggles(item.isInOverflow) }
					/>
				);
			},
		};
	}, [shBlendedIndex, toggles, globalFiltersDispatch]);

	const metTargetToggleItem: OverflowContextualItem = useMemo((): OverflowContextualItem => {
		return {
			isInOverflow: true,
			isAlwaysInOverflow: true,
			key: 'met target',
			onRender: (item: OverflowContextualItem) => {
				return (
					<Toggle
						label="Show Met Target"
						inlineLabel
						ariaLabel="Show Met Target"
						checked={ shMetTarget }
						role="menuitemcheckbox"
						onChange={ (event?: MouseEvent<HTMLElement>, checked?: boolean) => {
							globalFiltersDispatch({
								type: 'set shmettarget',
								showMetTarget: !!checked,
							});
						} }
						styles={ toggles(item.isInOverflow) }
					/>
				);
			},
		};
	}, [shMetTarget, toggles, globalFiltersDispatch]);
	//#endregion

	//////////////////////////
	//#region 		TimeFrame dropdown
	/////////////////////////
	const onTimeframeChange = useCallback(
		(
			ev?: MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
			option?: IContextualMenuItem
		) => {
			if (option && option.data) {
				const { startDate, endDate, timeframe } = option.data as TimeframeMenuItemData;
				globalFiltersDispatch({
					type: 'set timeframe',
					startDate: startDate,
					endDate: endDate,
					timeframe: timeframe,
				});
			}
		},
		[globalFiltersDispatch]
	);

	const monthsDrodDownItem = useMemo(() => {
		const now = new Date();
		const minDate = new Date();
		minDate.setMonth(minDate.getMonth() - 5);

		now.setHours(0, 0, 0, 0);
		const today = VF.yyyymmddDate(now);

		const yesterday = new Date(now);
		yesterday.setDate(yesterday.getDate() - 1);

		const sevenDaysAgo = new Date(now);
		sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

		let customText = TimespanEnum.Custom as string;

		let tempStartDate: Date;
		let tempEndDate: Date;

		if (timeframe === customText) {
			tempStartDate = new Date(
				`${ startDate.slice(4, 6) }/${ startDate.slice(6, 8) }/${ startDate.slice(0, 4) }`
			);
			tempEndDate = new Date(
				`${ endDate.slice(4, 6) }/${ endDate.slice(6, 8) }/${ endDate.slice(0, 4) }`
			);
			tempEndDate.setDate(tempEndDate.getDate() - 1);
			customText = `${ VF.shortMonth(
				tempStartDate
			) } ${ tempStartDate.getDate() } - ${ VF.shortMonth(
				tempEndDate
			) } ${ tempEndDate.getDate() } `;
		}

		const options: IContextualMenuItem[] = [
			{
				key: TimespanEnum.Last6Months,
				text: TimespanEnum.Last6Months,
				id: TimespanEnum.Last6Months,
				checked: timeframe === TimespanEnum.Last6Months,
				data: {
					startDate: megaMonthsIterator[0].startDate,
					endDate: megaMonthsIterator[megaMonthsIterator.length - 1].endDate,
					timeframe: TimespanEnum.Last6Months,
				},
				onClick: onTimeframeChange,
			},
			{
				key: TimespanEnum.Yesterday,
				text: TimespanEnum.Yesterday,
				id: TimespanEnum.Yesterday,
				checked: timeframe === TimespanEnum.Yesterday,
				data: {
					startDate: VF.yyyymmddDate(yesterday),
					endDate: today,
					timeframe: TimespanEnum.Yesterday,
				},
				onClick: onTimeframeChange,
			},
			{
				key: TimespanEnum.Last7Days,
				text: TimespanEnum.Last7Days,
				id: TimespanEnum.Last7Days,
				checked: timeframe === TimespanEnum.Last7Days,
				data: {
					startDate: VF.yyyymmddDate(sevenDaysAgo),
					endDate: today,
					timeframe: TimespanEnum.Last7Days,
				},
				onClick: onTimeframeChange,
			},
			...megaMonthsIterator
				.map<IContextualMenuItem>((month) => ({
					key: month.startDate,
					text: `${ month.fullMonth } ${ month.year }`,
					id: month.startDate,
					checked: timeframe === month.startDate,
					data: {
						startDate: month.startDate,
						endDate: month.endDate,
						timeframe: month.startDate,
					},
					onClick: onTimeframeChange,
				}))
				.reverse(),
			{
				key: TimespanEnum.Custom,
				text: customText,
				id: TimespanEnum.Custom,
				checked: timeframe === TimespanEnum.Custom,
				iconProps: {
					iconName: 'Calendar',
				},
				subMenuProps: {
					items: [
						{
							key: 'calender-date-range',
							onRender: (
								item,
								// Intentional any due to package requirements
								/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
								dismissMenu: (ev?: any, dismissAll?: boolean) => void
							) => {
								return (
									<Suspense fallback={ null }>
										<DateRangePicker
											minDate={ minDate }
											maxDate={ new Date() }
											startDate={ tempStartDate }
											endDate={ tempEndDate }
											onApplyDate={ (
												ev: MouseEvent<HTMLElement>,
												startDate: Date,
												endDate: Date
											) => {
												endDate.setDate(endDate.getDate() + 1);
												onTimeframeChange(ev, {
													key: 'apply-custom-date',
													data: {
														startDate: VF.yyyymmddDate(startDate),
														endDate: VF.yyyymmddDate(endDate),
														timeframe: TimespanEnum.Custom,
													} as TimeframeMenuItemData,
												});
												dismissMenu(ev);
											} }
										/>
									</Suspense>
								);
							},
						},
					],
				},
			},
		];
		const monthName = megaMonths[timeframe]
			? `${ megaMonths[timeframe].fullMonth } ${ megaMonths[timeframe].year }`
			: timeframe === TimespanEnum.Custom
				? customText
				: timeframe;
		const overflowItem: OverflowContextualItem = {
			isInOverflow: false,
			key: 'month-dropdown',
			ariaLabel: 'Month dropdown',
			text: 'Month',
			secondaryText: monthName,
			subMenuProps: {
				items: options,
			},
		};
		return overflowItem;
	}, [endDate, megaMonthsIterator, startDate, timeframe, megaMonths, onTimeframeChange]);
	//#endregion

	//////////////////////////
	//#region 		Command Bar Props
	/////////////////////////
	const clearButtonItem = useMemo((): OverflowContextualItem => {
		return {
			isInOverflow: false,
			key: 'clear',
			ariaLabel: 'Clear filter',
			iconProps: { iconName: 'Cancel' },
			onClick: () =>
				globalFiltersDispatch({
					type: 'reset visible filters',
				}),
		};
	}, [globalFiltersDispatch]);

	const { menuItems, overflowItems } = useMemo(() => {
		const pathName = location.pathname.toLowerCase();
		const onManagementPage = pathName === '/management';
		const onPendingChangesPage = pathName === '/pendingchanges';
		const onGapAnalysisPage = pathName === '/gaps';
		const onHealthPage = pathName === '/';

		const menuItems = [keywordSearchItem];
		const overflowItems = [];

		if (!(onManagementPage || onPendingChangesPage)) menuItems.push(monthsDrodDownItem);

		if (!onGapAnalysisPage) menuItems.push(ownerDrodDownItem);

		menuItems.push(executiveDropdownItem);

		if (onHealthPage) {
			overflowItems.push(metTargetToggleItem);
			overflowItems.push(blendedIndexToggleItem);
		}

		overflowItems.push(clearButtonItem);

		return { menuItems, overflowItems };
	}, [
		blendedIndexToggleItem,
		clearButtonItem,
		executiveDropdownItem,
		keywordSearchItem,
		location.pathname,
		metTargetToggleItem,
		monthsDrodDownItem,
		ownerDrodDownItem,
	]);

	const overflowData: OverflowData = {
		primary: menuItems,
		overflow: overflowItems,
	};

	/** Moves command bar items into overflow when there is not enough space */
	function onReduceData(prevData: OverflowData): OverflowData | undefined {
		if (prevData.primary.length === 0) {
			return undefined;
		}

		const primary: OverflowContextualItem[] = [];
		let overflow: OverflowContextualItem[] = [];
		let index = 0;
		for (const item of prevData.primary) {
			if (index === prevData.primary.length - 1 || item.isAlwaysInOverflow)
				overflow.push(item);
			else primary.push(item);
			index++;
		}

		overflow = overflow.concat(prevData.overflow);
		// const overflow = [...prevData.primary.slice(-1), ...prevData.overflow];
		overflow.forEach((item: OverflowContextualItem) => (item.isInOverflow = true));
		// const primary: OverflowContextualItem[] = prevData.primary.slice(0, -1);

		return { primary, overflow };
	}

	/** Render contents in of visible items. */
	function onRenderData(data: OverflowData) {
		data.primary.forEach((item) => (item.isInOverflow = false));
		return (
			<OverflowSet
				role="menuitem"
				styles={ overflowSet }
				items={ data.primary }
				overflowItems={ data.overflow.length ? data.overflow : undefined }
				onRenderItem={ (item: IOverflowSetItemProps) => {
					const typedItem = item as OverflowContextualItem;
					if (typedItem.onRender) {
						return typedItem.onRender(typedItem, () => undefined);
					} else if (typedItem && typedItem.subMenuProps) {
						return (
							<CommandBarButton
								{ ...item }
								styles={ menuButton(false) }
								menuProps={ typedItem.subMenuProps }
								ariaLabel={ typedItem.ariaLabel }
								key={ typedItem.key }
								onRenderText={ () =>
									/*
									buttonProps?: IButtonProps,
									defaultRender?: () => void*/ {
									return (
										<div key={ typedItem.key + '-label' }>
											<Text styles={ semiBoldFontWeightStyle }>
												{ typedItem.text }:{ ' ' }
											</Text>
											<Text>{ typedItem.secondaryText }</Text>
										</div>
									);
								}
								}
							/>
						);
					} else if (typedItem) {
						return <CommandBarButton styles={ menuButton(false) } { ...item } />;
						// const content = <CommandBarButton styles={menuButton(false)} {...item} />;
						// return typedItem.key === 'clear' ? (
						// 	<TooltipHostSHDefault content={'Clear filter'}>
						// 		{' '}
						// 		{content}
						// 	</TooltipHostSHDefault>
						// ) : (
						// 	content
						// );
					}
					return null;
				} }
				onRenderOverflowButton={ (overflowItems) => {
					let clearButton;
					const tempItems = [];
					for (const item of overflowItems as IOverflowSetItemProps[]) {
						if (item.key === 'clear') clearButton = item;
						else tempItems.push(item);
					}
					if (location.pathname.toLowerCase() !== '/') {
						return (
							<>
								<TooltipHostSHDefault content={ 'Clear filters' }>
									<CommandBarButton
										role="menuitem"
										styles={ menuButton(true) }
										ariaLabel="Clear filters"
										{ ...clearButton }
									/>
								</TooltipHostSHDefault>
							</>
						);
					} else {
						return (
							<>
								<TooltipHostSHDefault content={ 'More items' }>
									<CommandBarButton
										role="menuitem"
										styles={ menuButton(true) }
										iconProps={ { iconName: 'More' } }
										menuProps={ { items: tempItems } }
										data={ { isInOverflow: true } }
										ariaLabel="More Items"
									/>
								</TooltipHostSHDefault>
								<TooltipHostSHDefault content={ 'Clear filters' }>
									<CommandBarButton
										role="menuitem"
										styles={ menuButton(true) }
										ariaLabel="Clear filters"
										{ ...clearButton }
									/>
								</TooltipHostSHDefault>
							</>
						);
					}
				} }
			/>
		);
	}
	//#endregion

	return (
		<ResizeGroup
			role="menubar"
			data={ overflowData }
			onReduceData={ onReduceData }
			onRenderData={ onRenderData }
		/>
	);
}

export default FilterBar;
