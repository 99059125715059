import { ITokenFunction } from '@fluentui/foundation-legacy';
import {
	FontSizes,
	FontWeights,
	ICalloutContentStyleProps,
	ICalloutContentStyles,
	IDetailsColumnStyleProps,
	IDetailsColumnStyles,
	IDetailsHeaderStyleProps,
	IDetailsHeaderStyles,
	IDetailsRowStyleProps,
	IDetailsRowStyles,
	IGroupHeaderStyles,
	ISeparatorStyleProps,
	ISeparatorStyles,
	IStackTokens,
	ITextStyles,
	ITooltipStyleProps,
	ITooltipStyles,
	mergeStyles,
	mergeStyleSets
} from '@fluentui/react';
import { ICardProps, ICardTokens } from '@fluentui/react-cards';
import { SharedColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors';
import {
	IAccordionCommandBarStyleProps,
	IAccordionCommandBarStyles
} from 'Components/AccordionCommandBar/AccordionCommandBar.types';

export const rechartsCustomTooltip = {
	backgroundColor: '#3b3a39',
	borderRadius: '4px',
	color: '#fff',
	padding: '7px 10px',
};

export const boldFontWeightStyle: ITextStyles = { root: { fontWeight: FontWeights.bold } };
export const semiBoldFontWeightStyle: ITextStyles = { root: { fontWeight: FontWeights.semibold, userSelect: "text" } };
export const userselectStyle: ITextStyles = { root: { userSelect: "text" } };

export const customTooltipClassName = mergeStyleSets({
	tooltip: rechartsCustomTooltip,
}).tooltip;

export const fluentTooltipCalloutStyle = ({
	theme,
}: ICalloutContentStyleProps): Partial<ICalloutContentStyles> => {
	return {
		calloutMain: { borderRadius: 0 },
		beakCurtain: {
			minWidth: 30,
			backgroundColor: theme.palette.neutralPrimaryAlt,
		},
		beak: {
			backgroundColor: theme.palette.neutralPrimaryAlt,
		},
	};
};

export const fluentTooltipStyle = ({ theme }: ITooltipStyleProps): Partial<ITooltipStyles> => {
	return {
		content: {
			backgroundColor: theme.palette.neutralPrimaryAlt,
			color: theme.palette.white,
		},
	};
};

export const inheritColorText: ITextStyles = { root: { color: 'inherit' } };

export const successText: ITextStyles = { root: { color: SharedColors.green20 } };
export const failureText: ITextStyles = { root: { color: SharedColors.red10 } };

export const passFailTextStyle =
	(isSuccess: boolean, optionalStyle?: ITextStyles) => (/*textProps: ITextProps*/): ITextStyles =>
		mergeStyleSets(isSuccess && successText, !isSuccess && failureText, optionalStyle);

export const tableSticky = mergeStyles({
	paddingLeft: 16,
	paddingRight: 16,
});

export const tableGroupHeaderStyles = (
	background: string,
	zIndex: number
): Partial<IGroupHeaderStyles> => ({
	root: {
		borderBottomColor: 'rgb(243, 242, 241)',
		background: background,
		height: 'unset',
		flexGrow: 1,
		zIndex: zIndex,
	},
	groupHeaderContainer: {
		height: 'unset',
		alignItems: 'center',
	},
});

export const tableDetailsRowsStyles =
	(backgroundColor?: string) =>
		({ theme }: IDetailsRowStyleProps): Partial<IDetailsRowStyles> => {
			return {
				root: [
					{
						// height: 'auto',
						// maxHeight: 96,
						border: 'none',
						fontSize: FontSizes.medium,
						backgroundColor: backgroundColor ?? 'inherit',
					},
				],
				cell: {
					display: 'inline-flex',
					alignItems: 'center',
					color: theme.semanticColors.bodyText,
					selectors: {
						'&.table-six-month-trend': { overflow: 'visible' },
					},
				},
				isMultiline: {
					display: 'inline-flex',
					alignItems: 'center',
				},
			};
		};

export const childrenGap8: IStackTokens = { childrenGap: 8 };
export const childrenGap16: IStackTokens = { childrenGap: 16 };
export const stackPadding8: IStackTokens = { padding: 8 };
export const stackChildren16Padding8: IStackTokens = { padding: 8, childrenGap: 16 };
export const stackChildren8Padding8: IStackTokens = { padding: 8, childrenGap: 8 };

export const cardHoverTokens: ITokenFunction<ICardProps, ICardTokens> = (
	{
		// theme,
		tokens,
	}: ICardProps,
	theme
): ICardTokens => {
	return {
		boxShadowFocused: `0 6.4px 14.4px 0  ${ theme?.palette.themeDark ?? '#000000'
			}80, 0 1.2px 3.6px 0 ${ theme?.palette.themeDark ?? '#000000' }4d`,
		borderFocused: '',
		boxShadowHovered: `0 6.4px 14.4px 0  ${ theme?.palette.themeDark ?? '#000000'
			}b3, 0 1.2px 3.6px 0 ${ theme?.palette.themeDark ?? '#000000' }80`,
		...tokens,
	};
};

export const separatorStyles = ({ theme }: ISeparatorStyleProps): Partial<ISeparatorStyles> => ({
	root: {
		selectors: {
			':before': {
				backgroundColor: theme.palette.neutralQuaternaryAlt,
			},
		},
	},
});

export const defaultSHTableHeaderStyles = ({
	theme,
}: IDetailsHeaderStyleProps): Partial<IDetailsHeaderStyles> => {
	const { palette } = theme;
	return {
		root: {
			height: 'unset',
			lineHeight: 'unset',
			minHeight: '100%',
			display: 'inline-flex',
			backgroundColor: palette.themePrimary,
			verticalAlign: 'middle',
			paddingTop: 0,
		},
		cellIsGroupExpander: {
			color: palette.white,
			height: 'unset',
			minHeight: '100%',
			selectors: {
				'&:hover': { backgroundColor: palette.themeSecondary },
				'&:active': { backgroundColor: palette.themeSecondary },
			},
		},
		cellSizer: { minHeight: '100%' },
		cellIsCheck: { minHeight: '100%' },
	};
};

/**Must be used in a table with `defaultSHTableHeader` on header */
export const wrappableColumnHeadersStyles = ({
	theme,
}: IDetailsColumnStyleProps): Partial<IDetailsColumnStyles> => {
	const { palette } = theme;
	return {
		root: {
			height: 'unset',
			backgroundColor: palette.themePrimary,
			paddingTop: 10,
			paddingBottom: 10,
			selectors: {
				'&:hover': { backgroundColor: palette.themeSecondary },
				'&:active': { backgroundColor: palette.themeSecondary },
			},
		},
		cellTitle: {
			color: palette.white,
			alignItems: 'center',
			display: 'flex',
		},
		cellName: { whiteSpace: 'normal' },
		cellTooltip: {
			minHeight: 0,
			height: '100%',
			position: 'relative',
			display: 'flex',
			justifyContent: 'left',
			alignItems: 'center',
		},
		filterChevron: { color: palette.white, paddingTop: 4 },
		sortIcon: { color: palette.white, paddingTop: 4 },
		nearIcon: { color: palette.white, paddingTop: 4 },
	};
};

export const accordionStyles = ({
	theme,
}: IAccordionCommandBarStyleProps): Partial<IAccordionCommandBarStyles> => ({
	root: [
		{
			boxShadow: theme.effects.elevation8,
			borderRadius: theme.effects.roundedCorner2,
		},
		{
			minWidth: '100%',
			width: '100%',
		},
	],
	subComponentStyles: {
		contentWrapper: {
			root: {
				paddingBottom: 16,
			},
		},
		commandBar: {},
	},
});
