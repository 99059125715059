import { AccountInfo, InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import {
	AppInsightsContext,
	AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { initializeIcons } from '@uifabric/icons';
import { registerCustomIcons } from 'Components/CustomIcons/CustomIcons';
import SpinnerFallback from 'Components/LazyLoad/SpinnerFallback';
import Shell from 'Components/Shell/Shell';
import { IPageDefinition, IShellProps } from 'Components/Shell/Shell.types';
import { ThemeProvider } from 'Context/ThemeContext/ThemeContext';
import { browserHistory } from 'index';
import React,{ Suspense, useState } from 'react';
import { showDevAdminItems } from 'Utils/MsalConfig';
import { reactAppInsights, TelemetryService } from 'Utils/TelemetryService';
import DashboardContainer from 'Views/Dashboard/Dashboard.Container';
import { ErrorText } from 'Views/Layout.Shared';
import MvpDashboardContainer from 'Views/Mvp/Dashboard/MvpDashboard.Container';

initializeIcons();
registerCustomIcons();

export interface AppRouteProps {
	showNewYearMessage: boolean;
}

const primaryRoutes: IPageDefinition[] = [
	{
		element: DashboardContainer,
		id: 'dashboard',
		title: '',
		path: '/*',
	},
];

// Isolated route for interns
if (showDevAdminItems()) {
	primaryRoutes.unshift({
		element: MvpDashboardContainer,
		title: '',
		id: 'mvp dahboard',
		path: '/mvp/*',
	});
}

function App(): JSX.Element {
	useMsalAuthentication(InteractionType.Redirect);

	const [signedinUser, setSignedinUser] = useState(undefined as AccountInfo | undefined);

	function Render() {
		const { accounts } = useMsal();
		try {
			const account = accounts[0];
			if (account) {
				setSignedinUser(account);
			}
		} catch (e) {
			//
		}
	}
	const [showNewYearMessage /*, setShowNewYearMessage*/] = useState(true);
	const { theme } = ThemeProvider.useTheme();

	const shellProps: AppRouteProps & IShellProps = {
		showNewYearMessage,
		theme,
		pages: primaryRoutes,
	};

	if (signedinUser == undefined) {
		return (<>{ Render() }</>);
	} else {
		TelemetryService.initialize({ history: browserHistory }, signedinUser.username);

		return (
			<AuthenticatedTemplate>
				<AppInsightsContext.Provider value={ reactAppInsights }>
					<AppInsightsErrorBoundary onError={ ErrorText } appInsights={ reactAppInsights }>
						<Suspense fallback={ <SpinnerFallback /> }>
							<Shell { ...shellProps } />
						</Suspense>
					</AppInsightsErrorBoundary>
				</AppInsightsContext.Provider>
			</AuthenticatedTemplate>
		);
	}
}

export default App;
