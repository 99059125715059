import { Spinner, Stack } from '@fluentui/react';
import React from 'react';

type SpinnerFallbackProps = { label?: string };

function SpinnerFallback({ label = 'Loading...' }: SpinnerFallbackProps): JSX.Element {
	return (
		<Stack grow horizontal horizontalAlign="center" verticalFill verticalAlign="center">
			<Spinner label={label} />
		</Stack>
	);
}

export default SpinnerFallback;
