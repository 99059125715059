import { AvailabilityOptions } from 'API/ScenarioHealthApi.types';
import {
	ISelfServeScenarios,
	MajorScenarioMetaData,
	MetaData,
	MinorScenarioMetaData,
	ModifiedApiDataMap,
	ModifiedGraduatedScenario,
	PeopleMetadata,
	ScenarioGroupMetadata,
	ScenarioGroupTypeData,
	UserMetadata,
} from 'DataModels/CoreDataModels.types';

export type CoreDataState = {
	coreScenarioData: ModifiedApiDataMap;
	scenarioStatus: Status;
	metaDataStatus: Status;
	emMetaData: Record<string, PeopleMetadata>;
	ownersMetaData: Record<string, PeopleMetadata>;
	scenarioMetaData: MetaData[];
	selfServeScenarioMetaData: ISelfServeScenarios[];
	majorScenarioList: MajorScenarioMetaData[];
	activeMinorScenarioList: MinorScenarioMetaData[];
	/**
	 * Only used in Management.
	 * Includes inactive scenarios.
	 * Excludes Unspecified scenarios w/ Unspecified Major scenarios. */
	fullMinorScenarioList: MinorScenarioMetaData[];
	/** Tracking if we have got the fullMinorScenarioList either on mount or visiting managment page */
	managementMetadataStatus: Status;
	scenarioGroupList: ScenarioGroupMetadata[];
	userMetadata: UserMetadata;
	graduatedScenarios: ModifiedGraduatedScenario[];
	scenarioGroupTypeData: ScenarioGroupTypeData[];
};

export type Status = 'empty' | 'fetching' | 'success' | 'error';

export type Action =
	| { type: CoreDataActions.ApiError; error: string}
	| { type: CoreDataActions.FetchedEMMetaData; emMetaData: PeopleMetadata[] }
	| { type: CoreDataActions.FetchedOwnersMetaData; ownersMetaData: PeopleMetadata[] }
	| { type: CoreDataActions.FetchedScenarios; scenarioData: ModifiedApiDataMap }
	| {
		type: CoreDataActions.FetchedScenariosMetaData;
		scenarioMetaData: MetaData[];
	}
	| {
		type: CoreDataActions.FetchedSelfServeScenariosMetaData;
		selfServeScenarioMetaData: ISelfServeScenarios[];
	}
	| { type: CoreDataActions.LoadAllMetaData; dispatch: CoreDataDispatch }
	| {
		type: CoreDataActions.LoadCoreData;
		apiParams: Partial<AvailabilityOptions>;
		dispatch: CoreDataDispatch;
		/** Include inactive scenarios and get scenarios metadata directly from sql instead of cache. */
		getFullScenarios: boolean;
	}
	| {
		type: CoreDataActions.GenerateMetaDataLists;
		dispatch: CoreDataDispatch;
		/** Includes inactive scenarios. Filter out scenarios with 'Unspecified' scenario group and major scenario*/
		getFullScenarios: boolean;
	}
	| { type: CoreDataActions.FetchedUserMetaData; userMetadata: UserMetadata }
	| { type: CoreDataActions.FetchedUserProfileData; profileImage: string }
	| { type: CoreDataActions.FetchedUserSecurityGroup; canApproveScenarios: boolean }
	| {
		type: CoreDataActions.LoadScenarioMetadata;
		dispatch: CoreDataDispatch;
		/** Include inactive scenarios and get scenarios metadata directly from sql instead of cache. */
		getFullScenarios: boolean;
	}
	| {
		type: CoreDataActions.LoadSelfServeScenarioMetadata;
		dispatch: CoreDataDispatch;
		/** Include inactive scenarios and get scenarios metadata directly from sql instead of cache. */
		getFullScenarios: boolean;
	}
	| { type: CoreDataActions.LoadManagementMetadata; dispatch: CoreDataDispatch }
	| { type: CoreDataActions.CheckMetaDataStatus }
	| { type: CoreDataActions.MapOwnersToEMs }
	| {
		type: CoreDataActions.FetchedGraduatedScenarios;
		graduatedScenarios: ModifiedGraduatedScenario[];
	}
	| { type: CoreDataActions.FetchedScenarioGroupTypeData; scenarioGroupTypeData: ScenarioGroupTypeData[] };

export type CoreDataDispatch = (action: Action) => void;

export enum CoreDataActions {
	ApiError = 'api error',
	FetchedEMMetaData = 'fetched engineering managers metadata',
	FetchedOwnersMetaData = 'fetched owners metadata',
	FetchedScenarios = 'fetched scenarios',
	FetchedScenariosMetaData = 'fetched scenarios metadata',
	FetchedSelfServeScenariosMetaData = 'fetched self serve scenarios metadata',
	FetchedManagementMetadata = 'fetched managment scenarios metadata',
	LoadAllMetaData = 'load all metadata',
	LoadCoreData = 'load core data',
	GenerateMetaDataLists = 'generate metadata lists',
	FetchedUserMetaData = 'fetched user metadata',
	FetchedUserProfileData = 'fetched user profiledata',
	FetchedUserSecurityGroup = 'fetched user security group',
	LoadScenarioMetadata = 'load scenario metadata',
	LoadSelfServeScenarioMetadata = 'load self serve scenario metadata',
	LoadManagementMetadata = 'load scenario metadata for management',
	CheckMetaDataStatus = 'check metadata status',
	MapOwnersToEMs = 'map owners to engineering managers',
	FetchedGraduatedScenarios = 'feteched graduated scenarios',
	FetchedScenarioGroupTypeData = 'fetched scenario group type data',
}
