import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { getShellClassNames } from 'Components/Shell/Shell.styles';
import { ContainerRouteProps, IPageDefinition, IShellProps } from 'Components/Shell/Shell.types';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { reactAppInsights } from 'Utils/TelemetryService';
import { ErrorText } from 'Views/Layout.Shared';

export default function Shell(shellProps: IShellProps & ContainerRouteProps): JSX.Element {
	const { styles, theme, className, pages = [] } = shellProps;
	const classNames = getShellClassNames(styles, {
		theme: theme,
		className,
		// Other style props
	});
	return (
		<div className={classNames.root}>
			<BrowserRouter>
				<Routes>
					{/* These routes are assumed to act 100% independently.
						These routes should have no shared function/data/api calls */}
					{pages.map(pageToRoute)}
				</Routes>
			</BrowserRouter>
		</div>
	);

	function pageToRoute(page: IPageDefinition, key: number | string): React.ReactNode {
		return createRoute(page, key);
	}

	function createRoute(page: IPageDefinition, key: number | string): React.ReactNode {
		return (
			<Route
				element={
					<AppInsightsErrorBoundary onError={ErrorText} appInsights={reactAppInsights}>
						{React.createElement(page.element, { ...shellProps })}
					</AppInsightsErrorBoundary>
				}
				key={key}
				path={page.path}
			/>
		);
	}
}
