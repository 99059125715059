import { styled } from '@fluentui/react/lib/Utilities';
import Shell from 'Components/Shell/Shell.base';
import { shellStyles } from 'Components/Shell/Shell.styles';
import {
	ContainerRouteProps,
	IShellProps,
	IShellStyleProps,
	IShellStyles,
} from 'Components/Shell/Shell.types';

const StyledShell = styled<IShellProps & ContainerRouteProps, IShellStyleProps, IShellStyles>(
	Shell,
	shellStyles
);
export default StyledShell;
