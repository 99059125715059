import {
	classNamesFunction,
	FontSizes,
	FontWeights,
	IButtonProps,
	IButtonStyles,
	IIconStyles,
	IStackItemTokens,
	ITheme,
} from '@fluentui/react';
import { IHeaderStyleProps, IHeaderStyles } from 'Components/Page/Header.types';

export const HeaderStyles = (props: IHeaderStyleProps): IHeaderStyles => {
	const { theme } = props;
	return {
		headerStackStyles: {
			backgroundColor: theme.palette.themeDark,
		},
		brandLink: {
			borderRadius: '50%',
			padding: '4px 6px 4px 4px',
			selectors: {
				':hover': {
					background: 'radial-gradient(rgba(0, 0, 0, 0.25), transparent)',
				},
			},
		},
		subComponentStyles: {
			menuStyles: { root: { paddingRight: '20px' } },
			primaryButtonStyles: {
				root: {
					height: 48,
					background: theme.palette.themeDark,
					borderColor: theme.palette.themeDark,
				},
				icon: {
					fontWeight: FontWeights.semibold,
				},
			},
			profilestyles: { root: { marginRight: 4 } },
			actionButtonStyles: {
				root: { minWidth: 100 },
				rootHovered: {
					boxShadow: theme.effects.elevation16,
				},
			},
			textStyles: {
				root: {
					fontWeight: FontWeights.semibold,
					fontSize: FontSizes.xLargePlus,
				},
			},
		},
	};
};

export const headerTokens: IStackItemTokens = { padding: '4px 10px' };
export const profileIconTokens: IStackItemTokens = { padding: 4, margin: 4 };
export const screenShotOverrideStyles = ({ theme }: IButtonProps): IButtonStyles => ({
	rootHovered: {
		background: theme?.semanticColors.primaryButtonBackgroundHovered,
	},
	rootPressed: {
		background: theme?.semanticColors.primaryButtonBackgroundPressed,
	},
	iconDisabled: {
		color: theme?.palette.neutralTertiary,
	},
});

export const screenshotIconStyle = (disabled: boolean, theme: ITheme): IIconStyles => ({
	root: {
		selectors: {
			'.capture-screenshot-fill': {
				fill: disabled
					? theme?.semanticColors.disabledBodySubtext
					: theme?.semanticColors.inputBackground,
			},
		},
	},
});

export const getHeaderClassNames = classNamesFunction<IHeaderStyleProps, IHeaderStyles>();
