import { styled } from '@fluentui/react/lib/Utilities';
import Header from 'Components/Page/Header.base';
import { HeaderStyles } from 'Components/Page/Header.styles';
import { IHeaderProps, IHeaderStyleProps, IHeaderStyles } from 'Components/Page/Header.types';

const StyledHeader =
    styled<IHeaderProps,
        IHeaderStyleProps,
        IHeaderStyles>(
            Header,
            HeaderStyles
        );

export default StyledHeader;