import {
	FontWeights,
	IIconStyleProps,
	IIconStyles,
	INavLink,
	INavLinkGroup,
	IStackItemTokens,
	IStackStyles,
	IStyleFunctionOrObject,
	mergeStyles,
	ResponsiveMode,
	Stack,
	StackItem
} from '@fluentui/react';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import FilterBar from 'Components/FilterBar/FilterBar';
import {
	BarChart,
	ColorTestLayout,
	GapsContainer,
	GapsLayout,
	HealthContainer,
	HealthLayout,
	LineChart,
	ManagementContainer,
	ManagementLayout,
	MetricsContainer,
	MetricsLayout,
	PendingChangesContainer,
	PendingChangesLayout,
	PieChart,
	ResponsiveContainer,
	ScenarioDetailsContainer,
	ScenarioDetailsLayout,
	TestLayout
} from 'Components/LazyLoad/_Lazy';
import SpinnerFallback from 'Components/LazyLoad/SpinnerFallback';
import Navigation from 'Components/Navigation/Navigation';
import PageContent from 'Components/Page/Content';
import PageFooter from 'Components/Page/Footer';
import PageHeader from 'Components/Page/Header';
import {
	useGlobalFilters,
	useGlobalFiltersDispatch
} from 'Context/GlobalFiltersContext/GlobalFiltersContext';
import { gapTableKey, shTableKey } from 'Context/GlobalFiltersContext/GlobalFiltersContext.types';
import { useResponsiveModeContext } from 'Context/ThemeContext/ThemeContext';
import { ScenarioHealthContainersProps } from 'DataModels/Global.types';
import React, { Suspense, useCallback, useMemo, useRef } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { showDevAdminItems } from 'Utils/MsalConfig';
import { reactAppInsights } from 'Utils/TelemetryService';
import { ErrorText } from 'Views/Layout.Shared';

const dashboardWrapperStyles: IStackStyles = { root: { height: '100%' } };
const webkitFillAvailableHeight: IStackStyles = { root: { height: '-webkit-fill-available' } };
const filterStackTokens: IStackItemTokens = { padding: '8px 16px' };
const filterStackstyles: IStackStyles = { root: { marginBottom: 4 } };

const pivotItemStyles =
	(
		key: shTableKey | gapTableKey,
		isActive: boolean
	): IStyleFunctionOrObject<IIconStyleProps, IIconStyles> =>
		({ theme }: IIconStyleProps) => {
			let color = theme?.palette.themeSecondary;
			switch (true) {
				case key === 'missingtarget' || key === 'unmapped':
					color = theme?.palette.red;
					break;
				case key === 'improvements' || key === 'mapped':
					color = theme?.palette.green;
					break;

				default:
					break;
			}
			return {
				root: [
					{
						color: `${ color } !important`,
						paddingLeft: 4,
					},
					isActive && {
						fontWeight: FontWeights.bold,
						paddingBottom: 4,
						borderBottom: `2px  ${ color } solid`,
					},
				],
			};
		};

function DashboardLayout(props: ScenarioHealthContainersProps): JSX.Element {
	const { viewsContentRef, tableRefInView } = props;
	const scrollRef = useRef<HTMLDivElement>(null);
	const fullPageRef = useRef<HTMLDivElement>(null);
	const globalFiltersDispatch = useGlobalFiltersDispatch();
	const { shTable, gapTable, shMetTarget, shNines } = useGlobalFilters();
	const responsiveMode = useResponsiveModeContext();

	const navigate = useNavigate();
	const location = useLocation();

	const onPivotNavigationSelect = useCallback(
		(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
			//if (onScrollToTable) onScrollToTable()
			if (location.pathname === '/')
				globalFiltersDispatch({ type: 'set shtable', tableName: item?.data as shTableKey });
			if (location.pathname === '/gaps')
				globalFiltersDispatch({
					type: 'set gaptable',
					tableName: item?.data as gapTableKey,
				});
		},
		[globalFiltersDispatch, location.pathname]
	);

	const navLinkGroups = useMemo<INavLinkGroup[]>(() => {
		const navLinksTemp: INavLinkGroup[] = [
			{
				links: [
					// {
					// 	name: 'Scenario Health',
					// 	url: '/',
					// 	expandAriaLabel: 'Scenario Health',
					// 	collapseAriaLabel: 'Scenario Health',
					// 	initial: "SH"
					// },
					// {
					// 	name: 'Onboard Scenario',
					// 	url: 'https://serviceinsights.microsoft.com/Availability/ManageScenarios',
					// 	expandAriaLabel: 'Onboard Scenario',
					// 	collapseAriaLabel: 'Onboard Scenario',
					// 	initial: 'OS',
					// 	showPersona: true,
					// 	target: '_blank',
					// },
					{
						name: 'Gap Analysis',
						initial: 'GAP',
						expandAriaLabel: 'Gap Analysis',
						collapseAriaLabel: 'Gap Analysis',
						showPersona: true,
						url: '',
						onClick: () => navigate('/gaps' + location.search),
					},
					{
						name: 'Scenario Management',
						initial: 'MGT',
						expandAriaLabel: 'Scenario Management',
						collapseAriaLabel: 'Scenario Management',
						showPersona: true,
						url: '',
						onClick: () => navigate('/management' + location.search),
					},
				],
			},
		];

		if (
			location.pathname === '/' &&
			responsiveMode <= ResponsiveMode.medium &&
			tableRefInView
		) {
			navLinksTemp.push({
				name: 'Scenario Details',
				groupData: { initials: 'SD', iconProps: { iconName: 'Table' } },
				links: [
					{
						name: 'All',
						expandAriaLabel: 'All',
						collapseAriaLabel: 'All',
						url: '',
						iconProps: {
							iconName: 'BuildQueue',
							styles: pivotItemStyles('all', shTable === 'all'),
						},
						data: 'all',
						onClick: onPivotNavigationSelect,
					},
					{
						name: 'Missing Target',
						expandAriaLabel: 'Missing Target',
						collapseAriaLabel: 'Missing Target',
						url: '',
						data: 'missingtarget',
						iconProps: {
							iconName: 'ErrorBadge',
							styles: pivotItemStyles('missingtarget', shTable === 'missingtarget'),
						},
						onClick: onPivotNavigationSelect,
					},
					{
						name: 'Improvements',
						expandAriaLabel: 'Improvements',
						collapseAriaLabel: 'Improvements',
						url: '',
						data: 'improvements',
						iconProps: {
							iconName: 'Trending12',
							styles: pivotItemStyles('improvements', shTable === 'improvements'),
						},
						onClick: onPivotNavigationSelect,
					},
					{
						name: 'Next 9',
						expandAriaLabel: 'Next 9',
						collapseAriaLabel: 'Next 9',
						url: '',
						data: 'isnext9',
						iconProps: {
							iconName: 'FavoriteList',
							styles: pivotItemStyles('isnext9', shTable === 'isnext9'),
						},
						onClick: onPivotNavigationSelect,
					},
				],
			});
		}

		if (
			location.pathname === '/gaps' &&
			gapTable &&
			responsiveMode <= ResponsiveMode.medium &&
			tableRefInView
		) {
			navLinksTemp.push({
				name: 'IcM Details',
				groupData: { initials: 'IcM', iconProps: { iconName: 'Table' } },
				links: [
					{
						name: 'All',
						expandAriaLabel: 'All',
						collapseAriaLabel: 'All',
						url: '',
						iconProps: {
							iconName: 'BuildQueue',
							styles: pivotItemStyles('all', gapTable === 'all'),
						},
						data: 'all',
						onClick: onPivotNavigationSelect,
					},
					{
						name: 'Unmapped',
						expandAriaLabel: 'Unmapped',
						collapseAriaLabel: 'Unmapped',
						url: '',
						data: 'unmapped',
						iconProps: {
							iconName: 'ErrorBadge',
							styles: pivotItemStyles('unmapped', gapTable === 'unmapped'),
						},
						onClick: onPivotNavigationSelect,
					},
					{
						name: 'Mapped',
						expandAriaLabel: 'Mapped',
						collapseAriaLabel: 'Mapped',
						url: '',
						data: 'mapped',
						iconProps: {
							iconName: 'Trending12',
							styles: pivotItemStyles('mapped', gapTable === 'mapped'),
						},
						onClick: onPivotNavigationSelect,
					},
				],
			});
		}

		if (showDevAdminItems()) {
			navLinksTemp.push({
				name: 'Dev Admins Only',
				groupData: { initials: 'Dev' },
				links: [
					{
						name: 'Metrics',
						initial: 'MTC',
						expandAriaLabel: 'Internal Metrics',
						collapseAriaLabel: 'Internal Metrics',
						showPersona: true,
						url: '',
						onClick: () => navigate('/metrics' + location.search),
					},
					{
						name: 'Testing',
						initial: 'TST',
						expandAriaLabel: 'Testing',
						collapseAriaLabel: 'Testing',
						showPersona: true,
						url: '',
						onClick: () => navigate('/testing' + location.search),
					},
					{
						name: 'Color',
						initial: 'CLR',
						expandAriaLabel: 'Color Test',
						collapseAriaLabel: 'Color Test',
						showPersona: true,
						url: '',
						onClick: () => navigate('/colortest' + location.search),
					},
				],
			});
		}
		return navLinksTemp;
	}, [
		gapTable,
		location.pathname,
		location.search,
		navigate,
		onPivotNavigationSelect,
		responsiveMode,
		shTable,
		tableRefInView
	]);

	return (
		<Stack styles={ dashboardWrapperStyles }>
			<StackItem>
				<PageHeader fullPageRef={ fullPageRef } scrollRef={ scrollRef } />
			</StackItem>

			<StackItem grow>
				<Stack grow horizontal verticalFill>
					<Stack>
						<Navigation navLinkGroups={ navLinkGroups } defaultOpen={ false } />
					</Stack>
					<Stack verticalFill grow styles={ webkitFillAvailableHeight }>
						<Stack tokens={ filterStackTokens } styles={ filterStackstyles }>
							<FilterBar />
						</Stack>
						<PageContent
							role="main"
							scrollRef={ scrollRef }
							viewsContentRef={ viewsContentRef }
						>
							<div ref={ fullPageRef } className={ pageContentWrapperStyle }>
								<Suspense fallback={ <SpinnerFallback /> }>
									<Routes>
										<Route
											path="gaps"
											// exact
											element={
												<PreloadWrapper
													preload={ () => {
														void GapsContainer.preload();
														void GapsLayout.preload();
													} }
												>
													<GapsContainer { ...props } />
												</PreloadWrapper>
											}
										/>
										<Route
											path="management"
											// exact
											element={
												<PreloadWrapper
													preload={ () => {
														void ManagementContainer.preload();
														void ManagementLayout.preload();
													} }
												>
													<ManagementContainer { ...props } />
												</PreloadWrapper>
											}
										/>
										<Route
											path="/pendingChanges"
											// exact={true}
											element={
												<PreloadWrapper
													preload={ () => {
														void PendingChangesContainer.preload();
														void PendingChangesLayout.preload();
													} }
												>
													<PendingChangesContainer { ...props } />
												</PreloadWrapper>
											}
										/>
										{ showDevAdminItems() && (
											<>
												<Route
													path="/metrics"
													// exact
													element={
														<PreloadWrapper
															preload={ () => {
																void MetricsContainer.preload();
																void MetricsLayout.preload();
															} }
														>
															<MetricsContainer { ...props } />{ ' ' }
														</PreloadWrapper>
													}
												/>

												<Route
													path="/testing"
													element={
														<PreloadWrapper
															preload={ () => {
																void TestLayout.preload();
															} }
														>
															<TestLayout />
														</PreloadWrapper>
													}
												/>

												<Route
													path="/colortest"
													element={
														<PreloadWrapper
															preload={ () => {
																void ColorTestLayout.preload();
															} }
														>
															<ColorTestLayout />
														</PreloadWrapper>
													}
												/>
												<Route
													path="/scenariodetails"
													element={
														<PreloadWrapper
															preload={ () => {
																void ScenarioDetailsContainer.preload();
																void ScenarioDetailsLayout.preload();
															} }
														>
															<ScenarioDetailsContainer { ...props } />
														</PreloadWrapper>
													}
												/>
											</>
										) }
										<Route
											path=""
											// exact
											element={
												<PreloadWrapper
													preload={ () => {
														void HealthContainer.preload();
														void HealthLayout.preload();
														void LineChart.preload();
														void ResponsiveContainer.preload();
														if (shNines) void BarChart.preload();
														if (shMetTarget) void PieChart.preload();
													} }
												>
													<HealthContainer { ...props } />
												</PreloadWrapper>
											}
										/>
										<Route path="*" element={ <Navigate to="/" /> } />
									</Routes>
								</Suspense>
							</div>
						</PageContent>
						<PageFooter scrollRef={ scrollRef } />
					</Stack>
				</Stack>
			</StackItem>
		</Stack>
	);
}

const pageContentWrapperStyle: string = mergeStyles({ minHeight: '100%', height: 0 });

export default DashboardLayout;

type PreloadWrapperProps = { preload: () => void };

const PreloadWrapper = ({
	preload,
	children,
}: React.PropsWithChildren<PreloadWrapperProps>): JSX.Element => {
	preload();
	return (
		<AppInsightsErrorBoundary onError={ ErrorText } appInsights={ reactAppInsights }>
			<Suspense fallback={ <SpinnerFallback /> }>{ children }</Suspense>
		</AppInsightsErrorBoundary>
	);
};
