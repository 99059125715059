import { ActionButton, Panel, PanelType, PrimaryButton, ResponsiveMode } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { getLinkButtonPanelClassNames } from 'Components/Navigation/LinkButtonPanel.styles';
import { ILinkButtonPanelProps } from 'Components/Navigation/LinkButtonPanel.types';
import { useResponsiveModeContext, useThemeContext } from 'Context/ThemeContext/ThemeContext';
import React from 'react';

function LinkButtonPanel({
	className,
	styles,
	linkButtons,
	panelTitle = '',
}: ILinkButtonPanelProps): JSX.Element {
	const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
	const theme = useThemeContext();
	const responsiveMode = useResponsiveModeContext();

	const { subComponentStyles } = getLinkButtonPanelClassNames(styles, {
		theme: theme,
		className,
		responsiveMode,
	});
	const { panel, panelButton, waffleButton } = subComponentStyles;

	return (
		<>
			<PrimaryButton
				iconProps={{ iconName: 'WaffleOffice365' }}
				split={false}
				styles={waffleButton({ theme })}
				onClick={openPanel}
			/>
			{isPanelOpen && (
				<Panel
					isOpen={isPanelOpen}
					hasCloseButton={true}
					type={PanelType.customNear}
					customWidth={responsiveMode < ResponsiveMode.medium ? undefined : '450px'}
					onDismiss={dismissPanel}
					isLightDismiss={true}
					onLightDismissClick={dismissPanel}
					headerText={panelTitle}
					headerTextProps={{ style: { fontWeight: 600 } }}
					styles={panel}
				>
					{linkButtons &&
						linkButtons.map((actionButtons, key) => (
							<ActionButton
								{...actionButtons}
								key={key}
								styles={panelButton({ theme, responsiveMode })}
								target="_blank"
							/>
						))}
				</Panel>
			)}
		</>
	);
}

export default LinkButtonPanel;
