import { styled } from '@fluentui/react/lib/Utilities';
import PageFooter from 'Components/Page/Footer.base';
import { pageFooterStyles } from 'Components/Page/Footer.styles';
import {
	IPageFooterProps,
	IPageFooterStyleProps,
	IPageFooterStyles
} from 'Components/Page/Footer.types';

const StyledPageFooter =
	styled<IPageFooterProps,
		IPageFooterStyleProps,
		IPageFooterStyles>(
			PageFooter,
			pageFooterStyles
		);

export default StyledPageFooter;
