import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from 'App';
import 'CSS/Index.scss';
import { ThemeProvider } from 'Context/ThemeContext/ThemeContext';
import { msalInstance } from 'Utils/MsalConfig';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from 'serviceWorker';

export const browserHistory = createBrowserHistory({ window: window });


// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getActiveAccount());
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload && (event.payload as AuthenticationResult).account) {
		const account = (event.payload as AuthenticationResult).account;
		msalInstance.setActiveAccount(account);
	}
});


ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider>
			<MsalProvider instance={ msalInstance }>
				<App />
			</MsalProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();












