import {
	IColumn,
	IDetailsColumnRenderTooltipProps,
	IRenderFunction,
	IStackProps,
	IStackTokens,
	ITooltipHostProps,
	Stack,
	Text,
	TooltipHost,
} from '@fluentui/react';
import { childrenGap8, fluentTooltipCalloutStyle, fluentTooltipStyle } from 'CSS/SharedStyles';
import React from 'react';
import VF from 'Utils/ValueFormatting';
import {
	ContextMenuTemplate,
	DataRetrievalColumn,
	DateTuple,
	ExtractedFilterType,
	FullandDisplayDateTuple,
	GenericColumn_Filterable,
	GenericColumn_Simple,
	GenericColumn_Sortable,
	GenericColumn_SortableAndFilterable,
	IColumnAction,
} from 'Views/Layout.types';

export const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (
	tooltipHostProps
) => {
	return (
		<TooltipHost
			{...tooltipHostProps}
			calloutProps={{ styles: fluentTooltipCalloutStyle }}
			tooltipProps={{ styles: fluentTooltipStyle }}
		/>
	);
};

const viewsContainerTokens: IStackTokens = { childrenGap: 16, padding: '0px 0px 16px 0px' };
type IViewsContainerProps = {
	contentRef?: React.RefObject<HTMLDivElement>;
};

const viewContainerStyle: React.CSSProperties = { minHeight: '100%', height: '100%' };
export const ViewsContainer = ({
	children,
	contentRef,
}: React.PropsWithChildren<IViewsContainerProps>): JSX.Element => {
	return (
		<div ref={contentRef} style={viewContainerStyle}>
			<Stack tokens={viewsContainerTokens}>{children}</Stack>
		</div>
	);
};

export const fullColumnsReducer =
	<
		ItemDataType,
		ColType extends
			| GenericColumn_SortableAndFilterable<
					ItemDataType,
					unknown,
					ExtractedFilterType<ColType>,
					ItemDataType2
			  >
			| GenericColumn_Simple<ItemDataType, unknown, unknown, ItemDataType2>,
		ItemDataType2 = ItemDataType
	>() =>
	(
		prevState: ColType[],
		action: IColumnAction<ItemDataType, ExtractedFilterType<ColType>>
	): ColType[] => {
		switch (action.type) {
			case 'sort': {
				const temp: ColType[] = [];

				for (const col of prevState) {
					if (!col.data || !('contMenuTemplate' in col.data)) {
						temp.push(col);
						continue;
					}

					const colCopy = { ...col };
					if (colCopy.key !== action.col.key) {
						//reset all other column's sort
						colCopy.isSorted = false;
						colCopy.isSortedDescending = false;
					} else if (
						colCopy.isSorted && //reset the sort of the column the user sorted IF they have selected the same option as that column's current sort state
						((colCopy.isSortedDescending && action.dir === 'desc') ||
							(!colCopy.isSortedDescending && action.dir === 'asc'))
					) {
						colCopy.isSorted = false;
						colCopy.isSortedDescending = false;
					} else {
						//sort the column as specified
						colCopy.isSorted = true;
						colCopy.isSortedDescending = action.dir === 'desc';
					}
					temp.push(colCopy);
				}

				return temp;
			}
			case 'filter': {
				const temp: ColType[] = [];

				for (const col of prevState) {
					if (!col.data || !('contMenuTemplate' in col.data)) {
						temp.push(col);
						continue;
					}

					if (col.key === action.col.key) {
						const colCopy = { ...col, data: { ...col.data } };
						if (action.value !== undefined) colCopy.isFiltered = true;
						else colCopy.isFiltered = false;
						colCopy.data.filterVal = action.value;
						temp.push(colCopy);
					} else temp.push(col);
				}
				return temp;
			}
			default:
				return prevState;
		}
	};

export const TooltipHostSHDefault = (
	props: React.PropsWithChildren<ITooltipHostProps>
): JSX.Element => {
	return (
		<TooltipHost
			calloutProps={{ styles: fluentTooltipCalloutStyle }}
			tooltipProps={{ styles: fluentTooltipStyle }}
			{...props}
		>
			{props.children}
		</TooltipHost>
	);
};

export type ITooltipHostWithIconAndText = ITooltipHostProps & {
	stackProps?: IStackProps;
};

export const TooltipHostWithIconAndText = (
	props: React.PropsWithChildren<ITooltipHostWithIconAndText>
): JSX.Element => {
	return (
		<TooltipHostSHDefault {...props}>
			<Stack
				horizontal
				verticalFill
				verticalAlign="center"
				tokens={childrenGap8}
				{...props.stackProps}
			>
				{props.children}
			</Stack>
		</TooltipHostSHDefault>
	);
};

export const ErrorText = (): JSX.Element => (
	<Text>
		Something went wrong! Scenario Health Support has been notified. Contact us for blocking
		issues:
		<a href="mailto:shsupport@microsoft.com">Email</a>,
		<a
			href="https://teams.microsoft.com/l/channel/19%3a9defc3e13ab1434ea5661c61083b34cc%40thread.tacv2/General?groupId=34fcbb55-bdc6-4755-940f-2188b9faad9b&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47"
			target="_blank"
			rel="noreferrer"
		>
			Teams Channel
		</a>
	</Text>
);

//#region Tables
//#region Sorts and Filters
//#region Alphabetic
export const alphabeticContextualMenuTemplate: ContextMenuTemplate[] = [
	{ type: 'sort descending', sortType: 'alphabetic' },
	{ type: 'sort ascending', sortType: 'alphabetic' },
	{ type: 'filter' },
];

/** `getFilterData` should only be used with editable columns that are multiselect here.
 * otherwise `getData` returning a string is preffered */
export const alphabeticSortAsc = <T, R extends string, F extends string = R, T2 = T>(
	item1: T,
	item2: T,
	{ data }: GenericColumn_Sortable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): number => {
	if (!data) return 0;

	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	return `${dataRetrieval(item1, externalItem)}`.localeCompare(
		`${dataRetrieval(item2, externalItem)}`
	);
};

/** `getFilterData` should only be used with editable columns that are multiselect here.
 * otherwise `getData` returning a string is preffered */
export const alphabeticSortDesc = <T, R extends string, F extends string = R, T2 = T>(
	item1: T,
	item2: T,
	{ data }: GenericColumn_Sortable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): number => {
	if (!data) return 0;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	return -`${dataRetrieval(item1, externalItem)}`.localeCompare(
		`${dataRetrieval(item2, externalItem)}`
	);
};

/** `getFilterData` should only be used with editable columns that are multiselect here.
 * otherwise `getData` returning a string is preffered */
export const alphabeticMatchesFilter = <T, R extends string, F extends string = R, T2 = T>(
	item: T,
	filterVal: F,
	{ data }: GenericColumn_Filterable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): boolean => {
	if (!data) return false;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	return (
		`${dataRetrieval(item, externalItem)}`.toLowerCase().indexOf(`${filterVal}`.toLowerCase()) >
		-1
	);
};
//#endregion

//#region Numeric
export const numericContextualMenuTemplate: ContextMenuTemplate[] = [
	{ type: 'sort descending', sortType: 'numeric' },
	{ type: 'sort ascending', sortType: 'numeric' },
	{ type: 'filter' },
];

/** `getFilterData` should probably not be used, as there is no real use case currently.  Providing it for parity*/
export const numericSortAsc = <T, R extends number, F extends number = R, T2 = T>(
	item1: T,
	item2: T,
	{ data }: GenericColumn_Sortable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): number => {
	if (!data) return 0;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	return dataRetrieval(item1, externalItem) - dataRetrieval(item2, externalItem);
};

/** `getFilterData` should probably not be used, as there is no real use case currently.  Providing it for parity*/
export const numericSortDesc = <T, R extends number, F extends number = R, T2 = T>(
	item1: T,
	item2: T,
	{ data }: GenericColumn_Sortable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): number => {
	if (!data) return 0;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	return -(dataRetrieval(item1, externalItem) - dataRetrieval(item2, externalItem));
};

/** `getFilterData` should probably not be used, as there is no real use case currently.  Providing it for parity*/
export const numericMatchesFilter = <T, R extends number, F extends number = R, T2 = T>(
	item: T,
	filterVal: R,
	{ data }: GenericColumn_Filterable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): boolean => {
	if (!data) return false;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	return (
		dataRetrieval(item, externalItem)
			.toString()
			.toLowerCase()
			.indexOf(filterVal.toString().toLowerCase()) > -1
	);
};
//#endregion

//#region Boolean
export const booleanMenuTemplate: ContextMenuTemplate[] = [
	{ type: 'sort ascending', sortType: 'boolean' },
	{ type: 'sort descending', sortType: 'boolean' },
	{ type: 'boolean filter' },
];

export const booleanSortAsc = <T, R extends boolean, F extends boolean = R, T2 = T>(
	item1: T,
	item2: T,
	{ data }: GenericColumn_Sortable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): number => {
	if (!data) return 0;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	const x = +dataRetrieval(item1, externalItem) - +dataRetrieval(item2, externalItem);
	return x;
};

export const booleanSortDesc = <T, R extends boolean, F extends boolean = R, T2 = T>(
	item1: T,
	item2: T,
	{ data }: GenericColumn_Sortable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): number => {
	if (!data) return 0;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	const x = +dataRetrieval(item2, externalItem) - +dataRetrieval(item1, externalItem);
	return x;
};

export const booleanMatchesFilter = <T, R extends boolean, F extends boolean = R, T2 = T>(
	item: T,
	filterVal: boolean,
	{ data }: GenericColumn_Filterable<T, R, F, T2>,
	/**@deprecated */
	externalItem?: T2
): boolean => {
	if (!data) return false;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	const b = dataRetrieval(item, externalItem);
	return b === filterVal;
};
//#endregion

//#region Date
export const dateContextualMenuTemplate: ContextMenuTemplate[] = [
	{ type: 'sort descending', sortType: 'date' },
	{ type: 'sort ascending', sortType: 'date' },
	{ type: 'date filter' },
];

export const dateSortAsc = <T, T2 = T>(
	item1: T,
	item2: T,
	{ data }: GenericColumn_Filterable<T, FullandDisplayDateTuple, DateTuple, T2>,
	/**@deprecated */
	externalItem?: T2
): number => {
	if (!data) return 0;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;

	const left = dataRetrieval(item1, externalItem)[0]?.valueOf() ?? 0;
	const right = dataRetrieval(item2, externalItem)[0]?.valueOf() ?? 0;
	return left && right ? left - right : left ? -1 : 0;
};

export const dateSortDesc = <T, T2 = T>(
	item1: T,
	item2: T,
	{ data }: GenericColumn_Filterable<T, FullandDisplayDateTuple, DateTuple, T2>,
	/**@deprecated */
	externalItem?: T2
): number => {
	if (!data) return 0;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;

	const left = dataRetrieval(item1, externalItem)[0]?.valueOf() ?? 0;
	const right = dataRetrieval(item2, externalItem)[0]?.valueOf() ?? 0;
	return left && right ? right - left : right ? -1 : 0;
};

export const dateMatchesFilter = <T, T2 = T>(
	item: T,
	[s, e]: DateTuple,
	{ data }: GenericColumn_Filterable<T, FullandDisplayDateTuple, DateTuple, T2>,
	/**@deprecated */
	externalItem?: T2
): boolean => {
	if (!data) return false;
	const { getData, getFilterData } = data;
	const dataRetrieval = getFilterData ?? getData;
	const date = (dataRetrieval(item, externalItem)[0] ?? 0).valueOf();
	// Date Tuples for sorting need to set endDate to be INCLUSIVE of the end date for sorting
	//	to prevent the DatePicker from incrementing on every click of apply.
	// Adding a day here to conform to our standard of endDate being EXCLUSIVE
	return date >= s.valueOf() && date < e.valueOf() + 8.64e7;
};
//#endregion
//#endregion

//#region Renders
export const defaultTableCellRender = <
	ItemDataType,
	RenderDataType,
	C extends IColumn & { data?: DataRetrievalColumn<ItemDataType, RenderDataType, ItemDataType2> },
	ItemDataType2 = ItemDataType
>(
	item: ItemDataType,
	index?: number,
	column?: C
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): RenderDataType =>
	(column?.data as DataRetrievalColumn<ItemDataType, RenderDataType, ItemDataType2>).getData(
		item
	);

export const defaultTableCellRender_WithTooltip =
	(shortFormatter: (val: number) => string) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	<T,>(item: T, index?: number, column?: IColumn): any => {
		const fullCellValue = (column?.data as DataRetrievalColumn<T, number, T>).getData(item);
		return (
			// using never because we don't really care about type here
			<TooltipHostSHDefault content={fullCellValue as never}>
				{shortFormatter(fullCellValue)}
			</TooltipHostSHDefault>
		);
	};

export const dateCellRender = <
	T,
	C extends IColumn & {
		data?: DataRetrievalColumn<T, FullandDisplayDateTuple, T2>;
	},
	T2 = T
>(
	item: T,
	index?: number,
	column?: C,
	/**@deprecated */
	externalItem?: T2
): string | null => {
	if (!column || !column.data) return null;
	const { getData } = column.data as DataRetrievalColumn<T, FullandDisplayDateTuple, T2>;
	return getData(item, externalItem)[1];
};

export const shortNumber_CellTooltipRender = defaultTableCellRender_WithTooltip(VF.shortNumber);

export const availabilityNumber_CellTooltipRender = defaultTableCellRender_WithTooltip(
	VF.availabilityNumber
);

export const latencyNumber_CellTooltipRender = defaultTableCellRender_WithTooltip(VF.latencyNumber);
//#endregion
//#endregion
