import { classNamesFunction, IScrollablePaneStyles } from '@fluentui/react';
import { IPageContentStyleProps, IPageStyles } from 'Components/Page/Content.types';

export const pageContentStyles: IPageStyles = {
	root: [
		{
			width: '100%',
			height:
				'InstallTrigger' in window //firefox
					? 'calc(100% - 105px)'
					: '-webkit-fill-available',
			overflowY: 'auto',
			overflowX: 'hidden',
			// padding: '8px',
			position: 'relative',
		},
	],
	subComponentStyles: {
		scrollablePane: (/*{ scrollablePaneOffsetHeight }: IContentScrollablePaneStyleProps*/): Partial<IScrollablePaneStyles> => {
			return {
				stickyAbove: {
					zIndex: 999999,
				},
				contentContainer: [
					'custom-scrollbar',
					{
						padding: '0px 16px',
					},
				],
			};
		},
	},
};
export const getPageContentClassNames = classNamesFunction<IPageContentStyleProps, IPageStyles>();
