import axios, { AxiosInstance } from 'axios';

// Api calls
const AxiosFetchSelfServeApi: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_SERVICE_SELF_SERVE_API_URL,
	timeout: 30000,
});

//fetch local file (.md)
const AxiosFetchDocumentation: AxiosInstance = axios.create({
	baseURL: '',
	timeout: 30000,
});

export { AxiosFetchDocumentation, AxiosFetchSelfServeApi };