import {
	ChannelGroupEnum,
	ChannelGroupMapping,
	CustKeyToLobMap,
	LobEnum,
	LobToCustKeyMap,
} from 'DataModels/Global.types';

/**
 * Used to iterate over Lobs
 * Last value should always be 'Overall'.  Others can change as we add them.
 */
export const LobsIterator = Object.values(LobEnum);

/**
 * Used to look up the LOB to Channel Group Mapping
 */
export const ChannelGroupMap: ChannelGroupMapping = {
	Consumer: [LobEnum.Xbox, LobEnum.Office],
	Commercial: [
		LobEnum.AzureDirect,
		LobEnum.AzureField,
		LobEnum.M365,
		LobEnum.Partner,
		LobEnum.Microsoft,
		LobEnum.Marketplace,
		LobEnum.All,
	],
};

/**
 * Used to iterate over Channel Groups.
 */
export const ChannelGroupIterator = Object.values(ChannelGroupEnum);

/**
 * Used to look up Lob from customerID
 */
export const CustomerKeysToLobMap: CustKeyToLobMap = {
	// 1: LobEnum.Unknown
	2: LobEnum.All,
	3: LobEnum.AzureDirect,
	4: LobEnum.Office,
	5: LobEnum.Xbox,
	6: LobEnum.Microsoft,
	// 7: LobEnum.Windows,
	8: LobEnum.AzureField,
	9: LobEnum.M365,
	10: LobEnum.Partner,
	11: LobEnum.Marketplace,
};

/**
 * Used to look up the customer keys from the Lob
 */
export const LobToCustomerKeysMap: LobToCustKeyMap = {
	// [LobEnum.Unknown]: 1,
	[LobEnum.All]: 2,
	[LobEnum.AzureDirect]: 3,
	[LobEnum.Office]: 4,
	[LobEnum.Xbox]: 5,
	[LobEnum.Microsoft]: 6,
	// [LobEnum.Windows]: 7,
	[LobEnum.AzureField]: 8,
	[LobEnum.M365]: 9,
	[LobEnum.Partner]: 10,
	[LobEnum.Marketplace]: 11,

	/**
	 * Dummy Do not use
	 */
	[LobEnum.Overall]: 999999999,
};

export const LobEnumToDisplayNameMap: Record<LobEnum, string> = {
	[LobEnum.Xbox]: 'Xbox One',
	[LobEnum.Office]: 'Office Consumer',
	[LobEnum.AzureDirect]: 'Azure Direct',
	[LobEnum.AzureField]: 'Azure Field',
	[LobEnum.M365]: 'Office Commercial',
	[LobEnum.Partner]: 'Partner',
	[LobEnum.Microsoft]: 'Store',
	[LobEnum.Marketplace]: 'Marketplace',
	[LobEnum.All]: 'Others',
	[LobEnum.Overall]: 'Overall',
};

export const DisplayNameToLobEnumMap: Record<string, LobEnum> = {
	'Xbox One': LobEnum.Xbox,
	'Office Consumer': LobEnum.Office,
	'Azure Direct': LobEnum.AzureDirect,
	'Azure Field': LobEnum.AzureField,
	'Office Commercial': LobEnum.M365,
	Partner: LobEnum.Partner,
	Store: LobEnum.Microsoft,
	Marketplace: LobEnum.Marketplace,
	Others: LobEnum.All,
	Overall: LobEnum.Overall,
};
