/**
 * More performant than `Object.keys(obj).length` when there are keys present
 * @param {(Record<string | number | symbol, unknown>)} obj any object
 * @returns {boolean} isEmpty
 */
function isEmpty(obj: Record<string | number | symbol, unknown>): boolean {
	for (const key in obj) {
		if (Reflect.has(obj, key)) return false;
	}

	return true;
}

export default isEmpty;
