import { classNamesFunction } from '@fluentui/react';
import { IShellStyleProps, IShellStyles } from "Components/Shell/Shell.types";

export const shellStyles = ({ theme }: IShellStyleProps): IShellStyles => {
	return {
		root: [
			'shell',
			{
				backgroundColor: theme.palette.neutralLighterAlt,
				height: '100%',
				width: '100%',
				position: 'relative'
			}
		]
	}
}


export const getShellClassNames = classNamesFunction<IShellStyleProps, IShellStyles>();
